export const en = {
  deviceType: {
    bloodPressure: {
      text: 'blood pressure',
      monitor: 'blood pressure monitor',
    },
    bloodGlucose: {
      text: 'blood glucose',
      monitor: 'blood glucose monitor',
    },
    thermometer: {
      text: 'thermometer',
    },
    oximeter: { text: 'oximeter' },
    weightScale: {
      text: 'weight',
      scale: 'weight scale',
    },
  },
  common: {
    words: {
      days: 'days',
      last: 'last',
      measured: 'measured',
      missed: 'missed',
      years: 'years',
      yearsOld: 'year old',
      measurement: 'measurements',
      noDeviceFound: 'no device found',
      noMessageFound: 'no message found',
      noDataAvailable: 'no data available',
      filter: 'filter',
      filterApplied: 'filter applied',
      lastMeasured: 'last measured',
      setting: 'Setting',
      delivered: 'delivered',
      types: 'types',
      conditions: 'conditions',
      when: 'when',
      note: 'note',
      notify: 'notify',
      isBetween: 'is between',
      and: 'and',
      article: 'article',
      over: 'over',
      times: 'times',
      period: 'Period',
      showMore: 'more',
      direction: 'direction',
      occurrences: 'Occurrences',
      engagementLog: 'Engagement Log',
      checkIfSettingOccur: 'Checks if settings occur',
      checkIfSettingNotOccur: "Don't check settings on historical measurements",
      basicInfo: 'Basic Information',
      contactInfo: 'Contact Information',
      remoteMonProgress: 'Remote Monitoring Progress',
      gateway: 'Gateway',
      completedSinceLastBilling: 'Completed since calendar month',
      last2Weeks: 'Last 2 weeks',
      lastMonth: 'Last month',
      last3Months: 'Last 3 months',
      cfgedNotYetSeen: 'Configured, but not yet seen',
      lastSeen: 'Last seen',
      assign: 'assign',
      replace: 'replace',
      remove: 'remove',
      byName: 'by name',
      byAgeGroup: 'by age group',
      byDeviceTypes: 'by device types',
      any: 'any',
    },
    title: {
      patientAge: 'patient age',
      deviceDistribution: 'device distribution',
      patientAgeDistribution: 'patient age distribution',
      deviceTypeDistribution: 'device type distribution',
      newPatientEngLog: 'new patient engagement log',
      newPatientNote: 'new patient note',
      newAdvancedRule: 'new advanced rule',
      notificationSetting: 'notification setting',
    },
    btnLabel: {
      new: 'new',
      refresh: 'refresh',
      clearFilter: 'clear filter',
      send: 'send',
      resend: 'resend',
      reset: 'reset',
      save: 'save',
      cancel: 'cancel',
      requestDevice: 'request a device',
      requested: 'requested',
      requestGateway: 'request a gateway',
      discard: 'discard',
      dismiss: 'dismiss',
      apply: 'apply',
      readMore: 'read more',
    },
    formPlaceholder: {
      selectPerson: 'Select a person',
      typeASubject: 'Type a subject ...',
      typeAMessage: 'Type a message ...',
      typeANote: 'Type a note ...',
      chooseEngType: 'Choose an engagement type',
      typePartialName: 'Type partial name ...',
    },
    deviceWords: {
      sp02: 'sp02',
      systolic: 'systolic',
      diastolic: 'diastolic',
      heartRate: 'heart rate',
      glucose: 'glucose',
      fasting: 'fasting',
      temperature: 'temperature',
      weight: 'weight',
      lookback: 'lookback',
      value: 'value',
    },
    tpl: {
      xAge: '{age} years',
      deviceDetail: '{device} detail',
      measurementStats: 'Measurement stats across {count} patients',
      listOfX: 'List of {msg}',
      resendMsg: 'Resend message for {person}',
    },
    roles: {
      careTaker: 'care taker',
    },
    headers: {
      name: 'Name',
      age: 'Age',
      notes: 'Notes',
      devices: 'Device',
      lastMeasurements: 'Last Measurements',
      missedMeasurements: 'Missed Measurement',
      dateTime: 'Date/Time',
      patient: 'Patient',
      patients: 'Patients',
      deviceType: 'Device Type',
      tags: 'Tags',
      dashboard: 'dashboard',
      measurements: 'measurements',
      knowledgeBase: 'Knowledge Base',
      messages: 'messages',
      message: 'message',
      alertTriggers: 'Alert Triggers',
      conversations: 'Conversations',
      automated: 'Automated',
      createdAt: 'Created At',
      actions: 'Actions',
      receipts: 'Receipts',
      manage: 'Manage',
      metrics: 'Metrics',
      settings: 'Settings',
      advRules: 'Rules',
      inherited: 'Inherited',
      enabled: 'Enabled',
      notification: 'Notification',
      duration: 'duration',
      content: 'content',
      log: 'log',
      email: 'Email',
      address: 'Address',
      phone: 'Phone',
      since: 'Since',
      goal: 'Goal',
      active: 'active',
      overview: 'Overview',
      patientEngagements: 'patients engagements',
      lastTwoComparison: 'Last two measurements comparison',
      notifyCareTeam: 'notify care team',
    },
  },
};
