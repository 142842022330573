import { render, staticRenderFns } from "./DeviceValueDisplay.vue?vue&type=template&id=2f253fc3&scoped=true&"
import script from "./DeviceValueDisplay.vue?vue&type=script&lang=ts&"
export * from "./DeviceValueDisplay.vue?vue&type=script&lang=ts&"
import style0 from "./DeviceValueDisplay.vue?vue&type=style&index=0&id=2f253fc3&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f253fc3",
  null
  
)

export default component.exports