export interface AuthState {
  accessToken: string;
  status: loadingStateE;
  loading: boolean;
}

export type authDetailsT = {
  email: string;
  roles: string[];
  tenantId: string;
  userId: string;
};

export type Auth0UserT = {
  sub: string;
  givenName: string;
  familyName: string;
  nickname: string;
  name: string;
  picture: string;
  locale: string;
  updatedAt: string;
  email: string;
  emailVerified: boolean;
};

export enum loadingStateE {
  Initial = 'initial',
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
}
