
import { Component, Vue } from 'vue-property-decorator';
import { GenericObjectT } from '@/common/types/interfaces';
import { values } from 'lodash';
import { eventNames } from '@/common/utils/vue-events';
import PageHeader from '@/common/components/PageHeader.vue';
import PatientFilter from '@/components/patients/filters/PatientFilter.vue';
import PatientTable from '@/components/patients/PatientsTable.vue';
import FilterBtn from '@/common/components/FilterBtn.vue';

@Component({
  components: {
    FilterBtn,
    PageHeader,
    PatientTable,
    PatientFilter,
  },
})
export default class Patients extends Vue {
  data = {
    filterDrawer: false,
    currentFilters: {},
  };

  get updatedFilters(): GenericObjectT {
    return this.data.currentFilters;
  }

  setFilterFromDrawer(value: GenericObjectT) {
    const filterValues = values(value);
    if (filterValues.length === 0) {
      this.data.currentFilters = {};
    } else {
      this.data.currentFilters = filterValues.pop();
    }

    this.$root.$emit(
      eventNames.patients.filter.updated,
      this.data.currentFilters,
    );
  }
}
