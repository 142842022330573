
import { Component, Vue } from 'vue-property-decorator';
import { gatewaysGql } from '@/common/graphql/gateways';
import { setPagination } from '@/common/utils/utils';
import { GenericObjectT } from '@/common/types/interfaces';
import { get, forEach, isEmpty } from 'lodash';
import ToolTip from '@/common/components/ToolTip.vue';
import PageHeader from '@/common/components/PageHeader.vue';
import PrettyTs from '@/common/components/PrettyTs.vue';
import FilterBtn from '@/common/components/FilterBtn.vue';
import FilterDialog from '@/common/components/FilterDialog.vue';
import GatewayFilterBody from '@/components/gateways/filter/GatewayFilterBody.vue';
import NewGatewayForm from '@/components/gateways/forms/NewGatewayForm.vue';
import { webUtil } from '@/common/utils/web-utils';

@Component({
  components: {
    ToolTip,
    PrettyTs,
    FilterBtn,
    PageHeader,
    FilterDialog,
    GatewayFilterBody,
    NewGatewayForm,
  },
})
export default class Gateways extends Vue {
  data = {
    loading: 0,
    filterDrawer: false,
    dialog: false,
    headers: [
      { text: 'Status', value: 'status' },
      { text: 'Firmware', value: 'firmware' },
      { text: 'Assigned To', value: 'Patient.name' },
      { text: 'Assigned Date/time', value: 'assignmentDate' },
      { text: 'Actions', value: 'actions' },
    ],
    rows: [],
    pagination: {
      offset: 0,
      limit: 15,
      total: 0,
      totalPages: 0,
    },
    params: {
      filter: {},
      sort: {},
    },
    pageNum: 1,
    currentFilter: {
      patient: {},
      status: {},
    },
  };

  get hasFilter(): boolean {
    return !isEmpty(this.data.params.filter);
  }

  updateOptions(value: GenericObjectT) {
    const sortBy = get(value, 'sortBy.0', 'status');
    const sortDesc = get(value, 'sortDesc.0', false) === true ? -1 : 1;
    this.data.params.sort = {
      [sortBy]: sortDesc,
    };

    this.fetchData();
  }

  mounted(): void {
    this.data.params.sort = { status: -1 };
    this.fetchData();
  }

  async fetchData() {
    try {
      this.data.loading += 1;
      const {
        data: {
          Gateways: {
            Get: { docs, pageInfo },
          },
        },
      } = await this.$apollo.query({
        query: gatewaysGql.listAll(),
        variables: {
          pagination: {
            offset: this.data.pagination.offset,
            limit: this.data.pagination.limit,
          },
          params: this.data.params,
        },
        fetchPolicy: 'network-only',
      });

      this.data.rows = docs;
      setPagination(this.data.pagination, pageInfo);
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }

  closeDialog(): void {
    this.data.dialog = false;
    this.fetchData();
  }

  setFilterFromDrawer() {
    const newFilter: GenericObjectT = {};
    forEach(
      this.data.currentFilter,
      (filterObj: GenericObjectT, key: string): void => {
        if (!isEmpty(filterObj)) {
          newFilter[key] = filterObj.id;
        }
      },
    );

    console.log(`New Filter Object: ${JSON.stringify(newFilter, null, 2)}`);

    this.data.params.filter = newFilter;
    this.data.filterDrawer = false;
    this.fetchData();
  }

  clearAllFilter() {
    this.clearFilterValues();
    this.setFilterFromDrawer();
  }

  clearFilterValues() {
    this.data.currentFilter = {
      patient: {},
      status: {},
    };
  }
}
