
import { GenericObjectT } from '@/common/types/interfaces';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { processLabelMatcher } from '@/components/notifications/settings/notification-setting-devices';
import ToolTip from '@/common/components/ToolTip.vue';

@Component({
  components: { ToolTip },
})
export default class NotificationDeviceSettings extends Vue {
  @Prop({ default: '' }) labels!: string;
  @Prop({ default: '' }) deviceType!: string;

  get processedLabels(): GenericObjectT[] {
    console.log(`In processedLabels for ${this.labels} [${this.deviceType}]`);
    return processLabelMatcher(this.labels, this.deviceType);
  }

  getTextPath(prefix: string, lbl: GenericObjectT): string {
    return `${prefix}.${this.$webUtil.str.camelCase(lbl.label)}`;
  }

  showComma(data: GenericObjectT, idx: number): boolean {
    return (
      idx < data.length - 1 && data.length > 1 && data[idx + 1].label.length > 0
    );
  }
}
