
import { Component, Vue, Prop } from 'vue-property-decorator';
import { webUtil } from '@/common/utils/web-utils';
import ToolTip from '@/common/components/ToolTip.vue';
import moment from 'moment';

@Component({
  components: {
    ToolTip,
  },
})
export default class PreetyTs extends Vue {
  @Prop({ default: '' }) second!: string | number;
  @Prop({ default: false }) highlightOld!: boolean;

  get today(): number {
    return moment().startOf('day').unix() * 1000;
  }

  get isValid(): boolean {
    return this.second !== Infinity;
  }

  get millisecond(): string {
    return (+this.second * 1000).toString();
  }

  get cls(): string {
    if (this.highlightOld !== true) {
      return '';
    } else if (webUtil.time.olderThanToday(this.millisecond)) {
      return 'old-value';
    }
    return '';
  }

  get prettyData(): string {
    if (!this.isValid) {
      return 'n/a';
    }

    if (webUtil.time.olderThanToday(this.millisecond)) {
      return webUtil.time.fullTsEpoch(this.millisecond);
    }
    return moment(+this.millisecond).fromNow();
  }

  get longFmtData(): string {
    if (!this.isValid) {
      return 'n/a';
    }
    return webUtil.time.custom(this.millisecond, 'llll');
  }
}
