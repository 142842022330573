import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const patientsGql = {
  patients: {
    all(): DocumentNode {
      return gql`
        query allPatientsQuery(
          $pagination: QueryInputPagination
          $params: QueryInputParam
          $period: MeasurementPeriodE!
        ) {
          Patients {
            Get(pagination: $pagination, params: $params) {
              docs {
                id
                dob
                mrn
                name
                Devices {
                  id
                  type
                  status
                  Measurements {
                    last

                    points(period: $period)
                  }
                }
              }
              pageInfo {
                offset
                limit
                total
                totalPages
              }
            }
          }
        }
      `;
    },
    byIdAndName(): DocumentNode {
      return gql`
        query allPatientsIdNameQuery(
          $pagination: QueryInputPagination
          $params: QueryInputParam
        ) {
          Patients {
            Get(pagination: $pagination, params: $params) {
              docs {
                id
                name
              }
              pageInfo {
                offset
                limit
                total
                totalPages
              }
            }
          }
        }
      `;
    },
    show(): DocumentNode {
      return gql`
        query PatientDetailQuery(
          $patientId: ID!
          $period: MeasurementPeriodE!
        ) {
          Patients {
            Show(patientId: $patientId) {
              id
              name
              dob
              mrn
              emailAddr
              phoneNum
              createdAt
              postal {
                address {
                  streetAddr
                  city
                  state
                  zipcode
                }
              }
              settings
              characteristics {
                race
                gender
              }
              Devices {
                type
                id
                Measurements {
                  last
                  progress
                  points(period: $period)
                }
              }
            }
          }
        }
      `;
    },
    allRequestedEquipments(): DocumentNode {
      return gql`
        query allPatientsQuery(
          $pagination: QueryInputPagination
          $params: QueryInputParam
        ) {
          Patients {
            Get(pagination: $pagination, params: $params) {
              docs {
                id
                name
                requestedDevices
              }
              pageInfo {
                offset
                limit
                total
                totalPages
              }
            }
          }
        }
      `;
    },
    requestedEquipments(): DocumentNode {
      return gql`
        query PatientReqEquipmentsQuery($patientId: ID!) {
          Patients {
            Show(patientId: $patientId) {
              Devices {
                id
                type
              }
              Gateway {
                id
                status
                firmware
                serialNumber
                createdAt
                LastSeen
                GwIpAddress
              }
              requestedDevices
            }
          }
        }
      `;
    },
    notes(): DocumentNode {
      return gql`
        query PatientNoteQuery(
          $patientId: ID!
          $pagination: QueryInputPagination
          $params: QueryInputParam
        ) {
          Patients {
            Notes(
              patientId: $patientId
              pagination: $pagination
              params: $params
            ) {
              docs {
                id
                needFollowUp
                followUpTs
                content
                createdAt
              }
              pageInfo {
                offset
                limit
                total
                totalPages
              }
            }
          }
        }
      `;
    },
    rawMeasurementList(): DocumentNode {
      return gql`
        query PatientMeasurementList(
          $patientId: ID!
          $pagination: QueryInputPagination
        ) {
          Patients {
            RawMeasurements(patientId: $patientId, pagination: $pagination) {
              docs
              pageInfo {
                offset
                limit
                total
                totalPages
              }
            }
          }
        }
      `;
    },
    measurements(): DocumentNode {
      return gql`
        query PatientMeasurements(
          $patientId: ID!
          $deviceId: ID!
          $period: MeasurementPeriodE!
        ) {
          Patients {
            Measurements(
              patientId: $patientId
              deviceId: $deviceId
              period: $period
            ) {
              entries
            }
          }
        }
      `;
    },
    engagements(): DocumentNode {
      return gql`
        query PatientEngagements(
          $pagination: QueryInputPagination
          $params: QueryInputParam
        ) {
          Patients {
            Engagements(params: $params, pagination: $pagination) {
              docs {
                startTs
                endTs
                type
                content
                updatedAt
                Patient {
                  name
                  id
                  mrn
                  emailAddr
                }
              }
              pageInfo {
                offset
                limit
                total
                totalPages
              }
            }
          }
          Dictionary {
            EngagemenLogTypes
          }
        }
      `;
    },
  },
  settings: {
    get(): DocumentNode {
      return gql`
        query getPatientSetting($patientId: ID!) {
          Patients {
            MeasurementRules(patientId: $patientId)
          }
        }
      `;
    },
    create(): DocumentNode {
      return gql`
        mutation SetPatientSetting($patientId: ID!, $data: JSON!) {
          Patient {
            Settings(patientId: $patientId) {
              Set(data: $data)
            }
          }
        }
      `;
    },
    remove(): DocumentNode {
      return gql`
        mutation RemovePatientSetting($patientId: ID!, $kind: String!) {
          Patient {
            Settings(patientId: $patientId) {
              Remove(kind: $kind)
            }
          }
        }
      `;
    },
  },

  measurementRule: {
    create(): DocumentNode {
      return gql`
        mutation patientMeasurementRule($patientId: ID!, $data: JSON!) {
          Patient {
            MeasurementRules(patientId: $patientId) {
              Set(data: $data)
            }
          }
        }
      `;
    },
    delete(): DocumentNode {
      return gql`
        mutation deletePatientMeasurementRule($patientId: ID!, $ruleId: ID!) {
          Patient {
            MeasurementRules(patientId: $patientId) {
              Delete(ruleId: $ruleId)
            }
          }
        }
      `;
    },
    setNotification(): DocumentNode {
      return gql`
        mutation patientMeasurementRuleNotification(
          $patientId: ID!
          $ruleId: ID!
          $data: LabelNumberInput
        ) {
          Patient {
            MeasurementRules(patientId: $patientId) {
              ruleNotification(ruleId: $ruleId, data: $data)
            }
          }
        }
      `;
    },
  },

  createNote(): DocumentNode {
    return gql`
      mutation PatientNote($patientId: ID!, $note: String!) {
        Patient {
          NewNote(patientId: $patientId, note: $note)
        }
      }
    `;
  },
  createEngagement(): DocumentNode {
    return gql`
      mutation PatientEngagement(
        $patientId: ID!
        $data: EngagementLogEntryInput
      ) {
        Patient {
          Engagement(patientId: $patientId, data: $data)
        }
      }
    `;
  },
  equipmentRequest: {
    setGateway(): DocumentNode {
      return gql`
        mutation patientRequestEquipment(
          $patientId: ID!
          $action: PatientEquipmentRequestActionE!
        ) {
          Patient {
            Equipment(patientId: $patientId) {
              Request {
                gateway(action: $action)
              }
            }
          }
        }
      `;
    },
    setDevice(): DocumentNode {
      return gql`
        mutation patientRequestEquipment(
          $patientId: ID!
          $action: PatientEquipmentRequestActionE!
          $deviceType: String!
        ) {
          Patient {
            Equipment(patientId: $patientId) {
              Request {
                device(action: $action, deviceType: $deviceType)
              }
            }
          }
        }
      `;
    },
  },
};
