
import { Component, Vue, Prop } from 'vue-property-decorator';
import { get } from 'lodash';

@Component
export default class BloodPressureGoals extends Vue {
  @Prop({ default: () => ({}) }) goal!: object;

  get systolic(): number {
    return get(this.goal, 'systolic', 0);
  }

  get diastolic(): number {
    return get(this.goal, 'diastolic', 0);
  }

  get heartRate(): number {
    return get(this.goal, 'heartRate', 0);
  }
}
