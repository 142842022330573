
import { Component, Vue, Prop } from 'vue-property-decorator';
import { get } from 'lodash';
import { webUtil } from '@/common/utils/web-utils';

@Component
export default class BaseMeasurementDisplay extends Vue {
  @Prop({ default: [] }) sequence!: string[];
  @Prop({ default: {} }) details!: object;

  getValue(key: string, type: string): string | number {
    let result = get(this.details, [type, key], '');
    if (type === 'units') {
      switch (result) {
        case '{beats}/min':
          result = 'bpm';
          break;
        default:
          break;
      }
    }
    return result;
  }

  startCase(value: string): string {
    return webUtil.str.startCase(value);
  }
}
