
import { Component, Vue, Prop } from 'vue-property-decorator';
import { get } from 'lodash';

@Component
export default class OximeterGoals extends Vue {
  @Prop({ default: () => ({}) }) goal!: object;

  get sp02(): number {
    return get(this.goal, 'sp02', 0);
  }

  get heartRate(): number {
    return get(this.goal, 'heartRate', 0);
  }
}
