
import { Component, Vue, Prop } from 'vue-property-decorator';
import { GenericObjectT } from '../types/interfaces';

@Component
export default class LocaleText extends Vue {
  @Prop({ default: true }) transformStartCase!: boolean;
  @Prop({ default: '' }) textPath!: string;
  @Prop({ default: () => ({}) }) textParams!: GenericObjectT;

  get text(): string {
    const words = this.$t(this.textPath, this.textParams) as string;

    if (this.transformStartCase === true) {
      return this.$webUtil.str.startCase(words);
    }
    return words;
  }
}
