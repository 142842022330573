
import { GenericObjectT } from '@/common/types/interfaces';
import { Component, Vue } from 'vue-property-decorator';
import { jwtTokenDetails } from '@/common/utils/utils';
import { authDetailsT } from '@/store/types';
import { get, findIndex } from 'lodash';
import { userActions } from '@/store/actions';

@Component
export default class AccountSetting extends Vue {
  items = ['Profile', 'Settings', 'Logout'];
  data = {
    currLocale: 0,
    locales: ['en', 'id', 'nl'],
  };

  get currLocale(): number {
    return findIndex(this.data.locales, this.myLocale);
  }

  get userProfile(): GenericObjectT {
    return this.$store.getters.profile;
  }

  get tokenDetails(): authDetailsT {
    return jwtTokenDetails(this.$store.getters.accessToken);
  }

  get userInitial(): string {
    return this.userProfile.nickname
      .match(/\b(\w)/g)
      .join('')
      .toUpperCase()
      .substr(0, 2);
  }

  get userFullname(): string {
    return this.userProfile.nickname;
  }

  get userRoles(): string {
    return this.tokenDetails.roles
      .map((role: string): string => {
        return this.$t(
          `common.roles.${this.$webUtil.str.camelCase(role)}`,
        ) as string;
      })
      .join(', ');
  }

  get myLocale(): string {
    return get(this.$i18n, 'locale', 'en');
  }

  setLocale(item: number): void {
    this.$i18n.locale = this.data.locales[item];
    this.$store.commit(userActions.setter.locale, this.$i18n.locale);
  }

  logout(): void {
    this.$auth.logout({
      returnTo: window.location.origin,
    });
  }
}
