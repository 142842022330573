
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ShareMenuAction extends Vue {
  data = {
    loading: 0,
    share: false,
    dialog: false,
  };
}
