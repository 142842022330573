import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const supportGql = {
  query: {
    devices(): DocumentNode {
      return gql`
        query SupportGetDeviceAndGateway(
          $pagination: QueryInputPagination
          $params: QueryInputParam
        ) {
          Support {
            Get {
              Devices(params: $params, pagination: $pagination) {
                docs {
                  id
                  type
                  status
                  firmware
                  serialNumber
                  macAddress
                  createdAt
                }
                pageInfo {
                  offset
                  limit
                  total
                  totalPages
                }
              }
            }
          }
        }
      `;
    },
    gateways(): DocumentNode {
      return gql`
        query SupportGetDeviceAndGateway(
          $pagination: QueryInputPagination
          $params: QueryInputParam
        ) {
          Support {
            Get {
              Gateways(params: $params, pagination: $pagination) {
                docs {
                  id
                  status
                  firmware
                  serialNumber
                  createdAt
                }
                pageInfo {
                  offset
                  limit
                  total
                  totalPages
                }
              }
            }
          }
        }
      `;
    },
    patients(): DocumentNode {
      return gql`
        query SupportGetPatients(
          $pagination: QueryInputPagination
          $params: QueryInputParam
        ) {
          Support {
            Get {
              Patients(params: $params, pagination: $pagination) {
                docs {
                  id
                  name
                  firstName
                  lastName
                  requestedDevices
                }
                pageInfo {
                  offset
                  limit
                  total
                  totalPages
                }
              }
            }
          }
        }
      `;
    },
  },
  mutation: {
    assignGateway(): DocumentNode {
      return gql`
        mutation assignGateway(
          $tenantId: ID!
          $gatewayId: ID!
          $patientId: ID!
        ) {
          Support {
            Gateway {
              Assign(
                tenantId: $tenantId
                patientId: $patientId
                gatewayId: $gatewayId
              )
            }
          }
        }
      `;
    },
    assignDevice(): DocumentNode {
      return gql`
        mutation assignDevice(
          $tenantId: ID!
          $deviceId: ID!
          $gatewayId: ID!
          $patientId: ID!
        ) {
          Support {
            Device {
              Assign(
                tenantId: $tenantId
                patientId: $patientId
                gatewayId: $gatewayId
                deviceId: $deviceId
              )
            }
          }
        }
      `;
    },
    importGateway(): DocumentNode {
      return gql`
        mutation importGateway($file: Upload!) {
          Support {
            Gateway {
              Import(file: $file)
            }
          }
        }
      `;
    },
    importDevice(): DocumentNode {
      return gql`
        mutation importDevice($file: Upload!) {
          Support {
            Device {
              Import(file: $file)
            }
          }
        }
      `;
    },
  },
};
