
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { get, find, sum, map, isEmpty } from 'lodash';
import FormDialog from '@/common/components/FormDialog.vue';
import { webUtil } from '@/common/utils/web-utils';
import moment from 'moment';

type notificationType = {
  label: string;
  value: string | number;
};

@Component({ components: { FormDialog } })
export default class RuleNotificationForm extends Vue {
  @Prop({ default: false }) inherited!: boolean;
  @Prop({ default: () => ({ label: 'once_per_minute', value: 60 }) })
  notificationValue!: notificationType;

  @Watch('customValue', { deep: true })
  onCustomValueChanged() {
    this.selected.value = this.calculateSeconds(this.customValue);
  }

  updated() {
    this.currentRadio = this.notificationValue.label;
    this.selected.label = this.notificationValue.label;
    this.selected.value = this.notificationValue.value;
    if (this.selected.label === 'custom') {
      const mmData = moment.duration(this.selected.value, 'seconds');

      const days = get(mmData, ['_data', 'days'], 0) * 24;
      const months = get(mmData, ['_data', 'months']) * 24 * 30;
      const years = get(mmData, ['_data', 'years']) * 365;
      this.customValue.hour =
        days + months + years + get(mmData, ['_data', 'hours']);
      this.customValue.minute = get(mmData, ['_data', 'minutes']);
      this.customValue.second = get(mmData, ['_data', 'seconds']);
    }
    // console.log('Updated lifecycle invoked.', this.notificationValue);
  }

  startCase = webUtil.str.startCase;
  notificationItems = [
    { label: 'immediately', value: 0 },
    { label: 'one_time', value: -1 },
    { label: 'once_per_minute', value: 60 },
    { label: 'once_per_hour', value: 60 * 60 },
    { label: 'once_per_day', value: 60 * 60 * 24 },
    { label: 'once_per_week', value: 60 * 60 * 24 * 7 },
    { label: 'once_per_month', value: 60 * 60 * 24 * 7 * 30 },
    { label: 'custom', value: 0 },
  ];

  dialog = false;
  currentRadio = '';
  customValue: { hour: number; minute: number; second: number } = {
    hour: 0,
    minute: 0,
    second: 0,
  };
  selected: notificationType = { label: '', value: 0 };

  get displayNotif(): string {
    if (isEmpty(this.notificationValue)) {
      return 'Set notification';
    }
    return get(
      find(this.notificationItems, { value: this.notificationValue }),
      'label',
    ) as unknown as string;
  }

  calculateSeconds(value: { hour: number; minute: number; second: number }) {
    return sum(
      map(value, (data, key) => {
        switch (key) {
          case 'hour':
            return data * 3600;
          case 'minute':
            return data * 60;
          default:
            return data;
        }
      }),
    );
  }

  get totalSeconds(): number {
    return this.calculateSeconds(this.customValue);
  }

  isCustom() {
    return this.notificationValue.label.toLowerCase() === 'custom';
  }

  humanizeValue() {
    return webUtil.str.startCase(this.notificationValue.label);
  }

  humanizeRecurring() {
    let result = webUtil.time.humanize
      .seconds(this.notificationValue.value)
      .replace('a ', '')
      .replace('an ', '');
    return webUtil.str.startCase(result);
  }

  closeDialog(): boolean {
    this.dialog = false;
    return false;
  }

  @Emit('save-data')
  saveNotificationSetting(): notificationType {
    this.dialog = false;
    this.selected.label = this.currentRadio;
    if (this.currentRadio !== 'custom') {
      this.selected.value = get(
        find(this.notificationItems, { label: this.currentRadio }),
        'value',
        0,
      );
    }

    return this.selected;
  }
}
