
import { GenericObjectT } from '@/common/types/interfaces';
import { eventNames } from '@/common/utils/vue-events';
import { Component, Vue, Prop, PropSync, Emit } from 'vue-property-decorator';
import FilterDrawerComponent from '@/common/components/FilterDrawer.vue';
import FilterByPatientName from '@/components/patients/filters/sections/FilterByPatientName.vue';
import FilterByDeviceType from '@/components/patients/filters/sections/FilterByDeviceTypes.vue';

@Component({
  components: {
    FilterByDeviceType,
    FilterByPatientName,
    FilterDrawerComponent,
  },
})
export default class NotificationsFilter extends Vue {
  @Prop({ default: {} }) currentFilters!: Record<string, unknown>;
  @PropSync('drawer', { type: Boolean }) showDrawer!: boolean;

  @Emit('collapse-filter-drawer')
  collapseFilterDrawer(): void {
    return;
  }

  @Emit('set-filter-from-drawer')
  setFilterFromDrawer(): GenericObjectT {
    this.collapseFilterDrawer();
    return this.data.filters;
  }

  data = {
    loading: 0,
    displayMaxEntry: 30,
    headers: ['By Name', 'By Device Types'],
    filters: {},
  };

  updateFilter(value: GenericObjectT): void {
    this.data.filters = value;
  }

  clearAllFilters(): void {
    this.data.filters = {};
    this.setFilterFromDrawer();
    this.$root.$emit(eventNames.patients.filter.clear.all);
  }
}
