
import { patientsGql } from '@/common/graphql/patients';
import { webUtil } from '@/common/utils/web-utils';
import { get } from 'lodash';
import { setPagination } from '@/common/utils/utils';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import PrettyTs from '@/common/components/PrettyTs.vue';
import ToolTip from '@/common/components/ToolTip.vue';
import PatientNoteForm from '@/components/patients/forms/PatientNoteForm.vue';

@Component({
  components: {
    PrettyTs,
    ToolTip,
    PatientNoteForm,
  },
})
export default class NoteSection extends Vue {
  @Prop({ default: '' }) patientId!: string;
  @Watch('data.pageNum')
  onPageNumChg(): void {
    this.data.pagination.offset =
      (this.data.pageNum - 1) * this.data.pagination.limit;

    this.fetchData();
  }

  data = {
    loading: 0,
    headers: [
      {
        text: this.getLocaleHdr('Date/Time'),
        value: 'createdAt',
        width: '210',
      },
      // { text: 'Tag', value: 'needFollowUp', width: '125' },
      { text: this.getLocaleHdr('Notes'), value: 'content' },
    ],
    rows: [],
    pagination: {
      offset: 0,
      limit: 15,
      total: 0,
      totalPages: 0,
    },
    filter: {
      sort: { createdAt: -1 },
    },
    pageNum: 1,
    noteDialog: false,
  };

  mounted(): void {
    this.fetchData();
  }

  getLocaleHdr(label: string): string {
    const camelLabel = this.$webUtil.str.camelCase(label);
    if (camelLabel !== 'dateTime') {
      return this.$webUtil.str.startCase(
        this.$t(`common.headers.${this.$webUtil.str.camelCase(label)}`),
      );
    }
    return this.$t(`common.headers.${camelLabel}`) as string;
  }

  get isLoading(): boolean {
    return get(this.data, 'loading', 1) > 0;
  }

  getSeconds(createdAt: string): number {
    return webUtil.time.epoch(createdAt);
  }

  async fetchData() {
    try {
      this.data.loading += 1;
      const result = await this.$apollo.query({
        query: patientsGql.patients.notes(),
        variables: {
          patientId: this.patientId,
          params: this.data.filter,
          pagination: {
            offset: this.data.pagination.offset,
            limit: this.data.pagination.limit,
          },
        },
        fetchPolicy: 'no-cache',
      });
      const {
        data: {
          Patients: {
            Notes: { docs, pageInfo },
          },
        },
      } = result;

      this.data.rows = docs;
      setPagination(this.data.pagination, pageInfo);
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }

  closeNoteDialog(): void {
    this.data.noteDialog = false;
    this.fetchData();
  }
}
