
import { Component, Vue, Watch } from 'vue-property-decorator';
import { notificationsGql } from '@/common/graphql/notifications';
import { setPagination } from '@/common/utils/utils';
import { webUtil } from '@/common/utils/web-utils';
import { GenericObjectT } from '@/common/types/interfaces';
import { values, cloneDeep } from 'lodash';
import moment from 'moment';
import PrettyTs from '@/common/components/PrettyTs.vue';
import PageHeader from '@/common/components/PageHeader.vue';
import DeviceIcon from '@/common/components/devices/DeviceIcon.vue';
import DeviceLabel from '@/common/components/devices/DeviceLabel.vue';
import FilterBtn from '@/common/components/FilterBtn.vue';
import NotificationDetail from '@/components/patients/notifications/NotificationDetail.vue';
import NotificationsFilter from '@/components/notifications/filter/NotificationsFilter.vue';
import BloodPressureMeasurementDisplay from '@/common/components/devices/measurements/BloodPressureMeasurement.vue';
import OximeterMeasurementDisplay from '@/common/components/devices/measurements/OximeterMeasurement.vue';
import BloodGlucoseMeasurementDisplay from '../common/components/devices/measurements/BloodGlucoseMeasurement.vue';
import ThermometerMeasurementDisplay from '../common/components/devices/measurements/ThermometerMeasurement.vue';
import WeightScaleMeasurementDisplay from '../common/components/devices/measurements/WeightScaleMeasurement.vue';

@Component({
  filters: {
    startCase: webUtil.str.startCase,
  },
  components: {
    PrettyTs,
    FilterBtn,
    PageHeader,
    DeviceIcon,
    DeviceLabel,
    NotificationDetail,
    NotificationsFilter,
    BloodPressureMeasurementDisplay,
    BloodGlucoseMeasurementDisplay,
    ThermometerMeasurementDisplay,
    WeightScaleMeasurementDisplay,
    OximeterMeasurementDisplay,
  },
})
export default class Notifications extends Vue {
  @Watch('data.pageNum')
  onPageNumChg(): void {
    this.data.pagination.offset =
      (this.data.pageNum - 1) * this.data.pagination.limit;

    this.fetchData();
  }

  data = {
    filterDrawer: false,
    currentFilters: {},
    dialog: false,
    loading: 0,
    headers: [
      { text: this.getLocaleHdr('Date/Time'), value: 'ts' },
      { text: this.getLocaleHdr('Patient'), value: 'Patient.name' },
      { text: this.getLocaleHdr('Device Type'), value: 'deviceType' },
      { text: this.getLocaleHdr('measurements'), value: 'deviceData' },
      // { text: this.getLocaleHdr('Tags'), value: 'label' },
    ],
    rows: [],
    pagination: {
      offset: 0,
      limit: 20,
      total: 0,
      totalPages: 0,
    },
    filter: {},
    pageNum: 1,
    params: {},
  };
  lastRefreshTs = '';
  interval = -1;

  fmtUnits(unitIn: string): string {
    switch (unitIn) {
      case 'lb_av':
        return 'lbs';
      case 'pct':
        return '%';
      case 'degC':
        return '°C';
      case 'degF':
        return '°F';
      default:
        return unitIn;
    }
  }

  mounted(): void {
    this.fetchData();
  }

  getLocaleHdr(label: string): string {
    const camelLabel = this.$webUtil.str.camelCase(label);
    if (camelLabel !== 'dateTime') {
      return this.$webUtil.str.startCase(
        this.$t(`common.headers.${this.$webUtil.str.camelCase(label)}`),
      );
    }
    return this.$t(`common.headers.${camelLabel}`) as string;
  }

  async fetchData(force = false) {
    try {
      this.data.loading += 1;
      const {
        data: {
          Notifications: {
            Get: { docs, pageInfo },
          },
        },
      } = await this.$apollo.query({
        query: notificationsGql.listAll(),
        variables: {
          pagination: {
            offset: this.data.pagination.offset,
            limit: this.data.pagination.limit,
          },
          params: {
            filter: this.data.filter,
            sort: { createdAt: -1 },
          },
        },
        fetchPolicy: 'no-cache',
      });

      this.data.rows = docs;
      setPagination(this.data.pagination, pageInfo);
      this.setAutoRefresh(force);
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }

  get updatedFilters(): GenericObjectT {
    return this.data.currentFilters;
  }

  setFilterFromDrawer(value: GenericObjectT) {
    const filterValues = values(value);
    if (filterValues.length === 0) {
      this.data.currentFilters = {};
    } else {
      this.data.currentFilters = filterValues.pop();
    }
    this.data.filter = cloneDeep(this.data.currentFilters);
    this.fetchData();
  }

  setAutoRefresh(force: boolean) {
    this.lastRefreshTs = this.$webUtil.time.fullTs(moment().unix() * 1_000);
    if (force === true) {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = -1;
      }
    } else if (this.interval >= 0) {
      return;
    }

    this.interval = setInterval(() => this.fetchData(false), 60_000);
  }

  beforeDestroy(): void {
    clearInterval(this.interval);
    this.interval = -1;
  }
}
