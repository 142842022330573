
import { GenericObjectT } from '@/common/types/interfaces';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class KbArticleViewer extends Vue {
  @Prop({ default: () => ({}) }) content!: GenericObjectT;
  data = {
    loading: 0,
  };
}
