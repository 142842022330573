
import { Component, Vue, Emit } from 'vue-property-decorator';
import { GenericObjectT } from '@/common/types/interfaces';
import { webUtil } from '@/common/utils/web-utils';
import moment from 'moment';

@Component
export default class FilterByPatientAgeGroup extends Vue {
  data = {
    loading: 0,
    values: [],
    selected: '',
    filter: {},
  };

  get dataValues(): GenericObjectT[] {
    return this.data.values;
  }

  mounted(): void {
    this.buildSelection();
  }

  @Emit('updated-filter')
  updateFilter({ min, max }: { min: number; max: number }) {
    this.data.filter = {
      dob: { $lte: min, $gt: max },
    };

    if (min === undefined && max === undefined) {
      return { section: 'ageGroup', value: {} };
    }
    return {
      section: 'ageGroup',
      value: this.data.filter,
    };
  }

  buildSelection(): void {
    try {
      const ageGroups = [
        {
          text: 'All',
          value: {},
        },
      ];
      this.data.loading += 1;
      this.data.values = [];
      let prevValue = 0;
      let currValue = 0;
      for (let i = 10; i < 111; i += 10) {
        currValue = i;
        if (prevValue > 0) {
          const label = prevValue >= 20 ? `${prevValue}s` : 'Teens';
          ageGroups.push({
            text: label,
            value: {
              min: moment().subtract(prevValue, 'years').unix(),
              max: moment().subtract(currValue, 'years').unix(),
            },
          });
        }

        prevValue = currValue;
      }
      this.data.values = ageGroups as never;
    } catch (err) {
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }
}
