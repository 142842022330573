
import { get, map } from 'lodash';
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import FormDialog from '@/common/components/FormDialog.vue';
import { patientsGql } from '@/common/graphql/patients';
import { setPagination } from '@/common/utils/utils';
// eslint-disable-next-line
// @ts-ignore
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import moment from 'moment';
import { webUtil } from '@/common/utils/web-utils';

@Component({
  components: {
    FormDialog,
    VueTimepicker,
  },
})
export default class PatientEngagementForm extends Vue {
  @Prop() closeDialogFn!: unknown;

  @Emit('close-dialog')
  closeDialog(): void {
    return;
  }

  data = {
    loading: 0,
    selDate: moment().format('YYYY-MM-DD'),
    selTime: moment().format('HH:mm A'),
    duration: 1,
    pickDate: false,
    pickTime: false,
    fields: {
      startTs: 0,
      endTs: 0,
      type: '',
      content: '',
      patient: '',
    },
    engagementTypes: [],
    patients: [],
    pagination: {
      offset: 0,
      limit: 10,
      totalPages: 0,
      total: 0,
    },
    filter: {},
  };

  mounted(): void {
    this.patientList();
  }

  get isLoading(): boolean {
    return get(this.data, 'loading', 1) > 0;
  }

  async patientList() {
    try {
      this.data.loading += 1;
      const result = await this.$apollo.query({
        query: patientsGql.patients.byIdAndName(),
        variables: {
          pagination: {
            offset: this.data.pagination.offset,
            limit: this.data.pagination.limit,
          },
          params: this.data.filter,
        },
      });
      const {
        data: {
          Patients: {
            Get: { docs, pageInfo },
          },
          Dictionary: { EngagemenLogTypes },
        },
      } = result;

      this.data.engagementTypes = map(EngagemenLogTypes, (type: string) => ({
        id: type,
        label: webUtil.str.startCase(type),
      })) as never[];
      this.data.patients = docs;
      setPagination(this.data.pagination, pageInfo);

      return this.data.patients;
    } catch (err) {
      console.log(err);

      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }

  async createEngagementLog() {
    try {
      this.data.loading += 1;
      const startTimeEpoch = moment(
        `${this.data.selDate} ${this.data.selTime}`,
        'YYYY-MM-DD HH:mm A',
      ).unix();

      await this.$apollo.mutate({
        mutation: patientsGql.createEngagement(),
        variables: {
          patientId: this.data.fields.patient,
          data: {
            ...this.data.fields,
            startTs: startTimeEpoch,
            endTs: startTimeEpoch + this.data.duration * 60,
          },
        },
      });
      this.closeDialog();

      webUtil.notify.error(
        this.$toast,
        'Engagement log was added successfully',
      );
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }
}
