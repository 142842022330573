
import { GenericObjectT } from '@/common/types/interfaces';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { get, has, zipObject } from 'lodash';
import PrettyTs from '@/common/components/PrettyTs.vue';

@Component({
  components: {
    PrettyTs,
  },
})
export default class lastMeasurementDisplay extends Vue {
  @Prop({ default: '' }) deviceType!: string;
  @Prop({ default: '' }) patientId!: string;
  @Prop({ default: '' }) deviceId!: string;
  @Prop({ default: () => ({}) }) lastMeasurement!: GenericObjectT;

  data = {
    label: {},
  };

  // mounted(): void {
  //   this.preprocessLabel();
  // }

  convertToSec(dateString: string): number {
    return Math.floor(+new Date(dateString) / 1);
  }

  preprocessLabel(): void {
    const { label } = this.lastMeasurement;
    switch (this.deviceType) {
      case 'BloodPressure':
        this.data.label = zipObject(
          ['systolic', 'diastolic', 'heartRate'],
          label.split('_'),
        );
        break;
      case 'BloodGlucose':
        this.data.label = zipObject(['glucose', 'fasting'], label.split('_'));
        break;
      case 'Oximeter':
        this.data.label = zipObject(['sp02', 'heartRate'], label.split('_'));
        break;
      case 'Weight':
        this.data.label = zipObject(['weight'], label.split('_'));
        break;
      case 'Thermometer':
        this.data.label = zipObject(['temperature'], label.split('_'));
        break;
    }
  }

  highlight(key: string): string {
    const value = +get(this.data, ['label', key], '0');

    if (value === 1) {
      return 'high';
    } else if (value === -1) {
      return 'low';
    }
    return '';
  }

  get height(): number {
    return 150;
  }

  is(deviceIn: string): boolean {
    return this.deviceType === deviceIn;
  }

  hasValue(key: string): boolean {
    return has(this.lastMeasurement, key);
  }

  value(key: string): string {
    return get(this.lastMeasurement, key, 0);
  }
}
