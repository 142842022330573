
import { GenericObjectT } from '@/common/types/interfaces';
import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator';
import { map, forEach } from 'lodash';
import { webUtil } from '@/common/utils/web-utils';

@Component
export default class DonutChart extends Vue {
  @Prop({ default: false }) rawTitle!: boolean;
  @Prop({ default: 'Default' }) title!: string;
  @Prop({ default: () => ({}) }) rawData!: GenericObjectT;
  @PropSync('loadingValue', { default: 0 }) loading!: number;

  @Watch('rawData')
  onRawDataChg(): void {
    /**
     *  Expect Raw data in key-value-pair,
     *    where key is the label and value is the actual value
     */
    // eslint-disable-next-line
    const data: any = [];
    forEach(this.rawData, (value: number, key: string): void => {
      if (value > 0) {
        data.push({
          value,
          name: this.rawTitle ? key : webUtil.str.startCase(key),
        });
        this.data.total += +value;
      }
    });

    this.data.chartOptions.legend.data = map(data, 'name') as never[];
    this.data.chartOptions.series[0].name = this.title;
    this.data.chartOptions.series[0].data = data;
  }

  data = {
    total: 0,
    initOpts: {
      height: 300,
    },
    chartOptions: {
      tooltip: {
        trigger: 'item',
      },
      legend: {
        show: false,
        orient: 'horizontal',
        bottom: '10%',
        left: 'center',
        data: [],
      },
      series: [
        {
          name: '',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          selectedMode: 'single',
          itemStyle: {
            borderRadius: 5,
            borderColor: '#fff',
            borderWidth: 1,
          },
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '1.25em',
            },
            itemStyle: {
              shadowBlur: 5,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          labelLine: {
            show: false,
          },
          data: [],
        },
      ],
    },
  };
}
