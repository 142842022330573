import { webUtil } from '@/common/utils/web-utils';
import {
  get,
  has,
  flatten,
  isPlainObject,
  isArray,
  isNumber,
  isString,
  map,
  update,
  uniq,
} from 'lodash';

export type ruleTypeT = {
  op: string;
  field: ruleTypeT;
  fields: ruleTypeT[];
  value: number;
  valueUnit?: string;
  periodValue?: string;
  periodUnit?: string;
  deviceType?: string;
};

export type notificationDataT = {
  label: string;
  value: number;
};

export function opSymbol(
  opStr: string,
  values?: number[],
  unit?: string,
) {
  switch (opStr) {
    case 'And':
    case 'Or':
    case 'Not':
      return opStr.toLowerCase();
    case 'HistoricalValueGreaterThanOrEqual':
    case 'ValueGreaterThanOrEqual':
    case 'CountGreaterThanOrEqual':
      return '>=';
    case 'HistoricalValueLessThan':
    case 'ValueLessThan':
    case 'CountLessThan':
      return '<';
    case 'HistoricalValueInRange':
    case 'InRange':
      return 'between';
    // return `between (${get(values, [0], 0)} ${unit}, ${get(
    //   values,
    //   [1],
    //   0,
    // )} ${unit})`;
    case 'OutsideRange':
    case 'HistoricalValueOutsideRange':
      return 'outside of';
    // return `outside of (${get(values, [0], 0)} ${unit}, ${get(
    //   values,
    //   [1],
    //   0,
    // )} ${unit})`;
  }
}

export function valueUnitString(valueUnit: string): string {
  switch (valueUnit) {
    case 'lb_av':
      return 'lbs';
    case 'pct':
      return '%';
    case 'degF':
      return '°F';
    case 'degC':
      return '°C';
    case '{beats}/min':
      return 'bpm';
    default:
      return valueUnit;
  }
}

export function ruleHealthDevices(ruleData: any, camel = false): string[] {
  let devices: string[] = [];
  const ruleKey = camel ? 'deviceType' : 'device_type';
  if (isString(ruleData.field)) {
    return [get(ruleData, ruleKey, '')];
  } else if (isPlainObject(ruleData.field)) {
    devices = uniq([...devices, ...ruleHealthDevices(ruleData.field, camel)]);
  } else if (isArray(ruleData.fields)) {
    devices = uniq([
      ...devices,
      ...flatten(
        map(ruleData.fields, (data) => ruleHealthDevices(data, camel)),
      ),
    ]);
  }
  return devices;
}

export function parseRule(ruleData: any) {
  let parsed = '';
  const sample = {
    op: 'ValueGreaterThanOrEqual',
    deviceType: 'Thermometer',
    field: 'temperature',
    value: '101',
    valueUnit: 'degF',
  };

  if (isString(ruleData.field)) {
    parsed = `${webUtil.str.startCase(ruleData.field)}`;
    if (isNumber(ruleData.value) || isString(ruleData.value)) {
      parsed += ` ${opSymbol(ruleData.op)} `;
      parsed += `${ruleData.value} ${valueUnitString(
        get(ruleData, 'value_unit', ''),
      )}`;
    } else if (isArray(ruleData.value)) {
      parsed += ` ${opSymbol(
        ruleData.op,
        ruleData.value,
        ruleData.value_unit,
      )}`;
    }
    if (ruleData.op.startsWith('Historical')) {
      if (ruleData.period_value) {
        parsed += ` in the past ${ruleData.period_value}${ruleData.period_unit}`;
      }
    }
  } else if (isPlainObject(ruleData.field)) {
    parsed += `(${parseRule(ruleData.field)})`;
    if (ruleData.op) {
      parsed += ` ${opSymbol(ruleData.op)} `;
      parsed += `${ruleData.value} ${valueUnitString(
        get(ruleData, 'value_unit', ''),
      )}`;
    }
  } else if (isArray(ruleData.fields)) {
    parsed += map(ruleData.fields, (data) => parseRule(data)).join(
      ` ${opSymbol(ruleData.op)} `,
    );
  }
  return parsed;
}

export const ruleDictionary = {
  devices: {
    bloodPressure: {
      values: [
        {
          label: 'systolic',
          unit: 'mm[Hg]',
          display: valueUnitString('mm[Hg]'),
          range: { min: 0, max: 200 },
        },
        {
          label: 'diastolic',
          unit: 'mm[Hg]',
          display: valueUnitString('mm[Hg]'),
          range: { min: 0, max: 200 },
        },
        {
          label: 'heartRate',
          unit: '{beats}/min',
          display: valueUnitString('{beats}/min'),
          range: { min: 0, max: 400 },
        },
      ],
    },
    bloodGlucose: {
      values: [
        {
          label: 'glucose',
          unit: 'mg/dL',
          display: valueUnitString('mg/dL'),
          range: { min: 0, max: 600 },
        },
      ],
    },
    thermometer: {
      values: [
        {
          label: 'temperature',
          unit: 'degF',
          display: valueUnitString('degF'),
          range: { min: 32, max: 110 },
        },
      ],
    },
    oximeter: {
      values: [
        {
          label: 'sp02',
          unit: 'pct',
          display: valueUnitString('%'),
          range: { min: 0, max: 100 },
        },
        {
          label: 'heartRate',
          unit: '{beats}/min',
          display: valueUnitString('{beats}/min'),
          range: { min: 0, max: 220 },
        },
      ],
    },
    weightScale: {
      values: [
        {
          label: 'weight',
          unit: 'lb_av',
          display: valueUnitString('lb_av'),
          range: { min: 0, max: 800 },
        },
      ],
    },
  },
  operators: [
    { label: 'In Range', value: 'InRange', rangeOperator: true },
    { label: 'Outside Range', value: 'OutsideRange', rangeOperator: true },
    { label: '>=', value: 'ValueGreaterThanOrEqual', rangeOperator: false },
    { label: '<', value: 'ValueLessThan', rangeOperator: false },
  ],
};

export class RuleEntry {
  op = '';
  tenant_id = '';
  patient_id = '';
  device_id = '';
  device_type = '';

  fields = [];
  field = '';
  value = 0;
  value_unit = 0;
  rate_value = null;
  rate_unit = '';
  period_value = -1;
  period_unit = '';
}
