
import {
  Component,
  Vue,
  Emit,
  Prop,
  PropSync,
  Watch,
} from 'vue-property-decorator';
import { GenericObjectT } from '../../types/interfaces';
import { RuleEntry, ruleDictionary } from '@/common/utils/rules';
import {
  get,
  map,
  find,
  inRange,
  isEmpty,
  startCase,
  camelCase,
  forEach,
} from 'lodash';
import { pascalCase } from '@/common/utils/utils';

@Component
export default class AdvancedRuleCondition extends Vue {
  @Prop({ default: '' }) deviceType!: string;
  @PropSync('ruleData', { default: new RuleEntry() }) rule!: GenericObjectT;
  // @PropSync('showDialog', { type: Boolean }) dialog!: boolean;

  valid = true;
  currentRule = {
    deviceType: '',
    field: '',
    op: { rangeOperator: false, value: '' },
    value: '',
    values: [],
  };

  @Watch('deviceType')
  onDeviceTypeInputChanged(value: string): void {
    console.log(`Input device type: ${this.deviceType} -- ${value}`);
    this.currentRule.deviceType = this.deviceType;
  }

  @Emit('dismiss-dialog')
  dismissDialog() {
    return false;
  }

  getHealthDevices(): GenericObjectT[] {
    const devTypes = map(Object.keys(ruleDictionary.devices), (key: string) => {
      return { label: startCase(key), value: pascalCase(key) };
    });

    return devTypes;
  }

  getDevices() {
    if (isEmpty(this.currentRule.deviceType)) {
      return '';
    }
    return get(
      ruleDictionary,
      ['devices', camelCase(this.currentRule.deviceType)],
      {},
    );
  }

  getDeviceLabels(): GenericObjectT[] {
    const devices = this.getDevices();
    if (isEmpty(devices)) {
      return [];
    }
    return map(devices.values, (value: any): GenericObjectT => {
      return { label: startCase(value.label), value: value.label };
    });
  }

  getRuleOperators() {
    return ruleDictionary.operators;
  }

  getSelectedDeviceEntry() {
    if (
      isEmpty(this.currentRule.deviceType) ||
      isEmpty(this.currentRule.field)
    ) {
      return '';
    }

    const currentDevice = this.getDevices();
    if (isEmpty(currentDevice)) {
      return '';
    }
    return find(currentDevice.values, {
      label: camelCase(this.currentRule.field),
    });
  }

  getLabelUnit(): string {
    const currentDevice = this.getSelectedDeviceEntry();
    if (isEmpty(currentDevice)) {
      return '';
    }

    return get(currentDevice, 'display', get(currentDevice, 'unit', 'Unknown'));
  }

  getValueUnit(): string {
    const currentDevice = this.getSelectedDeviceEntry();
    if (isEmpty(currentDevice)) {
      return '';
    }

    return get(currentDevice, 'unit', '');
  }

  getLabelRules() {
    const currentDevice = this.getSelectedDeviceEntry();
    if (isEmpty(currentDevice)) {
      return '';
    }
    const valueRange = get(currentDevice, 'range', []);
    if (isEmpty(valueRange)) {
      return true;
    }

    const currValue = this.currentRule.value as unknown as number;
    if (!inRange(currValue, valueRange.min, valueRange.max + 1)) {
      return `Invalid value ${currValue} entered, [${valueRange.min}, ${valueRange.max}]`;
    }
    return true;
  }

  valueRange() {
    if (!this.currentRule.op.rangeOperator) {
      return true;
    }
    if (
      parseInt(this.currentRule.values[0]) >=
      parseInt(this.currentRule.values[1])
    ) {
      return `End Range must be larger than start range`;
    }
    return true;
  }

  clearSelection(clearFields: string[]) {
    forEach(Object.keys(this.currentRule), (key: string): void => {
      if (clearFields.indexOf(key) >= 0) {
        if (key === 'values') {
          this.currentRule[key] = [];
        } else {
          // @ts-ignore: intentional change
          this.currentRule[key] = '';
        }
      }
    });
  }

  resetCurrentRule() {
    this.currentRule = {
      deviceType: this.deviceType,
      field: '',
      op: { rangeOperator: false, value: '' },
      value: '',
      values: [],
    };
  }

  @Emit('set-new-rule')
  setRuleData(): void {
    console.log(`Current rule: ${JSON.stringify(this.currentRule)}`);
    this.rule.field = this.currentRule.field;
    this.rule.op = this.currentRule.op.value;
    this.rule.device_type = this.currentRule.deviceType;
    if (this.currentRule.op.rangeOperator) {
      this.rule.value = this.currentRule.values;
    } else {
      this.rule.value = this.currentRule.value;
    }

    this.rule.value_unit = this.getValueUnit();

    this.resetCurrentRule();
  }
}
