import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { messages } from '@/common/i18n';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // fallback locale
  messages, // set locale messages
});
