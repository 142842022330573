
import { devicesGql } from '@/common/graphql/devices';
import { GenericObjectT } from '@/common/types/interfaces';
import { webUtil } from '@/common/utils/web-utils';
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import { get, map, forEach } from 'lodash';

@Component
export default class FilterByDeviceType extends Vue {
  @Prop({ default: 'devices.type' }) filterKey!: string;

  @Emit('updated-filter')
  updateSelected(): GenericObjectT {
    const currentFilter = this.data.values.filter(
      (value: GenericObjectT): boolean => value.selected,
    );
    this.data.filter = currentFilter;
    let updatedFilter: GenericObjectT = {};

    if (currentFilter.length === 1) {
      updatedFilter = {
        [this.filterKey]: get(currentFilter, '0.value'),
      };
    } else if (currentFilter.length > 1) {
      updatedFilter = {
        $or: [],
      };

      forEach(currentFilter, (filter: GenericObjectT): void => {
        updatedFilter.$or.push({ [this.filterKey]: get(filter, 'value') });
      });
    }

    return { section: 'byDeviceType', value: updatedFilter };
  }

  data = {
    loading: 0,
    values: [],
    selected: '',
    filter: {},
  };

  get dataValues(): GenericObjectT[] {
    return this.data.values;
  }

  mounted(): void {
    this.fetchData();
  }

  async fetchData(): Promise<void> {
    try {
      this.data.loading += 1;
      const {
        data: {
          Devices: { Distinct },
        },
      } = await this.$apollo.query({
        query: devicesGql.query.distinctTypes(),
      });

      this.data.values = map(Distinct, (value: string): GenericObjectT => {
        return {
          text: webUtil.str.startCase(value),
          value,
          selected: false,
        };
      }) as never[];
    } catch (err) {
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }
}
