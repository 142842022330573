
import { Component, Vue, Prop } from 'vue-property-decorator';
import { GenericObjectT } from '@/common/types/interfaces';
import { flatten, map } from 'lodash';
import { tenantsGql } from '@/common/graphql/tenants';
import ToolTip from '@/common/components/ToolTip.vue';
import FormDialog from '@/common/components/FormDialog.vue';
import NotificationDeviceSettings from '@/components/notifications/settings/NotificationDeviceSetting.vue';
import { webUtil } from '@/common/utils/web-utils';

@Component({
  filters: {
    startCase: webUtil.str.startCase,
  },
  components: {
    ToolTip,
    FormDialog,
    NotificationDeviceSettings,
  },
})
export default class NotificationSettingForm extends Vue {
  @Prop({ default: '' }) deviceType!: string;

  data = {
    loading: 0,
    dialog: false,
    headers: [],
    rows: [],
    pagination: {
      limit: 10,
    },
  };

  get isLoading(): boolean {
    return this.data.loading > 0;
  }

  mounted(): void {
    this.fetchData();
  }

  get tableHeaders(): GenericObjectT {
    return [
      { text: this.getLocaleHdr('deviceType'), value: 'deviceType' },
      {
        text: this.getLocaleHdr('alertTriggers'),
        value: 'matcher',
        sortable: false,
      },
      { text: this.getLocaleHdr('notifyCareTeam'), value: 'deliverToCareTeam' },
      { text: this.getLocaleHdr('actions'), value: 'actions', sortable: false },
    ];
  }

  getLocaleHdr(label: string): string {
    const camelLabel = this.$webUtil.str.camelCase(label);
    if (camelLabel !== 'dateTime') {
      return this.$webUtil.str.startCase(
        this.$t(`common.headers.${camelLabel}`),
      );
    }
    return this.$t(`common.headers.${camelLabel}`) as string;
  }

  closeDialog(): void {
    this.data.dialog = false;
  }

  annotateDevices(devices: string[], notifSettings: GenericObjectT[]): void {
    this.data.rows = flatten(
      map(devices, (deviceType: string): GenericObjectT | GenericObjectT[] => {
        const notifSettingByDevType = notifSettings.filter(
          (ns: GenericObjectT) => ns.deviceType === deviceType,
        );
        console.log(
          `Notif setting by ${deviceType}: ${JSON.stringify(
            notifSettingByDevType,
          )}`,
        );
        if (notifSettingByDevType.length) {
          return map(
            notifSettingByDevType,
            (item: GenericObjectT): GenericObjectT => {
              return {
                deviceType: item.deviceType,
                matcher: item.matcher,
                deliverToCareTeam: item.deliverToCareTeam,
              };
            },
          );
        } else {
          return {
            deviceType,
            matcher: this.dfltMatcherByDeviceType(deviceType),
            deliverToCareTeam: [],
          };
        }
      }),
    ) as never[];
  }

  dfltMatcherByDeviceType(deviceType: string): string {
    let dfltMatcher = '';
    switch (this.$webUtil.str.camelCase(deviceType)) {
      case 'bloodPressure':
        dfltMatcher = 'x_x_x';
        break;
      case 'bloodGlucose':
      case 'oximeter':
        dfltMatcher = 'x_x';
        break;
      case 'thermometer':
      case 'weight':
        dfltMatcher = 'x';
        break;
    }
    return dfltMatcher;
  }

  async fetchData() {
    try {
      this.data.loading += 1;
      const {
        data: {
          Devices: { Distinct },
          Tenant: { NotificationSettings },
        },
      } = await this.$apollo.query({
        query: tenantsGql.query.notifSettings(),
      });
      this.annotateDevices(Distinct, NotificationSettings);
    } catch (err) {
      console.log(err);
      // this.$webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }

  async saveNotificationSetting() {
    try {
      this.data.loading += 1;
      console.log(`Notification setting save invoked.`);
    } catch (err) {
      console.log(err);
      this.$webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }
}
