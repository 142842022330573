
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { get } from 'lodash';
import { patientsGql } from '@/common/graphql/patients';
import FormDialog from '@/common/components/FormDialog.vue';
import { webUtil } from '@/common/utils/web-utils';

@Component({
  components: {
    FormDialog,
  },
})
export default class PatientNoteForm extends Vue {
  @Prop({ default: '' }) patientId!: string;
  @Prop() closeDialogFn!: unknown;

  data = {
    loading: 0,
    fields: {
      content: '',
    },
  };

  @Emit('close-dialog')
  closeDialog(): void {
    return;
  }

  get isLoading(): boolean {
    return get(this.data, 'loading', 1) > 0;
  }

  async saveNewNote() {
    try {
      this.data.loading += 1;
      await this.$apollo.mutate({
        mutation: patientsGql.createNote(),
        variables: {
          patientId: this.patientId,
          note: this.data.fields.content,
        },
      });
      this.closeDialog();

      webUtil.notify.error(this.$toast, 'Note was added successfully');
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }
}
