
import { Component, Vue } from 'vue-property-decorator';
import { camelCase } from 'lodash';
import SubMenuBar from '@/common/components/menu/SubMenu.vue';

@Component({
  components: {
    SubMenuBar,
  },
})
export default class SideBar extends Vue {
  mini = false;
  renderComponent = true;

  subMenus() {
    return [
      {
        subheader: '',
        perm: { action: 'update', subject: 'patients' },
        items: [
          // [
          //   'mdi-home-outline',
          //   this.getLabel('Dashboard'),
          //   '/dashboard',
          //   { action: 'read', subject: 'dashboard' },
          // ],
          [
            'mdi-account-supervisor-circle',
            this.getLabel('Patients'),
            '/patients',
            { action: 'update', subject: 'patients' },
          ],
          [
            'mdi-page-next-outline',
            this.getLabel('Measurements'),
            '/measurements',
            { action: 'read', subject: 'engagement-log' },
          ],
        ],
      },
      {
        subheader: this.getLabel('Manage'),
        perm: { action: 'update', subject: 'patients' },
        items: [
          // [
          //   'mdi-clipboard-edit-outline',
          //   this.getLabel('Patient Engagements'),
          //   '/engagements',
          //   { action: 'update', subject: 'patients' },
          // ],
          // [
          //   'mdi-book-open-page-variant-outline',
          //   this.getLabel('Knowledge Base'),
          //   '/knowledge-base',
          //   { action: 'read', subject: 'knowledge-base' },
          // ],
          // [
          //   'mdi-email-fast-outline',
          //   this.getLabel('Messages'),
          //   '/messages',
          //   { action: 'create', subject: 'messages' },
          // ],
          [
            'mdi-cog-outline',
            this.getLabel('Settings'),
            '/settings',
            { action: 'update', subject: 'settings' },
          ],
        ],
      },
      {
        subheader: 'Equipments',
        perm: { action: 'manage', subject: 'equipments' },
        items: [
          [
            'mdi-video-input-antenna',
            'Gateways',
            '/gateways',
            { action: 'manage', subject: 'gateways' },
          ],
          [
            'mdi-devices',
            'Devices',
            '/devices',
            { action: 'manage', subject: 'devices' },
          ],
        ],
      },
      {
        subheader: 'Support',
        perm: { action: 'manage', subject: 'operations' },
        items: [
          [
            'mdi-wrench-cog-outline',
            'Requests',
            '/equipment-requests',
            { action: 'manage', subject: 'equipment-requests' },
          ],
          [
            'mdi-import',
            'Bulk Import',
            '/bulk-import',
            { action: 'create', subject: 'bulk-import' },
          ],
        ],
      },
    ];
  }

  getLabel(label: string): string {
    return this.$webUtil.str.startCase(
      this.$t(`common.headers.${camelCase(label)}`),
    );
  }
}
