
import { Component, Vue } from 'vue-property-decorator';
import { setPagination } from '@/common/utils/utils';
import { webUtil } from '@/common/utils/web-utils';
import { get } from 'lodash';
import PageHeader from '@/common/components/PageHeader.vue';
import RequestFulfillment from '@/common/components/devices/requests/RequestFulfillment.vue';
import { GenericObjectT } from '@/common/types/interfaces';
import { supportGql } from '@/common/graphql/support';

@Component({
  filters: {
    startCase: webUtil.str.startCase,
  },
  components: {
    PageHeader,
    RequestFulfillment,
  },
})
export default class EquipmentRequests extends Vue {
  data = {
    loading: 0,
    headers: [
      { text: 'Patient', value: 'firstName' },
      { text: 'Requested', value: 'requestedDevices' },
      { text: 'Action', value: 'action' },
    ],
    rows: [{}],
    pagination: {
      offset: 0,
      limit: 15,
      total: 0,
      totalPages: 0,
    },
    params: {
      filter: {
        requestedDevices: {
          $gt: { $size: 0 },
          $elemMatch: { completedAt: { $lte: 0 } },
        },
      },
      sort: {},
    },
  };

  allRequestedDevices(item: GenericObjectT) {
    return get(item, 'requestedDevices', []).filter((dev: GenericObjectT) => {
      return get(dev, 'completedAt', 0) <= 0;
    });
  }

  mounted(): void {
    this.fetchData();
  }

  async fetchData() {
    try {
      this.data.loading += 1;
      const {
        data: {
          Support: {
            Get: {
              Patients: { docs, pageInfo },
            },
          },
        },
      } = await this.$apollo.query({
        query: supportGql.query.patients(),
        variables: {
          pagination: {
            offset: this.data.pagination.offset,
            limit: this.data.pagination.limit,
          },
          params: this.data.params,
        },
        fetchPolicy: 'network-only',
      });

      this.data.rows = docs;
      setPagination(this.data.pagination, pageInfo);
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }

  dialogClosed() {
    console.log('dialog closed [EquipmentRequests] invoked');
    this.fetchData();
  }
}
