
import { Component, Vue } from 'vue-property-decorator';
import { forEach } from 'lodash';
import { dashboardsGql } from '@/common/graphql/dashboards';
import { GenericObjectT } from '@/common/types/interfaces';
import { webUtil } from '@/common/utils/web-utils';

@Component
export default class MeasurementsStats extends Vue {
  data = {
    loading: 0,
    stats: {
      stats: [],
      patients: 0,
    },
    initOpts: {
      height: 300,
    },
    chartOptions: {
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        type: 'value',
        data: [''],
      },
      yAxis: {
        type: 'value',
        min: 0,
        max: 100,
        axisLabel: {
          formatter: `{value} %`,
        },
      },
      series: [{}],
    },
  };

  mounted(): void {
    this.fetchData();
  }

  async fetchData() {
    try {
      this.data.loading += 1;
      const {
        data: {
          Dashboards: { MeasurementStats },
        },
      } = await this.$apollo.query({
        query: dashboardsGql.query.measurementStats(),
      });

      this.data.stats = MeasurementStats;
      this.procChartData();
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }

  procChartData(): void {
    const xLabels: string[] = [];
    const series = {
      data: [],
      name: 'Measurements',
      type: 'bar',
      smooth: true,
      showBackground: true,
      backgroundStyle: {
        color: 'rgba(180, 180, 180, 0.2)',
      },
    };
    const { stats, patients } = this.data.stats;

    forEach(stats, (values: GenericObjectT, ts: number): void => {
      xLabels.push(webUtil.time.custom((ts * 1000).toString(), 'ddd, MMM DD'));
      series.data.push(Math.round((values.measured / patients) * 100) as never);
    });

    this.data.chartOptions = {
      ...this.data.chartOptions,
      ...{
        xAxis: {
          data: xLabels,
        },
      },
      visualMap: [
        {
          show: false,
          type: 'continuous',
          seriesIndex: 0,
          min: 100,
          max: 30,
          inRange: {
            color: ['#FD665F', '#FFCE34', '#65B581'],
          },
        },
      ],
      series: [series as never],
      // eslint-disable-next-line
    } as any;
  }
}
