
import { Component, Vue, Prop } from 'vue-property-decorator';
import { camelCase } from 'lodash';
import BloodPressureGoals from '@/common/components/devices/goals/BloodPressureGoals.vue';
import BloodGlucoseGoals from '@/common/components/devices/goals/BloodGlucoseGoals.vue';
import WeightGoals from '@/common/components/devices/goals/WeightGoals.vue';
import OximeterGoals from '@/common/components/devices/goals/OximeterGoal.vue';

@Component({
  components: {
    OximeterGoals,
    WeightGoals,
    BloodPressureGoals,
    BloodGlucoseGoals,
  },
})
export default class DeviceGoals extends Vue {
  @Prop({ default: '' }) deviceType!: string;
  @Prop({ default: () => ({}) }) goal!: object;

  is(devIn: string): boolean {
    return camelCase(this.deviceType) === camelCase(devIn);
  }
}
