
import { get, map, isEmpty, camelCase } from 'lodash';
import { webUtil } from '@/common/utils/web-utils';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { GenericObjectT } from '@/common/types/interfaces';
import PrettyTs from './PrettyTs.vue';
import moment from 'moment';

@Component({
  filters: {
    startCase: webUtil.str.startCase,
  },
  components: {
    PrettyTs,
  },
})
export default class MeasurementTooltipBody extends Vue {
  @Prop({ default: () => ({}) }) lastMeasurement!: GenericObjectT;
  @Prop({ default: () => ({}) }) points!: GenericObjectT;
  @Prop({ default: '' }) deviceType!: string;
  @Prop({ default: true }) showDetailedTs!: boolean;

  isToday = this.$webUtil.time.isToday;

  convertToSecond(key: string, timeString: string): number {
    console.log(`Got ${key}: ${timeString}`);
    // return Math.floor(+new Date(timeString) / 1000);

    return moment.utc(timeString).unix();
  }

  fromAgo(timeString: string) {
    if (!isEmpty(timeString)) {
      return moment.utc(timeString).fromNow();
    }
    return 'n/a';
  }

  get viewableData(): GenericObjectT {
    return get(this, 'lastMeasurement', {});
  }

  valueUnit(key: string): string {
    switch (camelCase(key)) {
      case 'systolic':
      case 'diastolic':
        return 'mmHg';
      case 'sp02':
        return '%';
      case 'heartRate':
        return 'bpm';
      case 'weight':
        return 'lbs';
    }
    return '';
  }

  viewableLabel(key: string): string {
    if (key === 'timestamp') {
      return this.$webUtil.str.startCase(
        this.$t('common.words.measured') as string,
      );
    } else {
      return this.$webUtil.str.startCase(this.$t(`common.deviceWords.${key}`));
    }
  }

  viewableFields(): string[] {
    switch (this.deviceType) {
      case 'BloodPressure':
        return ['timestamp', 'systolic', 'diastolic', 'heartRate'];
      case 'BloodGlucose':
        return ['timestamp', 'glucose'];
      case 'WeightScale':
        return ['timestamp', 'weight'];
      case 'Oximeter':
        return ['timestamp', 'sp02', 'heartRate'];
      case 'Thermometer':
        return ['timestamp', 'temperature'];
    }
    return [];
  }

  sparklineData(key: string): number[] {
    return map(this.points, key);
  }
}
