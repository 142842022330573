
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { notificationDataT } from '@/common/utils/rules';
import RuleNotificationForm from '@/common/components/rules/RuleNotificationForm.vue';

@Component({ components: { RuleNotificationForm } })
export default class RuleNotificationConfigure extends Vue {
  @Prop({ default: '' }) ruleId!: string;
  @Prop({ default: false }) inherited!: boolean;
  @Prop({ default: () => ({}) }) notificationData!: notificationDataT;

  @Emit('updated-notif-data')
  onSaveData(value: notificationDataT): {
    ruleId: string;
    value: notificationDataT;
  } {
    return { ruleId: this.ruleId, value };
  }
}
