export const id = {
  deviceType: {
    bloodPressure: {
      text: 'tekanan darah',
      monitor: 'monitor tekanan darah',
    },
    bloodGlucose: {
      text: 'glukosa darah',
      monitor: 'monitor glukosa darah',
    },
    thermometer: {
      text: 'termometer',
    },
    oximeter: { text: 'oksimeter' },
    weight: {
      text: 'berat',
      scale: 'timbangan berat badan',
    },
  },
  common: {
    words: {
      days: 'hari',
      last: 'terakhir',
      measured: 'terukur',
      missed: 'kehilangan',
      years: 'tahun',
      yearsOld: 'tahun',
      measurement: 'pengukuran',
      noDeviceFound: 'tidak ditemukan perangkat',
      noMessageFound: 'tidak ditemukan pesan',
      noDataAvailable: 'tidak tersedia data',
      filter: 'filter',
      notify: 'melaporkan',
      filterApplied: 'filter diterapkan',
      lastMeasured: 'terakhir diukur',
      setting: 'pengaturan',
      delivered: 'terkirim',
      types: 'jenis',
      conditions: 'kondisi',
      when: 'jika',
      note: 'catatan',
      isBetween: 'antara',
      and: 'dan',
      article: 'artikel',
      over: 'di atas',
      times: 'kali',
      period: 'Periode',
      showMore: 'lebih',
      direction: 'arah',
      occurrences: 'Kejadian',
      engagementLog: 'log keterlibatan',
      checkIfSettingOccur: 'Memeriksa apakah pengaturan terjadi',
      checkIfSettingNotOccur:
        'Jangan periksa pengaturan pada pengukuran historis',
      basicInfo: 'Informasi Dasar',
      contactInfo: 'Kontak Informasi',
      remoteMonProgress: 'Progres Pemantauan Jarak Jauh',
      gateway: 'Gerbang',
      completedSinceLastBilling: 'Sejak siklus penagihan terakhir',
      last2Weeks: '2 minggu terakhir',
      lastMonth: 'bulan kemarin',
      last3Months: '3 bulan terakhir',
      cfgedNotYetSeen: 'Dikonfigurasi, tetapi belum terlihat',
      lastSeen: 'Terakhir terlihat',
      assign: 'tetapkan',
      replace: 'ganti',
      remove: 'hapus',
      byName: 'dengan name',
      byAgeGroup: 'menurut kelompok usia',
      byDeviceTypes: 'menurut tipe perangkat',
      any: 'apa saja',
    },
    title: {
      patientAge: 'umur patient',
      deviceDistribution: 'distribusi perangkat',
      patientAgeDistribution: 'distribusi usia pasien',
      deviceTypeDistribution: 'distribusi jenis perangkat',
      newPatientEngLog: 'log keterlibatan pasien baru',
      newPatientNote: 'catatan pasien baru',
      notificationSetting: 'pengaturan notifikasi',
    },
    btnLabel: {
      new: 'baru',
      refresh: 'refresh',
      clearFilter: 'clear filter',
      send: 'kirim',
      resend: 'kirim lagi',
      reset: 'reset',
      save: 'simpan',
      cancel: 'batal',
      requestDevice: 'minta perangkat',
      requested: 'diminta',
      requestGateway: 'minta gerbang',
      discard: 'buang',
      dismiss: 'bubar',
      apply: 'terapkan',
      readMore: 'baca lebih lanjut',
    },
    formPlaceholder: {
      selectPerson: 'Pilih orang',
      typeASubject: 'Ketik subjek ...',
      typeAMessage: 'Ketik pesan ...',
      typeANote: 'Ketik catatan ...',
      chooseEngType: 'Pilih log keterlibatan',
      typePartialName: 'Ketik sebagian name ...',
    },
    deviceWords: {
      sp02: 'sp02',
      systolic: 'sistolik',
      diastolic: 'diastolik',
      heartRate: 'denyut',
      glucose: 'glukosa',
      fasting: 'puasa',
      temperature: 'suhu',
      weight: 'berat',
      lookback: 'liat kebelakang',
      value: 'nilai',
    },
    tpl: {
      xAge: '{age} tahun',
      deviceDetail: '{device} detail',
      measurementStats: 'Statistik pengukuran dari {count} pasien',
      listOfX: 'Daftar {msg}',
      resendMsg: 'Kirim pesan untuk {person}',
    },
    roles: {
      careTaker: 'pengurus',
    },
    headers: {
      name: 'Nama',
      age: 'Umur',
      notes: 'Catatan',
      devices: 'Perangkat',
      lastMeasurements: 'Pengukuran Terakhir',
      dateTime: 'Tanggal/Jam',
      patient: 'Pasien',
      patients: 'Pasien',
      deviceType: 'Tipe Perangkat',
      tags: 'Tags',
      dashboard: 'dashboard',
      measurements: 'pengukuran',
      knowledgeBase: 'Basis pengetahuan',
      messages: 'pesan',
      message: 'pesan',
      alertTriggers: 'Pemicu peringatan',
      conversations: 'Percakapan',
      automated: 'Otomatis',
      createdAt: 'Dibuat',
      actions: 'Tindakan',
      receipts: 'Tanda Terima',
      manage: 'Mengelola',
      settings: 'Pengaturan',
      duration: 'duraso',
      content: 'isi',
      log: 'catatan',
      email: 'Email',
      address: 'Alamat',
      phone: 'Phone',
      since: 'Sejak',
      goal: 'Tujuan',
      overview: 'Ringkasan',
      active: 'aktif',
      patientEngagements: 'keterlibatan pasien',
      lastTwoComparison: 'Perbandingan dua pengukuran terakhir',
      notifyCareTeam: 'tim perawatan untuk diberi tahu',
    },
  },
};
