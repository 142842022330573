
import { Component, Vue } from 'vue-property-decorator';
import { knowledgeBasesGql } from '@/common/graphql/knowledge-bases';
import { setPagination } from '@/common/utils/utils';
import { webUtil } from '@/common/utils/web-utils';
import PageHeader from '@/common/components/PageHeader.vue';
import KbArticleViewer from '@/components/knowledge-bases/KbArticleViewer.vue';

@Component({
  components: {
    PageHeader,
    KbArticleViewer,
  },
})
export default class KnowledgeBaseArticles extends Vue {
  truncate = webUtil.str.truncate;

  data = {
    loading: 0,
    kbId: '',
    articles: [],
    selected: 0,
    pagination: {
      offset: 0,
      limit: 15,
      total: 0,
      totalPages: 0,
    },
    filter: {},
    sort: {},
  };

  mounted(): void {
    this.data.kbId = this.$route.params.kbId;
    this.fetchData();
  }

  async fetchData() {
    try {
      this.data.loading += 1;
      const {
        data: {
          KnowledgeBase: {
            Articles: { docs, pageInfo },
          },
        },
      } = await this.$apollo.query({
        query: knowledgeBasesGql.listArticles(),
        variables: {
          kbId: this.data.kbId,
          pagination: {
            offset: this.data.pagination.offset,
            limit: this.data.pagination.limit,
          },
          params: {
            filter: this.data.filter,
            sort: this.data.sort,
          },
        },
      });

      this.data.articles = docs;
      setPagination(this.data.pagination, pageInfo);
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }
}
