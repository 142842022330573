const bpThreshold = {
  kind: 'BloodPressureThreshold',
  systolic: {
    max: 180,
    min: 80,
    occurence: 2,
    period: 604800,
  },
  diastolic: {
    max: 120,
    min: 50,
    occurence: 2,
    period: 604800,
  },
  heartRate: {
    max: 110,
    min: 60,
    occurence: 2,
    period: 604800,
  },
};

const bgThreshold = {
  kind: 'BloodGlucoseThreshold',
  glucose: {
    min: 70,
    max: 450,
    occurence: 2,
    period: 604800,
  },
};

const weightThreshold = {
  kind: 'WeightThreshold',
  weight: [
    {
      type: 'current',
      lookback: 7,
      value: 5,
      direction: 'inc',
      occurence: 1,
      period: 86400,
    },
    {
      type: 'historical',
      value: 3,
      direction: 'inc',
      occurence: 1,
      period: 86400,
    },
    {
      type: 'historical',
      value: 3,
      direction: 'dec',
      occurence: 1,
      period: 86400,
    },
    {
      type: 'historical',
      value: 5,
      direction: 'inc',
      occurence: 1,
      period: 604800,
    },
    {
      type: 'historical',
      value: 7,
      direction: 'dec',
      occurence: 1,
      period: 604800,
    },
  ],
};

const oximeterThreshold = {
  kind: 'OximeterThreshold',
  sp02: {
    min: 90,
    max: 100,
    occurence: 2,
    period: 60,
  },
  heartRate: {
    max: 110,
    min: 60,
    occurence: 2,
    period: 60,
  },
};

const thermometerThreshold = {
  kind: 'ThermometerThreshold',
  temperature: {
    min: 96,
    max: 105,
    occurence: 2,
    period: 600,
  },
};

export const deviceDfltSettings = {
  bloodPressure: bpThreshold,
  bloodGlucose: bgThreshold,
  weight: weightThreshold,
  oximeter: oximeterThreshold,
  thermometer: thermometerThreshold,
};
