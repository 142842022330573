
import { Component, Vue } from 'vue-property-decorator';
import SideBar from '@/common/components/menu/SideBar.vue';
import AccountSetting from '@/components/accounts/AccountSetting.vue';

@Component({
  components: {
    SideBar,
    AccountSetting,
  },
})
export default class App extends Vue {
  get loading(): boolean {
    return this.$store.getters.authLoading;
  }

  get isShow(): boolean {
    return !this.loading && this.$store.getters.isAuthenticated;
  }
}
