
import { get, find, forEach, isEmpty } from 'lodash';
import { GenericObjectT } from '@/common/types/interfaces';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { webUtil } from '@/common/utils/web-utils';
import { supportGql } from '@/common/graphql/support';
import FormDialog from '@/common/components/FormDialog.vue';

@Component({
  components: {
    FormDialog,
  },
})
export default class RequestFulfillment extends Vue {
  @Prop({ default: '' }) patientId!: string;
  @Prop({ default: () => [] }) requestedDevices!: GenericObjectT[];
  @Emit('close-dialog')
  closeDialog(): void {
    this.data.dialog = false;
    this.data.step = 1;
    console.log(`Close dialog [RequestFulfillment] invoked`);
  }

  @Watch('data.step')
  onDataStepChg(): void {
    this.loadStepData(this.data.step);
  }

  onDataFieldChange(evt: string): void {
    if (!isEmpty(evt)) {
      this.loadStepData(this.data.step, {
        serialNumber: { $regex: evt, $options: 'i' },
      });
    } else {
      this.loadStepData(this.data.step, {});
    }
  }

  get isLoading(): boolean {
    return this.data.loading > 0;
  }

  get hasGateway(): boolean {
    const requestedGw = find(this.requestedDevices, {
      device: 'None',
      gateway: true,
      completedAt: 0,
    });

    return !isEmpty(requestedGw);
  }

  get items() {
    return this.data.items;
  }

  isFieldEmpty(stepId: string): boolean {
    return isEmpty(get(this.data, ['fields', stepId], ''));
  }

  mounted(): void {
    this.procWizardSteps();
    this.loadStepData(1);
  }

  search = '';
  data = {
    loading: 0,
    dialog: false,
    step: 1,
    steps: [],
    fields: {},
    items: [],
    input: '',
    pagination: {
      offset: 0,
      limit: 10,
      total: 0,
      totalPages: 0,
    },
    params: {
      filter: {},
      sort: { macAddress: 1 },
    },
  };

  procWizardSteps(): void {
    this.data.steps = [];

    if (this.hasGateway) {
      this.data.steps.push({
        id: 'gateway',
        title: 'Gateway',
        subtitle: 'Bluetooth-enabled gateway',
      } as never);
    }

    forEach(this.requestedDevices, (reqDevice): void => {
      if (reqDevice.device !== 'None') {
        const devName = webUtil.str.startCase(reqDevice.device);
        this.data.steps.push({
          id: reqDevice.device,
          title: devName,
          subtitle: `Adding a ${devName} device`,
        } as never);
      }
    });
  }

  async fetchGateways(filterIn: object) {
    try {
      this.data.loading += 1;
      const {
        data: {
          Support: {
            Get: {
              Gateways: { docs },
            },
          },
        },
      } = await this.$apollo.query({
        query: supportGql.query.gateways(),
        variables: {
          pagination: {
            offset: this.data.pagination.offset,
            limit: this.data.pagination.limit,
          },
          params: {
            filter: {
              status: 'Idle',
              ...filterIn,
            },
            sort: this.data.params.sort,
          },
        },
      });

      this.data.items = docs;
    } catch (err) {
      console.log(err);
    } finally {
      this.data.loading -= 1;
    }
  }

  async fetchDevices(type: string, filterIn: object) {
    try {
      this.data.loading += 1;
      const {
        data: {
          Support: {
            Get: {
              Devices: { docs },
            },
          },
        },
      } = await this.$apollo.query({
        query: supportGql.query.devices(),
        variables: {
          pagination: {
            offset: this.data.pagination.offset,
            limit: this.data.pagination.limit,
          },
          params: {
            filter: {
              status: 'Idle',
              type,
              ...filterIn,
            },
            sort: this.data.params.sort,
          },
        },
      });

      this.data.items = docs;
    } catch (err) {
      console.log(err);
    } finally {
      this.data.loading -= 1;
    }
  }

  async loadStepData(stepNo: number, filterIn: object = {}) {
    try {
      if (this.data.step !== stepNo) {
        console.log(`${this.data.step} => ${stepNo}, aborting ...`);
        return;
      } else if (this.data.steps.length === 0) {
        return;
      }

      this.data.loading += 1;
      const type = get(
        this.data,
        ['steps', this.data.step - 1, 'id'],
        undefined,
      );

      if (type === undefined) {
        return;
      }

      if (type === 'gateway') {
        await this.fetchGateways(filterIn);
      } else {
        await this.fetchDevices(type, filterIn);
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.data.loading -= 1;
    }
  }

  async setGateway() {
    try {
      this.data.loading += 1;
      const gatewayItem = find(this.requestedDevices, {
        device: 'None',
        gateway: true,
      });

      await this.$apollo.mutate({
        mutation: supportGql.mutation.assignGateway(),
        variables: {
          tenantId: get(gatewayItem, 'tenant', ''),
          gatewayId: get(this.data, 'fields.gateway.id', ''),
          patientId: this.patientId,
        },
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.data.loading -= 1;
    }
  }

  async setDevice(deviceType: string) {
    try {
      this.data.loading += 1;
      const device = this.data.steps[this.data.step - 1]['id'];
      const deviceItem = find(this.requestedDevices, {
        device,
      });

      await this.$apollo.mutate({
        mutation: supportGql.mutation.assignDevice(),
        variables: {
          tenantId: get(deviceItem, 'tenant', ''),
          gatewayId: get(this.data, 'fields.gateway.id', ''),
          deviceId: get(this.data, `fields.${deviceType}.id`, ''),
          patientId: this.patientId,
        },
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.data.loading -= 1;
    }
  }

  async saveData(step: string) {
    console.log(`Step number: ${step}`);

    if (step === 'gateway') {
      await this.setGateway();
    } else {
      await this.setDevice(step);
    }
    this.data.step += 1;
  }
}
