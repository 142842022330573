import { render, staticRenderFns } from "./WeightSetting.vue?vue&type=template&id=364200ec&scoped=true&"
import script from "./WeightSetting.vue?vue&type=script&lang=ts&"
export * from "./WeightSetting.vue?vue&type=script&lang=ts&"
import style0 from "./WeightSetting.vue?vue&type=style&index=0&id=364200ec&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "364200ec",
  null
  
)

export default component.exports