
import { Component, Vue, Prop } from 'vue-property-decorator';
import HealthIcon from '@/common/components/HealthIcon.vue';
import ToolTip from '@/common/components/ToolTip.vue';

@Component({
  components: {
    ToolTip,
    HealthIcon,
  },
})
export default class BaseDeviceIcon extends Vue {
  @Prop({ default: false }) hasStatus!: boolean;
  @Prop({ default: '' }) status!: string;
  @Prop({ default: '' }) svgSrc!: string;
  @Prop({ default: '' }) variant!: string;

  get badgeCls(): string {
    if (['success', 'error'].includes(this.status)) {
      return this.status;
    }
    return '';
  }
}
