import moment from 'moment';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import millify from 'millify';
import pluralize from 'pluralize';
import {
  startCase,
  forEach,
  inRange,
  isObject,
  isEmpty,
  camelCase,
  isString,
} from 'lodash';
import { GenericObjectT } from '@/common/types/interfaces';
import { ToastApi } from 'vue-toast-notification';
import Vue from 'vue';

function customDateTimeFormatter(input: string, format: string): string {
  return moment(+input).format(format);
}

function fullTsEpochFormatter(input: string): string {
  return moment(+input).format('MMM D, LT');
}

function epochFromString(input: string): number {
  return moment(input).unix();
}

function fullTsFormatter(input: string): string {
  return moment(input).format('llll');
}

function dateFmt(input: string, fmt: string): string {
  return moment(+input).format(fmt);
}

function shortDateFormatter(input: string): string {
  return moment(+input).format('ll');
}

function shortTimeFormatter(input: string): string {
  return moment(+input).format('LT');
}

function fromAgoFormatter(input: string): string {
  return moment(+input).fromNow();
}

function calculateAge(input: string): number {
  return moment().diff(moment(input), 'years', false);
}

function prettyTsFormatter(input: string): string {
  if (!olderThanToday(input)) {
    return fromAgoFormatter(input);
  }
  return shortDateFormatter(input);
}

function olderThanXDay(epoch: string, targetSeconds: number): boolean {
  const targetTime = moment(+epoch).unix();
  const today = moment().startOf('day').unix();
  return Math.abs(targetTime - today) > targetSeconds;
}

function olderThanOneDay(epoch: string, targetSeconds = 3600 * 24): boolean {
  return olderThanXDay(epoch, targetSeconds);
}

function olderThanToday(epoch: string): boolean {
  const targetTime = moment(+epoch).unix();
  const today = moment().startOf('day').unix();

  return targetTime < today;
}

function isToday(epoch: string | number): boolean {
  const targetTime = moment(epoch).unix();
  const today = moment().startOf('day').unix();

  return targetTime === today;
}

function dateOlderThanToday(dateStr: string): boolean {
  const targetTs = moment.utc(dateStr).startOf('day').unix();
  const today = moment.utc().startOf('day').unix();

  return Math.abs(targetTs - today) > 0;
}

function numberDecFormatter(num: number | string): string {
  if (isNaN(num as number)) {
    return '0';
  }
  return millify(parseInt(num.toString()), { precision: 1, space: true });
}

function numberKmgFormatter(num: number | string): string {
  if (isNaN(num as number)) {
    return '0';
  }
  return millify(parseInt(num.toString()), {
    precision: 1,
    space: true,
    units: ['B', 'KB', 'MB', 'GB', 'TB', 'PB'],
  });
}

function inchFeetFormatter(num: number): string {
  const foot = Math.floor(num / 12);
  const inch = num - foot * 12;
  return `${foot}' ${inch}"`;
}

function bmiCalculator(
  weight: number,
  height: number,
  imperial: boolean,
): { bmi: string; status: string } {
  const range = {
    underweight: [0, 18.5],
    normal: [18.5, 24.9],
    overweight: [25.0, 29.9],
    obese: [30, 99],
  };
  const multiplier = imperial ? 703 : 1;

  const bmi = (weight / (height * height)) * multiplier;
  let status = 'underweight';

  forEach(range, (rg: number[], key: string): void => {
    if (inRange(bmi, rg[0], rg[1])) {
      status = key;
    }
  });

  return {
    bmi: bmi.toFixed(2),
    status,
  };
}

function flattenObject(objIn: GenericObjectT, prefix = ''): GenericObjectT {
  let result: GenericObjectT = {};
  for (const key in objIn) {
    if (isObject(objIn[key])) {
      result = {
        ...result,
        ...flattenObject(objIn[key], key),
      };
    } else {
      const keywd = !isEmpty(prefix) ? `${prefix}.${key}` : key;
      result[keywd] = objIn[key];
    }
  }
  return result;
}

export function strEllipsis(str: string, max = 20): string {
  return str.length > max
    ? str.substring(0, max / 2 - 1) +
        '...' +
        str.substring(str.length - max / 2 + 2, str.length)
    : str;
}

function deviceToThreshold(deviceType: string): string {
  return `${deviceType.replace('_', '')}Threshold`;
}

// eslint-disable-next-line
function notifyInternal($toast: ToastApi, type: string, msg: any): void {
  $toast.open({
    message: msg.toString(),
    type,
  });
}

// eslint-disable-next-line
function localStr($t: any, prefix: string, label: string): string {
  const camelLabel = camelCase(label);
  if (camelLabel !== 'dateTime') {
    return startCase($t(`${prefix}.${camelCase(label)}`));
  }
  return $t(`${prefix}.${camelLabel}`) as string;
}

function wordInitials(strInput: string): string {
  return (
    startCase(strInput)
      .match(/\b(\w)/g)
      ?.join('')
      .toUpperCase()
      .substr(0, 2) || ''
  );
}

function humanizeSeconds(seconds: number | string): string {
  let value = seconds;
  if (isString(seconds)) {
    value = parseInt(seconds);
  }
  return moment.duration(value, 'seconds').humanize();
}

export const webUtil = {
  time: {
    fullTs: fullTsFormatter,
    fullTsEpoch: fullTsEpochFormatter,
    shortDate: shortDateFormatter,
    shortTime: shortTimeFormatter,
    fromAgo: fromAgoFormatter,
    epoch: epochFromString,
    dateFmt: dateFmt,
    custom: customDateTimeFormatter,
    isToday: isToday,
    olderThanToday: olderThanToday,
    olderThanOneDay: olderThanOneDay,
    dateOlderThanToday: dateOlderThanToday,
    prettyTsFormatter: prettyTsFormatter,
    calcAge: calculateAge,
    humanize: {
      seconds: humanizeSeconds,
    },
  },
  number: {
    dec: numberDecFormatter,
    kmg: numberKmgFormatter,
    inft: inchFeetFormatter,
    bmi: bmiCalculator,
  },
  str: {
    startCase,
    camelCase,
    truncate: strEllipsis,
    pluralize: (val: string, count: number): string => pluralize(val, count),
    deviceToThreshold,
    locale: localStr,
    wordInitials,
  },
  object: {
    flatten: flattenObject,
  },
  notify: {
    success: (toast: ToastApi, msg: unknown): void =>
      notifyInternal(toast, 'success', msg),
    error: (toast: ToastApi, msg: unknown): void =>
      notifyInternal(toast, 'error', msg),
    info: (toast: ToastApi, msg: unknown): void =>
      notifyInternal(toast, 'info', msg),
  },
};

export const busEvt = new Vue();
