import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const notificationsGql = {
  listAll(): DocumentNode {
    return gql`
      query ListOfNotifications(
        $pagination: QueryInputPagination
        $params: QueryInputParam
      ) {
        Notifications {
          Get(params: $params, pagination: $pagination) {
            docs {
              ts
              historical
              ruleType
              deviceType
              newEntry
              details
              label
              Patient {
                id
                name
              }
            }
            pageInfo {
              offset
              limit
              page
              total
              totalPages
            }
          }
        }
      }
    `;
  },
};
