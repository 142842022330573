
import { Component, Vue } from 'vue-property-decorator';
import { getInstance } from '@/plugins/auth0';
import { authActions } from '@/store/actions';
import { get } from 'lodash';
import { webUtil } from '@/common/utils/web-utils';

@Component
export default class Login extends Vue {
  async created(): Promise<void> {
    const checkAuth0Load = setInterval(() => {
      const ready = getInstance();
      if (ready) {
        clearInterval(checkAuth0Load);
      }
    }, 1000);

    if (this.$route.query.error) {
      const errorDescription = get(
        this.$route,
        'query.error_description',
        'Session Invalid',
      );

      webUtil.notify.error(this.$toast, errorDescription);
    }
  }

  doLogin() {
    this.$store.dispatch(authActions.setter.loginRequest);
  }
}
