
import { Component, Vue, Prop } from 'vue-property-decorator';
import { get } from 'lodash';

@Component
export default class WeightGoals extends Vue {
  @Prop({ default: () => ({}) }) goal!: object;

  get weight(): number {
    return get(this.goal, 'weight', 0);
  }
}
