
import { patientsGql } from '@/common/graphql/patients';
import { chartOptionUtil } from '@/common/utils/chart-utils';
import { camelCase, first, forEach, isEmpty, keys } from 'lodash';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { GenericObjectT } from '@/common/types/interfaces';
import PageHeader from '@/common/components/PageHeader.vue';
import DeviceGoals from '@/common/components/devices/goals/DeviceGoals.vue';
import PatientDetailProgress from '@/common/components/devices/progress/DeviceProgress.vue';
import { webUtil } from '@/common/utils/web-utils';

@Component({
  components: {
    PageHeader,
    DeviceGoals,
    PatientDetailProgress,
  },
})
export default class DeviceDetailSection extends Vue {
  @Prop({ default: '' }) patientId!: string;
  @Prop({ default: '' }) deviceId!: string;
  @Prop({ default: '' }) deviceType!: string;

  @Watch('deviceId')
  onDeviceIdChg(): void {
    this.data.selPeriod = first(this.data.periods) as never;
    this.fetchData();
  }

  data = {
    debug: false,
    loading: 0,
    insights: {},
    measurements: {},
    instance: new chartOptionUtil(this.deviceType),
    periods: [
      { text: this.getLocaleText('Last 2 weeks'), value: 'Past_2_Weeks' },
      { text: this.getLocaleText('Last month'), value: 'Past_Month' },
      { text: this.getLocaleText('Last 3 months'), value: 'Past_3_Months' },
    ],
    selPeriod: { text: 'Last 2 weeks', value: 'Past_2_Weeks' },
    entryDiffs: [],
  };
  chartOptions = {};

  mounted(): void {
    this.fetchData();
  }

  handlePeriodChg(): void {
    this.fetchData();
  }

  getLocaleText(text: string): string {
    return this.$t(
      `common.words.${this.$webUtil.str.camelCase(text)}`,
    ) as string;
  }

  patientGoal() {
    switch (camelCase(this.deviceType)) {
      case 'bloodPressure':
        return { systolic: 140, diastolic: 85, heartRate: 80 };
      case 'bloodGlucose':
        return { glucose: 120 };
      case 'oximeter':
        return { sp02: 95, heartRate: 80 };
      case 'weight':
        return { weight: 180 };
      case 'thermometer':
        return { temperature: 98.6 };
    }
  }

  getShortLabel(text: string): string {
    switch (text.toLowerCase()) {
      case 'systolic':
        return 'SYS';
      case 'diastolic':
        return 'DIA';
      case 'glucose':
        return 'mg/dL';
      case 'heartrate':
        return 'HR';
      case 'weight':
        return 'lbs';
      case 'sp02':
        return 'sp02';
      case 'temperature':
        return 'F';
    }
    return text;
  }

  getEntriesDiff(entries: GenericObjectT[]): void {
    const lastTwoEntries = entries.slice(-2);
    const result: GenericObjectT[] = [];

    const dataKeys = lastTwoEntries.length > 0 ? keys(lastTwoEntries[0]) : [];
    let prevValue: GenericObjectT = {};

    forEach(lastTwoEntries, (entry: GenericObjectT): void => {
      forEach(dataKeys, (key: string): void => {
        let unit = 'bpm';
        if (['systolic', 'diastolic'].includes(key)) {
          unit = 'mmHg';
        } else if (key === 'glucose') {
          unit = 'mg/dL';
        } else if (key === 'sp02') {
          unit = '%';
        } else if (key === 'weight') {
          unit = 'lbs';
        } else if (key === 'temperature') {
          unit = 'F';
        }
        if (prevValue[key] !== undefined && !['ts', 'fasting'].includes(key)) {
          result.push({
            text: key,
            label: this.getShortLabel(key),
            value: Math.abs(entry[key] - prevValue[key]),
            unit,
            direction: entry[key] > prevValue[key] ? 'up' : 'down',
            cls:
              entry[key] > prevValue[key]
                ? 'rotate-up error--text'
                : 'rotate-down info--text',
          });
        }
      });
      prevValue = entry;
    });

    this.data.entryDiffs = result as never[];
  }

  async fetchData() {
    try {
      if (isEmpty(this.deviceId)) {
        return '';
      }

      this.data.loading += 1;
      const result = await this.$apollo.query({
        query: patientsGql.patients.measurements(),
        variables: {
          patientId: this.patientId,
          deviceId: this.deviceId,
          period: this.data.selPeriod.value || 'Past_2_Weeks',
        },
      });
      const {
        data: {
          Patients: {
            Measurements: { entries },
          },
        },
      } = result;

      this.data.measurements = entries;
      // this.data.insights = insights;
      this.data.instance = new chartOptionUtil(this.deviceType);
      this.chartOptions = this.data.instance.buildChartData(
        this.deviceType,
        entries,
        {},
        // eslint-disable-next-line
      ) as any;
      this.getEntriesDiff(entries);
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }
}
