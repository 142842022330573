
import { Component, Vue, Emit } from 'vue-property-decorator';
import { dictionaryGql } from '@/common/graphql/dictionary';
import { preprocDictionary } from '@/common/utils/utils';
import { gatewaysGql } from '@/common/graphql/gateways';
import FormDialog from '@/common/components/FormDialog.vue';
import ToolTip from '@/common/components/ToolTip.vue';
import { webUtil } from '@/common/utils/web-utils';

@Component({
  components: {
    ToolTip,
    FormDialog,
  },
})
export default class NewGatewayForm extends Vue {
  @Emit('close-dialog')
  closeDialog() {
    return;
  }

  data = {
    loading: 0,
    dialog: false,
    fields: {
      serialNumber: '',
      firmware: '',
      macAddress: '',
    },
    types: [{}],
  };

  get isLoading(): boolean {
    return this.data.loading > 0;
  }

  mounted(): void {
    this.fetchData();
  }

  async fetchData() {
    try {
      this.data.loading += 1;
      const {
        data: {
          Dictionary: {
            equipments: { types },
          },
        },
      } = await this.$apollo.query({
        query: dictionaryGql.equipment.types(),
        fetchPolicy: 'network-only',
      });

      this.data.types = preprocDictionary(types, false);
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }
  async saveGateway() {
    try {
      this.data.loading += 1;
      await this.$apollo.mutate({
        mutation: gatewaysGql.create(),
        variables: {
          data: this.data.fields,
        },
      });

      this.closeDialog();

      webUtil.notify.error(this.$toast, 'Gateway was added successfully');
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }
}
