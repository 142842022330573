
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { get, forEach, find, isEmpty, cloneDeep } from 'lodash';
import { patientsGql } from '@/common/graphql/patients';
import AdvancedRuleConditionGroup from '@/common/components/rules/AdvancedRuleConditionGroup.vue';
import FormDialog from '@/common/components/FormDialog.vue';
import RuleNotificationConfigure from '@/common/components/rules/RuleNotificationConfig.vue';
import { webUtil } from '@/common/utils/web-utils';
import { ruleTypeT, notificationDataT } from '@/common/utils/rules';

@Component({
  components: {
    FormDialog,
    AdvancedRuleConditionGroup,
    RuleNotificationConfigure,
  },
})
export default class AdvancedRuleForm extends Vue {
  @Prop({ default: '' }) patientId!: string;

  debug = false;
  loading = 0;
  groupOp = '';
  groupOpTemp = '';
  groupOpDialog = false;
  notifValue: notificationDataT = { label: 'once_per_minute', value: 60 };
  rules: any = [
    {
      op: '',
      field: null,
      fields: [],
      value: null,
      value_unit: null,
      device_type: null,
    },
  ];
  operatorItems = [
    { label: 'All', value: 'And' },
    { label: 'Any', value: 'Or' },
  ];

  get displayOp(): string {
    if (isEmpty(this.groupOp)) {
      return 'Choose';
    }
    //@ts-ignore
    return get(find(this.operatorItems, { value: this.groupOp }), 'label');
  }

  get finalRule(): object {
    let result: any = {
      op: this.groupOp,
    };

    if (this.rules.length > 1) {
      result['fields'] = this.rules;
    } else {
      result = this.rules[0];
    }
    return result;
  }

  newConditionGroup(): void {
    this.rules.push({ op: '', field: '', fields: [] });
    this.groupOpDialog = true;
  }

  @Emit('close-dialog')
  closeDialog(): boolean {
    return false;
  }

  onGroupRemoved(id: number): void {
    console.log(`Received Group Level: ${id}`);
    const updated: ruleTypeT[] = [];
    forEach(this.rules, (rule: ruleTypeT, idx: number): void => {
      if (idx !== id) {
        updated.push(rule);
      }
    });
    if (updated.length === 1) {
      this.groupOp = '';
    }
    this.rules = cloneDeep(updated);
  }

  closeOperatorDialog() {
    this.groupOp = this.groupOpTemp;
    this.groupOpDialog = false;
  }

  saveNotificationData(value: notificationDataT): void {
    //@ts-ignore
    this.notifValue = cloneDeep(value.value);
  }

  @Emit('close-dialog')
  async saveRule() {
    try {
      this.loading += 1;
      const payload = {
        rule: this.finalRule,
        notification: this.notifValue,
      };
      await this.$apollo.mutate({
        mutation: patientsGql.measurementRule.create(),
        variables: {
          patientId: this.patientId,
          data: payload,
        },
      });
      this.$webUtil.notify.success(
        this.$toast,
        'Rule was created successfully',
      );
      return true;
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
      return false;
    } finally {
      this.loading -= 1;
    }
  }
}
