
import { GenericObjectT } from '@/common/types/interfaces';
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { forEach } from 'lodash';

@Component
export default class WeightSettingForm extends Vue {
  @Prop({ default: () => ({}) }) setting!: GenericObjectT;
  @Prop({ default: false }) flatCard!: boolean;

  kind = 'WeightThreshold';
  data = {
    direction: [
      { text: 'Increased', value: 'inc' },
      { text: 'Decreased', value: 'dec' },
    ],
    status: false,
    current: [],
    historical: [],
    historicalStatus: false,
  };

  mounted(): void {
    this.initialize();
  }

  initialize(): void {
    forEach(this.setting.weight, (entry: GenericObjectT): void => {
      if (entry.type === 'current') {
        this.data.current.push(entry as never);
      } else if (entry.type === 'historical') {
        this.data.historical.push(entry as never);
      }
    });

    const key = 'weight';
    let period = 0;
    let occurence = 0;

    if (period <= 0 || period !== this.setting[key]['period']) {
      period = this.setting[key]['period'];
      this.data.historicalStatus = true;
    }
    if (occurence <= 0 || period !== this.setting[key]['occurence']) {
      occurence = this.setting[key]['occurence'];
      this.data.historicalStatus = true;
    }
  }

  getChkboxLabel(statusValue: boolean): string {
    if (statusValue === true) {
      return this.$webUtil.str.startCase(
        this.$t('common.words.checkIfSettingOccur'),
      );
    }
    return this.$webUtil.str.startCase(
      this.$t('common.words.checkIfSettingNotOccur'),
    );
  }

  getThresholds(typeIn: string): GenericObjectT[] {
    const result: GenericObjectT[] = [];
    forEach(this.setting.weight, (entry: GenericObjectT): void => {
      if (entry.type === typeIn) {
        result.push(entry);
      }
    });
    return result;
  }

  directionStr(dir: string): string {
    if (dir === 'inc') {
      return 'increased';
    }
    return 'decreased';
  }

  @Emit('save-data')
  saveData(): GenericObjectT {
    return {
      kind: this.kind,
      current: this.data.current,
      historical: this.data.historical,
    };
  }

  @Emit('reset-setting')
  removeSetting(): string {
    return this.kind;
  }
}
