import type { NavLink, NavLinkProps } from '@/common/types/types.d';
import { GenericObjectT, PaginationTypeT } from '@/common/types/interfaces';
import {
  get,
  map,
  forEach,
  startCase,
  isEmpty,
  flow,
  camelCase,
  upperFirst,
} from 'lodash';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { authDetailsT } from '@/store/types';

/**
 * Return nav link props to use
 * @param {Object, String} item navigation routeName or route Object provided in navigation data
 */
export const getComputedNavLinkToProp = () => (link: NavLink) => {
  const props: NavLinkProps = {
    target: link.target,
    rel: link.rel,
  };

  // If route is string => it assumes string is route name => Create route object from route name
  // If route is not string => It assumes it's route object => returns passed route object
  if (link.to)
    props.to = typeof link.to === 'string' ? { name: link.to } : link.to;
  else props.href = link.href;

  return props;
};

/**
 * Convert Hex color to rgb
 * @param hex
 */

export const hexToRgb = (hex: string) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

  hex = hex.replace(
    shorthandRegex,
    (m: string, r: string, g: string, b: string) => {
      return r + r + g + g + b + b;
    },
  );

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? `${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
        result[3],
        16,
      )}`
    : null;
};

export function setPagination(src: PaginationTypeT, dst: PaginationTypeT) {
  src.offset = dst.offset;
  src.limit = dst.limit;
  src.total = dst.total;
  src.totalPages = Math.ceil(dst.total / dst.limit);
}

export function preprocDictionary(
  values: string[],
  withAll = true,
): GenericObjectT[] {
  const allValues = withAll ? ['All', ...values] : values;
  return map(allValues, (value: string): GenericObjectT => {
    if (value === 'All') {
      return { id: '', label: startCase(value) };
    }
    return { id: value, label: startCase(value) };
  });
}

export function preprocSettingData(values: GenericObjectT): GenericObjectT {
  const data: GenericObjectT = {};
  let status = false;
  let occurence = 0;
  let period = 0;
  switch (values.kind) {
    case 'WeightThreshold':
      data.weight = [...values.current, ...values.historical];
      break;

    default:
      if (values.data.historical.status === true) {
        status = true;
        occurence = values.data.historical.occurence;
        period = values.data.historical.period;
      }
      forEach(values.data, (obj: GenericObjectT, key: string): void => {
        if (key !== 'historical') {
          if (status === true) {
            data[key] = {
              ...obj,
              occurence,
              period,
            };
          } else {
            data[key] = obj;
          }
        }
      });
      break;
  }
  return data;
}

export function jwtTokenDecode(token: string): GenericObjectT {
  return isEmpty(token) ? {} : jwtDecode<JwtPayload>(token);
}

export function jwtTokenPerms(token: string): string[] {
  return get(jwtTokenDecode(token), 'permissions', []);
}

export function jwtTokenDetails(token: string): authDetailsT {
  return get(jwtTokenDecode(token), 'details', {
    email: '',
    roles: [],
    tenantId: '',
    userId: '',
  });
}

export const pascalCase = flow(camelCase, upperFirst);
