import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const devicesGql = {
  listAll(): DocumentNode {
    return gql`
      query deviceQuery(
        $pagination: QueryInputPagination
        $params: QueryInputParam
      ) {
        Devices {
          Get(pagination: $pagination, params: $params) {
            docs {
              id
              type
              status
              firmware
              macAddress
              serialNumber
              assignmentDate
              Patient {
                id
                name
              }
            }
            pageInfo {
              offset
              limit
              page
              total
            }
          }
        }
      }
    `;
  },
  create(): DocumentNode {
    return gql`
      mutation createDevice($data: NewDeviceInput!) {
        Device {
          New(data: $data) {
            id
            type
          }
        }
      }
    `;
  },
  query: {
    distinctTypes(): DocumentNode {
      return gql`
        query distinctDeviceTypes {
          Devices {
            Distinct
          }
        }
      `;
    },
  },
};
