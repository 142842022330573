
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import { GenericObjectT } from '@/common/types/interfaces';
import { keys, forEach } from 'lodash';
import SimpleHistoricalSetting from '@/components/settings/forms/SimpleHistoricalSetting.vue';
import { webUtil } from '@/common/utils/web-utils';

@Component({
  filters: {
    startCase: webUtil.str.startCase,
  },
  components: {
    SimpleHistoricalSetting,
  },
})
export default class RangeSettingForm extends Vue {
  @Prop({ default: () => ({}) }) setting!: GenericObjectT;
  @PropSync('dataValue', { default: () => ({}) }) data!: GenericObjectT;

  get settingKeys(): string[] {
    return keys(this.setting).filter((key: string): boolean => key !== 'kind');
  }

  mounted(): void {
    this.initValues();
  }

  initValues(): void {
    forEach(this.settingKeys, (key: string): void => {
      this.data[key] = {
        min: +this.setting[key]['min'] as number,
        max: +this.setting[key]['max'] as number,
      };
    });
    const { status, period, occurence } = this.procHistoricalConfig;
    this.data.historical = {
      status,
      period,
      occurence,
    };
  }

  get procHistoricalConfig(): GenericObjectT {
    let period = 0;
    let occurence = 0;
    let status = false;
    forEach(this.settingKeys, (key: string): void => {
      if (period <= 0 || period !== this.setting[key]['period']) {
        period = +this.setting[key]['period'];
        status = true;
      }
      if (occurence <= 0 || period !== this.setting[key]['occurence']) {
        occurence = +this.setting[key]['occurence'];
        status = true;
      }
    });
    return { status, period, occurence };
  }
}
