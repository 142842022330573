import { VueConstructor, PluginObject } from 'vue/types/index';
import VueEchartsComponent from '@/common/components/charts/VueEcharts.vue';

const vueEchartsCompoment = {
  install(Vue: VueConstructor) {
    Vue.component('VueEcharts', VueEchartsComponent);
  },
  // eslint-disable-next-line
} as PluginObject<any>;

export default vueEchartsCompoment;
