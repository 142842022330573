import { AbilityBuilder, Ability } from '@casl/ability';
import store from '@/store';
import { forEach } from 'lodash';
import { jwtTokenPerms } from '@/common/utils/utils';

export const JwtAbility = new Ability();
const { can, rules } = new AbilityBuilder(Ability);

export function updateJwtAbility(accessToken: string): void {
  forEach(jwtTokenPerms(accessToken), (perm: string): void => {
    const [action, subject] = perm.split(':');
    can(action.trim(), subject.trim());
  });

  JwtAbility.update(rules);
}

export function abilityFromJwt(): Ability {
  updateJwtAbility(store.getters.accessToken);
  return JwtAbility;
}
