import Vue from 'vue';
import createAuth0Client, {
  Auth0Client,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
  PopupLoginOptions,
  User,
} from '@auth0/auth0-spa-js';
import _Vue from 'vue';
import store from '@/store';
import { authActions } from '@/store/actions';

export type Auth0TypesT = {
  domain: string;
  clientId: string;
  audience: string;
};

const DEFAULT_REDIRECT_CALLBACK = () => {
  return window.history.replaceState(
    {},
    document.title,
    window.location.pathname,
  );
};

let instance: Vue;
export const getInstance = (): Vue => instance;

export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}): Vue => {
  if (instance) {
    return instance;
  }

  instance = new Vue({
    data(): {
      loading: boolean;
      isAuthenticated: boolean;
      user: User | undefined;
      auth0Client: Auth0Client | undefined;
      popupOpen: boolean;
      error: Error | undefined;
    } {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: undefined,
        popupOpen: false,
        error: undefined,
      };
    },
    methods: {
      async loginWithPopup(o: PopupLoginOptions): Promise<void> {
        this.popupOpen = true;
        try {
          await this.auth0Client?.loginWithPopup(o);
        } catch (err) {
          console.error(err);
        } finally {
          this.popupOpen = false;
        }

        this.user = await this.auth0Client?.getUser();
        this.isAuthenticated = true;
      },

      async handleRedirectCallback(): Promise<void> {
        this.loading = true;
        try {
          await this.auth0Client?.handleRedirectCallback();
          this.user = await this.auth0Client?.getUser();
          this.isAuthenticated = true;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
          this.error = err;
        } finally {
          this.loading = false;
        }
      },

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      loginWithRedirect(opt: any): void {
        this.auth0Client?.loginWithRedirect(opt);
      },

      async getTokenSilently(
        opt: GetTokenSilentlyOptions,
      ): Promise<string | undefined> {
        return this.auth0Client?.getTokenSilently(opt);
      },

      async getTokenWithPopup(
        opt: GetTokenWithPopupOptions,
      ): Promise<string | undefined> {
        return this.auth0Client?.getTokenWithPopup(opt);
      },

      async logout(opt: LogoutOptions): Promise<void> {
        await store.dispatch(authActions.setter.logoutRequest);
        return this.auth0Client?.logout(opt);
      },
    },

    async created(): Promise<void> {
      this.auth0Client = await createAuth0Client({
        domain: options.domain,
        client_id: options.clientId,
        audience: options.audience,
        redirect_uri: redirectUri,
      });

      try {
        if (
          window.location.search.includes('code=') &&
          window.location.search.includes('state=')
        ) {
          const redirectCb = await this.auth0Client?.handleRedirectCallback();

          if (redirectCb) {
            await store.dispatch(authActions.setter.loginSuccess);
            this.error = undefined;
            onRedirectCallback();
          } else {
            console.log('Handle Redirect callback error');
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        this.error = err;
        console.error('Auth error', err);
      } finally {
        this.isAuthenticated =
          (await this.auth0Client?.isAuthenticated()) as boolean;
        this.user = await this.auth0Client?.getUser();
        this.loading = false;
      }
    },
  });
  return instance;
};

export const Auth0Plugin = {
  install(Vue: typeof _Vue, options: Auth0TypesT) {
    Vue.prototype.$auth = useAuth0(options);
  },
};
