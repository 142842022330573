
import { Component, Vue, Prop } from 'vue-property-decorator';
import { get, forEach } from 'lodash';
import { GenericObjectT } from '@/common/types/interfaces';
import { webUtil } from '@/common/utils/web-utils';

@Component
export default class DeviceValueDisplay extends Vue {
  @Prop() deviceType!: string;
  @Prop() deviceValue!: GenericObjectT;
  @Prop({ default: '' }) label!: string;

  data = {
    keys: {
      Blood_Pressure: ['systolic', 'diastolic', 'heartRate'],
      Blood_Glucose: ['glucose', 'fasting'],
      Oximeter: ['sp02', 'heartRate'],
      Weight: ['weight'],
      Thermometer: ['temperature'],
    },
  };
  unit: Record<string, string> = {
    systolic: 'mmHg',
    diastolic: 'mmHg',
    heartRate: 'bpm',
    glucose: 'mg/dL',
    sp02: '%',
    weight: 'lbs',
    temperature: 'F',
  };

  get deviceKeys(): string[] {
    return get(this.data, ['keys', this.deviceType], []);
  }

  fmtLabel(label: string): string {
    return ['sp02'].includes(label)
      ? label
      : webUtil.str.startCase(this.$t(`common.deviceWords.${label}`) as string);
  }

  getCls(idx: number): string {
    const mappings: Record<string, string> = {
      '1': 'high_value',
      '0': '',
      '-1': 'low_value',
    };
    const arr = this.label.split('_');

    return mappings[arr[idx]];
  }

  get displayLabel(): string[] {
    let label: string[] = [];
    forEach(this.deviceKeys, (key: string): void => {
      label.push(
        `${this.fmtLabel(key)}: ${this.deviceValue[key]} ${this.unit[key]}`,
      );
    });
    return label;
  }
}
