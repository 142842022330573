
import {
  Component,
  Vue,
  Emit,
  Watch,
  Prop,
  PropSync,
} from 'vue-property-decorator';
import { RuleEntry, parseRule } from '@/common/utils/rules';
import { get, has, find, isEmpty, forEach, cloneDeep } from 'lodash';
import AdvancedRuleCondition from '@/common/components/rules/AdvancedRuleCondition.vue';
import FormDialog from '@/common/components/FormDialog.vue';
import PlainRuleComponent from '@/common/components/rules/PlainRule.vue';

@Component({
  components: { AdvancedRuleCondition, FormDialog, PlainRuleComponent },
})
export default class AdvancedRuleConditionGroup extends Vue {
  @Prop({ default: -1 }) level!: number;
  @PropSync('conditionGroup', { default: { op: '', field: '', fields: [] } })
  groupEntry!: RuleEntry;

  debug = false;
  dialog = false;
  operatorItems = [
    { label: 'All', value: 'And' },
    { label: 'Any', value: 'Or' },
  ];
  showFormInput = false;
  conditionOpDialog = false;

  currentEntry: RuleEntry = new RuleEntry();
  groupCondition = '';
  deviceType = '';

  @Watch('groupCondition')
  onGroupConditionChg(newValue: string) {
    this.groupEntry.op = newValue;
  }

  @Emit('remove-condition-group')
  removeConditionGroup() {
    return this.level;
  }

  showForm() {
    this.showFormInput = true;
  }
  hideForm() {
    this.showFormInput = false;
  }
  randId() {
    return Math.random() * 1_000_000;
  }
  dismissDialog(data: RuleEntry) {
    this.showFormInput = false;
  }

  displayRule(rule: any) {
    return parseRule(rule);
  }

  get displayOp(): string {
    if (isEmpty(this.groupCondition)) {
      return 'Choose';
    }
    return get(
      find(this.operatorItems, { value: this.groupCondition }),
      'label',
    ) as unknown as string;
  }

  popGroupSingleEntry() {
    const entry = {
      op: cloneDeep(this.groupEntry.op),
      field: cloneDeep(this.groupEntry.field),
      value: cloneDeep(this.groupEntry.value),
      value_unit: cloneDeep(this.groupEntry.value_unit),
      device_type: cloneDeep(this.groupEntry.device_type),
    };

    // @ts-ignore: intentional change
    this.groupEntry.op = cloneDeep(this.groupCondition);
    // @ts-ignore: intentional change
    this.groupEntry.field = null;
    // @ts-ignore: intentional change
    this.groupEntry.value = null;
    // @ts-ignore: intentional change
    this.groupEntry.value_unit = null;
    // @ts-ignore: intentional change
    this.groupEntry.device_type = null;

    return entry;
  }

  addCurrentRule() {
    if (!has(this.groupEntry, 'fields') || isEmpty(this.groupEntry.fields)) {
      if (isEmpty(this.groupEntry.field)) {
        // @ts-ignore: intentional change
        this.groupEntry.op = this.currentEntry.op;
        // @ts-ignore: intentional change
        this.groupEntry.field = this.currentEntry.field;
        // @ts-ignore: intentional change
        this.groupEntry.value = this.currentEntry.value;
        // @ts-ignore: intentional change
        this.groupEntry.value_unit = this.currentEntry.value_unit;
        // @ts-ignore: intentional change
        this.groupEntry.device_type = this.currentEntry.device_type;

        // delete this.groupEntry.fields;
        this.groupEntry.fields = [];
      } else {
        // @ts-ignore: intentional change
        this.groupEntry.fields = [];
        // @ts-ignore: intentional change
        this.groupEntry.fields.push(this.popGroupSingleEntry());
        // @ts-ignore: intentional change
        this.groupEntry.fields.push(cloneDeep(this.currentEntry));
        // @ts-ignore: intentional change
        this.groupEntry.field = null;
        // delete this.groupEntry.field;
      }
    } else {
      // @ts-ignore: intentional change
      this.groupEntry.fields.push(cloneDeep(this.currentEntry));
    }
  }

  // @ts-ignore: intentional change
  deleteRule(rule) {
    if (isEmpty(this.groupEntry.fields)) {
      this.groupEntry.field = '';
      this.deviceType = '';
    } else {
      // @ts-ignore: intentional change
      const updatedFields = [];
      forEach(this.groupEntry.fields, (field): void => {
        if (field !== rule) {
          updatedFields.push(field);
        }
      });

      if (updatedFields.length === 1) {
        // @ts-ignore: intentional change
        this.groupEntry.op = updatedFields[0].op;
        // @ts-ignore: intentional change
        this.groupEntry.field = updatedFields[0].field;
        // @ts-ignore: intentional change
        this.groupEntry.value = updatedFields[0].value;
        // @ts-ignore: intentional change
        this.groupEntry.value_unit = updatedFields[0].value_unit;
        // @ts-ignore: intentional change
        this.groupEntry.device_type = updatedFields[0].device_type;
        // @ts-ignore: intentional change
        this.groupEntry.fields = [];
      } else {
        // @ts-ignore: intentional change
        this.groupEntry.fields = updatedFields;
      }
    }
  }

  newRuleHandler() {
    console.log('Current entry', this.currentEntry);
    this.deviceType = this.currentEntry.device_type;

    this.addCurrentRule();
    this.showFormInput = false;
    if (this.groupEntry.fields.length > 1) {
      this.conditionOpDialog = true;
    }
  }

  closeConfirmDialog() {
    this.dialog = false;
  }

  closeConfirmGroupDialog() {
    this.conditionOpDialog = false;
  }
}
