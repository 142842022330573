import { userActions } from '../actions';
import { Auth0UserT } from '../types';

type UserStateT = {
  profile: Auth0UserT;
  locale: string;
};

const state: UserStateT = {
  profile: {
    sub: '',
    givenName: '',
    familyName: '',
    nickname: '',
    name: '',
    picture: '',
    locale: '',
    updatedAt: '',
    email: '',
    emailVerified: false,
  },
  locale: '',
};

const getters = {
  profile: (state: UserStateT): Auth0UserT => state.profile,
  locale: (state: UserStateT): string => state.locale,
};
const actions = {};
const mutations = {
  [userActions.setter.profile]: (
    state: UserStateT,
    profile: Auth0UserT,
  ): void => {
    state.profile = profile;
  },
  [userActions.setter.locale]: (state: UserStateT, locale: string): void => {
    state.locale = locale;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
