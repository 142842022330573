
import { Component, Vue, Prop } from 'vue-property-decorator';
import { isPlainObject, isArray, isString } from 'lodash';
import { opSymbol, valueUnitString } from '@/common/utils/rules';
import PlainRuleComponent from '@/common/components/rules/PlainRule.vue';

@Component({
  components: { PlainRuleComponent },
  name: 'advanced-rule-describer',
})
export default class AdvancedRuleDescriber extends Vue {
  @Prop({ default: () => ({}) }) ruleData!: any;
  @Prop({ default: 0 }) level!: number;

  debug = false;
  isDataObject = isPlainObject;
  isDataString = isString;
  isDataArray = isArray;
  opSymbol = opSymbol;
  valueUnitString = valueUnitString;

  randId(): number {
    return Math.random() * 1_000_000;
  }

  isConditionOperator(operator: string): boolean {
    return !['and', 'or'].includes(operator.toLowerCase());
  }

  operatorString(operator: string): string {
    let opString = operator;
    switch (operator.toLowerCase()) {
      case 'and':
        opString = 'All';
        break;
      case 'or':
        opString = 'Any';
        break;
    }
    return opString;
  }
}
