
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class EhrSetting extends Vue {
  data = {
    loading: 0,
    config: {
      ehrServerUri: 'hapi.fhir.org/baseR4',
      hasAuth: false,
      clientId: '499bb6b7-baa1-4b64-92b4-f98c16f95708',
      privateKey: '',
    },
  };
}
