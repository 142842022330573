
import { Component, Vue, Watch } from 'vue-property-decorator';
import { patientsGql } from '@/common/graphql/patients';
import { setPagination } from '@/common/utils/utils';
import { webUtil } from '@/common/utils/web-utils';
import moment from 'moment';
import PrettyTs from '@/common/components/PrettyTs.vue';
import ToolTip from '@/common/components/ToolTip.vue';
import PageHeader from '@/common/components/PageHeader.vue';
import PatientEngagementForm from '@/components/patients/forms/PatientEngagementForm.vue';

@Component({
  filters: {
    startCase: webUtil.str.startCase,
  },
  components: {
    ToolTip,
    PrettyTs,
    PageHeader,
    PatientEngagementForm,
  },
})
export default class PatientEngagements extends Vue {
  @Watch('data.pageNum')
  onPageNumChg(): void {
    this.data.pagination.offset =
      (this.data.pageNum - 1) * this.data.pagination.limit;

    this.fetchData();
  }

  maxLen = 70;
  data = {
    filterDrawer: false,
    dialog: false,
    loading: 0,
    headers: [
      { text: this.getLocaleHdr('Date/time'), value: 'startTs' },
      { text: this.getLocaleHdr('Duration'), value: 'endTs', align: 'right' },
      { text: this.getLocaleHdr('Patient'), value: 'Patient.name' },
      // { text: 'Type', value: 'type' },
      {
        text: this.getLocaleHdr('Log'),
        value: 'content',
        class: 'engagement-log',
      },
    ],
    rows: [],
    pagination: {
      offset: 0,
      limit: 15,
      total: 0,
      totalPages: 0,
    },
    filter: {
      sort: { startTs: -1 },
    },
    pageNum: 1,
    params: {},
  };

  mounted(): void {
    this.fetchData();
  }

  getLocaleHdr(label: string): string {
    const camelLabel = this.$webUtil.str.camelCase(label);
    if (camelLabel !== 'dateTime') {
      return this.$webUtil.str.startCase(
        this.$t(`common.headers.${this.$webUtil.str.camelCase(label)}`),
      );
    }
    return this.$t(`common.headers.${camelLabel}`) as string;
  }

  showMinAndSeconds(startTs: number, endTs: number): string {
    const mStartTs = moment(startTs * 1000);
    const mEndTs = moment(endTs * 1000);
    const minutes = mEndTs.diff(mStartTs, 'minutes');

    let result = `${minutes}`;
    // if (minutes > 0) {
    //   result = `${minutes} ${webUtil.str.pluralize('minute', minutes)}`;
    // }
    return result;
  }

  async fetchData() {
    try {
      this.data.loading += 1;
      const result = await this.$apollo.query({
        query: patientsGql.patients.engagements(),
        variables: {
          pagination: {
            offset: this.data.pagination.offset,
            limit: this.data.pagination.limit,
          },
          params: this.data.filter,
        },
        fetchPolicy: 'no-cache',
      });

      const {
        data: {
          Patients: {
            Engagements: { docs, pageInfo },
          },
        },
      } = result;

      this.data.rows = docs;
      setPagination(this.data.pagination, pageInfo);
    } catch (err) {
      console.log(err);
      // webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }

  closeDialog(): void {
    this.data.dialog = false;
    this.fetchData();
  }
}
