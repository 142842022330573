
import { Component, Vue } from 'vue-property-decorator';
import PageHeader from '@/common/components/PageHeader.vue';
import MeasurementsStats from '@/components/dashboards/MeasurementStats.vue';
import DonutChart from '@/common/components/charts/DonutChart.vue';
import { dashboardsGql } from '@/common/graphql/dashboards';
import { GenericObjectT } from '@/common/types/interfaces';
import { get } from 'lodash';
import { webUtil } from '@/common/utils/web-utils';

@Component({
  components: {
    PageHeader,
    DonutChart,
    MeasurementsStats,
  },
})
export default class Dashboard extends Vue {
  data = {
    loading: 0,
    distributions: {},
    chartHeight: 300,
  };

  getTitle(path: string): string {
    return this.$webUtil.str.startCase(this.$t(path));
  }

  get ageDistribution(): GenericObjectT {
    return get(this.data.distributions, 'age_distribution', {});
  }

  get deviceTypeDistribution(): GenericObjectT {
    return get(this.data.distributions, 'type_distribution', {});
  }

  mounted(): void {
    this.fetchData();
  }

  async fetchData(): Promise<void> {
    try {
      this.data.loading += 1;
      const {
        data: {
          Dashboards: { DistributionStats },
        },
      } = await this.$apollo.query({
        query: dashboardsGql.query.distributionStats(),
      });

      this.data.distributions = DistributionStats;
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }
}
