
import { Component, Vue, Prop, Emit, PropSync } from 'vue-property-decorator';
import { GenericObjectT } from '@/common/types/interfaces';
import { get, isEmpty } from 'lodash';
import { webUtil } from '@/common/utils/web-utils';
import FilterDrawerComponent from '@/common/components/FilterDrawer.vue';
import DeviceFilterBody from '@/components/devices/filter/DeviceFilterBody.vue';

@Component({
  filters: {
    startCase: webUtil.str.startCase,
  },
  components: {
    DeviceFilterBody,
    FilterDrawerComponent,
  },
})
export default class DeviceFilter extends Vue {
  @PropSync('filters', { default: {} }) currentFilters!: Record<string, string>;
  @PropSync('drawer', { type: Boolean }) showDrawer!: boolean;
  @Prop({ default: () => ({}) }) headers!: GenericObjectT[];

  @Emit('collapse-filter-drawer')
  collapseFilterDrawer(): void {
    return;
  }

  @Emit('set-filter-from-drawer')
  setFilterFromDrawer(): boolean {
    return true;
  }

  @Emit('clear-filter-values')
  clearAllFilters(): void {
    return;
  }

  data = {
    headers: [
      { text: 'By Status', value: 'status' },
      { text: 'By Patient', value: 'patient' },
      { text: 'By Types', value: 'type' },
    ],
  };

  displayFilter(item: GenericObjectT): string {
    const filterObj = get(this.currentFilters, item.value, {});
    const name = get(filterObj, 'name', '');
    return !isEmpty(name) ? name : get(filterObj, 'label', '');
  }

  hasFilter(value: string) {
    return !isEmpty(get(this.currentFilters, value, ''));
  }
}
