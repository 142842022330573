import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const tenantsGql = {
  query: {
    settings(): DocumentNode {
      return gql`
        query tenantSettingsQuery {
          Tenant {
            Settings
          }
        }
      `;
    },
    notifSettings(): DocumentNode {
      return gql`
        query tenantSettingsQuery {
          Tenant {
            NotificationSettings {
              type
              matcher
              deviceType
              deliveryMethod
              deliverToCareTeam {
                id
                firstName
                lastName
                type
              }
            }
          }
          Devices {
            Distinct
          }
        }
      `;
    },
  },
  mutation: {
    measurementRule(): DocumentNode {
      return gql`
        mutation tenantMeasurementRule($data: JSON!) {
          Tenant {
            MeasurementRules(data: $data)
          }
        }
      `;
    },
    measurementRuleNotification(): DocumentNode {
      return gql`
        mutation tenantMeasurementRule($ruleId: ID!, $data: LabelNumberInput) {
          Tenant {
            RuleNotification(ruleId: $ruleId) {
              Update(data: $data)
            }
          }
        }
      `;
    },
    settings(): DocumentNode {
      return gql`
        mutation tenantSetting($data: JSON!) {
          Tenant {
            Settings(data: $data)
          }
        }
      `;
    },
    notificationSettings: {
      create(): DocumentNode {
        return gql`
          mutation createNotifSetting($data: NotificationSettingInput!) {
            Tenant {
              NotificationSetting {
                Create(data: $data) {
                  type
                  matcher
                  deviceType
                  deliveryMethod
                  deliverToCareTeam
                }
              }
            }
          }
        `;
      },
      update(): DocumentNode {
        return gql`
          mutation updateNotifSetting(
            $id: ID!
            $data: NotificationSettingInput!
          ) {
            Tenant {
              NotificationSetting {
                Update(id: $id, data: $data) {
                  type
                  matcher
                  deviceType
                  deliveryMethod
                  deliverToCareTeam
                }
              }
            }
          }
        `;
      },
      delete(): DocumentNode {
        return gql`
          mutation deleteNotifSetting($id: ID!) {
            Tenant {
              NotificationSetting {
                Delete(id: $id) {
                  type
                  matcher
                  deviceType
                  deliveryMethod
                  deliverToCareTeam
                }
              }
            }
          }
        `;
      },
    },
  },
};
