import { forEach, camelCase } from 'lodash';
import { webUtil } from '@/common/utils/web-utils';
import { GenericObjectT } from '@/common/types/interfaces';

export class chartOptionUtil {
  data = {
    deviceType: '',
  };

  constructor(deviceType: string) {
    this.data.deviceType = deviceType;
  }

  // eslint-disable-next-line
  buildChartData(deviceType: string, measurements: any, insights: any) {
    switch (camelCase(deviceType)) {
      case 'weightScale':
        return this.buildWeightAxisData(measurements, insights);
      case 'oximeter':
        return this.buildOximeterAxisData(measurements, insights);
      case 'bloodPressure':
        return this.buildBloodPressureAxisData(measurements, insights);
      case 'bloodGlucose':
        return this.buildBloodGlucoseAxisData(measurements, insights);
      case 'thermometer':
        return this.buildThermometerAxisData(measurements, insights);
    }
  }

  buildBaseChartOpt(unit: string) {
    return {
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        type: 'weight',
        data: [],
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: `{value} ${unit}`,
        },
      },
      series: [],
    };
  }

  // eslint-disable-next-line
  buildWeightAxisData(measurements: any, insights: any) {
    const labels: string[] = [];
    const series = {
      weight: {
        data: [],
        name: 'Weight',
        type: 'line',
        smooth: true,
        markPoint: {
          data: [
            {
              type: 'max',
              name: 'Max',
            },
            { type: 'min', name: 'Min' },
          ],
        },
        markLine: {
          data: [
            {
              type: 'average',
              name: 'Avg',
              label: {
                formatter: ({ value }: { value: string }) =>
                  `Avg Weight: ${Math.round(+value)} lbs`,
                position: 'insideStartTop',
              },
            },
          ],
        },
      },
    };

    forEach(measurements, (entry): void => {
      const milliseconds = +new Date(entry.timestamp);
      const xLabel = webUtil.time.olderThanToday(
        Math.floor(milliseconds / 1000).toString(),
      )
        ? webUtil.time.shortDate(milliseconds.toString())
        : webUtil.time.fromAgo(milliseconds.toString());

      labels.push(xLabel);
      series.weight.data.push(entry.weight as never);
    });

    return {
      ...this.buildBaseChartOpt('lbs'),
      ...{
        xAxis: {
          data: labels,
        },
      },
      visualMap: [
        {
          show: false,
          type: 'continuous',
          seriesIndex: 0,
          min: 70,
          max: 600,
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: 'weight',
          axisLabel: {
            formatter: `{value} lbs`,
          },
          min: 80,
          max: 300,
        },
      ],
      series: [series.weight],
    };
  }

  // eslint-disable-next-line
  buildOximeterAxisData(measurements: any, insights: any) {
    const labels: string[] = [];
    const series = {
      sp02: {
        data: [],
        name: 'sp02',
        type: 'line',
        smooth: true,
        yAxisIndex: 0,
        markPoint: {
          data: [
            {
              type: 'max',
              name: 'Max',
            },
            { type: 'min', name: 'Min' },
          ],
        },
        markLine: {
          data: [
            {
              type: 'average',
              name: 'Avg',
              label: {
                formatter: (val: GenericObjectT) => `Avg sp02: ${val.value}%`,
                position: 'insideStartTop',
              },
            },
          ],
        },
      },
      heartRate: {
        data: [],
        name: 'Heart Rate',
        type: 'line',
        smooth: true,
        yAxisIndex: 1,
      },
    };

    forEach(measurements, (entry): void => {
      const milliseconds = +new Date(entry.timestamp);
      const xLabel = webUtil.time.olderThanToday(
        Math.floor(milliseconds / 1000).toString(),
      )
        ? webUtil.time.shortDate(milliseconds.toString())
        : webUtil.time.fromAgo(milliseconds.toString());

      labels.push(xLabel);
      series.sp02.data.push(entry.sp02 as never);
      series.heartRate.data.push(entry.heart_rate as never);
    });

    return {
      ...this.buildBaseChartOpt('%'),
      ...{
        xAxis: {
          data: labels,
        },
      },
      yAxis: [
        {
          type: 'value',
          name: 'sp02',
          axisLabel: {
            formatter: `{value} %`,
          },
          min: 50,
          max: 110,
        },
        {
          type: 'value',
          name: 'Heart Rate',
          axisLabel: {
            formatter: `{value} bpm`,
          },
          min: 40,
          max: 200,
        },
      ],
      series: [series.sp02, series.heartRate],
    };
  }

  // eslint-disable-next-line
  buildBloodPressureAxisData(measurements: any, insights: any) {
    const labels: string[] = [];
    const series = {
      systolic: {
        data: [],
        name: 'Systolic',
        type: 'line',
        smooth: true,
        yAxisIndex: 0,
        // markArea: {
        //   data: [
        //     [
        //       {
        //         name: 'Elevated',
        //         yAxis: 120,
        //         itemStyle: { color: 'rgba(255,165,0,0.1)' },
        //         label: { position: 'inside' },
        //       },
        //       {
        //         yAxis: 129,
        //       },
        //     ],
        //     [
        //       {
        //         name: 'Normal',
        //         itemStyle: { color: 'rgba(84,112,198,0.1)' },
        //         yAxis: 60,
        //         label: { position: 'inside' },
        //       },
        //       {
        //         yAxis: 119,
        //       },
        //     ],
        //   ],
        // },
        markPoint: {
          data: [
            {
              type: 'max',
              name: 'Max',
            },
            { type: 'min', name: 'Min' },
          ],
        },
        markLine: {
          data: [
            {
              type: 'average',
              name: 'Avg',
              label: {
                formatter: ({ value }: { value: string }) =>
                  `Avg SYS: ${Math.round(+value)}`,
                position: 'insideStartTop',
              },
            },
          ],
        },
      },
      diastolic: {
        data: [],
        name: 'Diastolic',
        type: 'line',
        smooth: true,
        yAxisIndex: 0,
        markPoint: {
          data: [
            {
              type: 'max',
              name: 'Max',
            },
            { type: 'min', name: 'Min' },
          ],
        },
        markLine: {
          data: [
            {
              type: 'average',
              name: 'Avg',
              label: {
                formatter: ({ value }: { value: string }) =>
                  `Avg DIA: ${Math.round(+value)}`,
                position: 'insideStartBottom',
              },
            },
          ],
        },
      },
      heartRate: {
        data: [],
        name: 'Heart Rate',
        type: 'bar',
        smooth: true,
        yAxisIndex: 1,
      },
    };

    forEach(measurements, (entry): void => {
      const milliseconds = +new Date(entry.timestamp);
      const xLabel = webUtil.time.olderThanToday(
        Math.floor(milliseconds / 1000).toString(),
      )
        ? webUtil.time.shortDate(milliseconds.toString())
        : webUtil.time.fromAgo(milliseconds.toString());

      labels.push(xLabel);
      series.systolic.data.push(entry.systolic as never);
      series.diastolic.data.push(entry.diastolic as never);
      series.heartRate.data.push(entry.heart_rate as never);
    });

    return {
      ...this.buildBaseChartOpt('mmHg'),
      ...{
        xAxis: {
          data: labels,
        },
      },
      yAxis: [
        {
          type: 'value',
          name: 'Pressure',
          axisLabel: {
            formatter: `{value} mmHg`,
          },
          min: 50,
          max: 200,
        },
        {
          type: 'value',
          name: 'Heart Rate',
          axisLabel: {
            formatter: `{value} bpm`,
          },
          min: 40,
          max: 200,
        },
      ],
      visualMap: [
        {
          show: false,
          type: 'continuous',
          seriesIndex: 2,
          min: 40,
          max: 200,
        },
      ],
      series: [series.systolic, series.diastolic, series.heartRate],
    };
  }

  // eslint-disable-next-line
  buildBloodGlucoseAxisData(measurements: any, insights: any) {
    const labels: string[] = [];
    const series = {
      glucose: {
        data: [],
        name: 'Glucose',
        type: 'line',
        smooth: true,
        yAxisIndex: 0,
        // markArea: {
        //   data: [
        //     [
        //       {
        //         name: 'Elevated',
        //         yAxis: 120,
        //         itemStyle: { color: 'rgba(255,165,0,0.1)' },
        //         label: { position: 'inside' },
        //       },
        //       {
        //         yAxis: 129,
        //       },
        //     ],
        //     [
        //       {
        //         name: 'Normal',
        //         itemStyle: { color: 'rgba(84,112,198,0.1)' },
        //         yAxis: 60,
        //         label: { position: 'inside' },
        //       },
        //       {
        //         yAxis: 119,
        //       },
        //     ],
        //   ],
        // },
        markPoint: {
          data: [
            {
              type: 'max',
              name: 'Max',
            },
            { type: 'min', name: 'Min' },
          ],
        },
        markLine: {
          data: [
            {
              type: 'average',
              name: 'Avg',
              label: {
                formatter: ({ value }: { value: string }) =>
                  `Avg Glucose: ${Math.round(+value)}`,
                position: 'insideStartTop',
              },
            },
          ],
        },
      },
    };

    forEach(measurements, (entry): void => {
      const milliseconds = +new Date(entry.timestamp);
      const xLabel = webUtil.time.olderThanToday(
        Math.floor(milliseconds / 1000).toString(),
      )
        ? webUtil.time.shortDate(milliseconds.toString())
        : webUtil.time.fromAgo(milliseconds.toString());

      labels.push(xLabel);
      series.glucose.data.push(entry.glucose as never);
    });

    return {
      ...this.buildBaseChartOpt('mg/dL'),
      ...{
        xAxis: {
          data: labels,
        },
      },
      yAxis: [
        {
          type: 'value',
          name: 'Glucose',
          axisLabel: {
            formatter: `{value} mg/dL`,
          },
          min: 100,
          max: 225,
        },
      ],
      visualMap: [
        {
          show: false,
          type: 'continuous',
          seriesIndex: 0,
          min: 100,
          max: 225,
        },
      ],
      series: [series.glucose],
    };
  }

  // eslint-disable-next-line
  buildThermometerAxisData(measurements: any, insights: any) {
    const labels: string[] = [];
    const series = {
      temperature: {
        data: [],
        name: 'Temperature',
        type: 'line',
        smooth: true,
        yAxisIndex: 0,
        // markArea: {
        //   data: [
        //     [
        //       {
        //         name: 'Elevated',
        //         yAxis: 120,
        //         itemStyle: { color: 'rgba(255,165,0,0.1)' },
        //         label: { position: 'inside' },
        //       },
        //       {
        //         yAxis: 129,
        //       },
        //     ],
        //     [
        //       {
        //         name: 'Normal',
        //         itemStyle: { color: 'rgba(84,112,198,0.1)' },
        //         yAxis: 60,
        //         label: { position: 'inside' },
        //       },
        //       {
        //         yAxis: 119,
        //       },
        //     ],
        //   ],
        // },
        markPoint: {
          data: [
            {
              type: 'max',
              name: 'Max',
            },
            { type: 'min', name: 'Min' },
          ],
        },
        markLine: {
          data: [
            {
              type: 'average',
              name: 'Avg',
              label: {
                formatter: ({ value }: { value: string }) =>
                  `Avg Temperature: ${Math.round(+value)}`,
                position: 'insideStartTop',
              },
            },
          ],
        },
      },
    };

    forEach(measurements, (entry): void => {
      const milliseconds = +new Date(entry.timestamp);
      const xLabel = webUtil.time.olderThanToday(
        Math.floor(milliseconds / 1000).toString(),
      )
        ? webUtil.time.shortDate(milliseconds.toString())
        : webUtil.time.fromAgo(milliseconds.toString());

      labels.push(xLabel);
      series.temperature.data.push(entry.temperature as never);
    });

    return {
      ...this.buildBaseChartOpt('F'),
      ...{
        xAxis: {
          data: labels,
        },
      },
      yAxis: [
        {
          type: 'value',
          name: 'Temperature',
          axisLabel: {
            formatter: `{value} F`,
          },
          min: 90,
          max: 110,
        },
      ],
      visualMap: [
        {
          show: false,
          type: 'continuous',
          seriesIndex: 0,
          min: 90,
          max: 105,
        },
      ],
      series: [series.temperature],
    };
  }
}
