
import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseMeasurementDisplay from '@/common/components/devices/measurements/MeasurementDisplay.vue';

@Component({
  components: {
    BaseMeasurementDisplay,
  },
})
export default class WeightScaleMeasurementDisplay extends Vue {
  @Prop({ default: () => ({}) }) details!: object;

  get sequence(): string[] {
    return ['weight'];
  }
}
