
import { get, first, map, find, isEmpty } from 'lodash';
import { webUtil } from '@/common/utils/web-utils';
import { GenericObjectT } from '@/common/types/interfaces';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { deviceDfltSettings } from '@/components/settings/forms/device.defaults';
import BloodPressureSettingForm from '@/components/settings/forms/BloodPressureSetting.vue';
import BloodGlucoseSettingForm from '@/components/settings/forms/BloodGlucoseSetting.vue';
import OximeterSettingForm from '@/components/settings/forms/OximeterSetting.vue';
import ThermometerSettingForm from '@/components/settings/forms/ThermometerSetting.vue';
import WeightSettingForm from '@/components/settings/forms/WeightSetting.vue';
import { preprocSettingData } from '@/common/utils/utils';
import { patientsGql } from '@/common/graphql/patients';
import { parseRule, ruleTypeT } from '@/common/utils/rules';

@Component({
  components: {
    BloodPressureSettingForm,
    BloodGlucoseSettingForm,
    OximeterSettingForm,
    ThermometerSettingForm,
    WeightSettingForm,
  },
})
export default class SettingSection extends Vue {
  @Prop({ default: '' }) patientId!: string;

  settings: GenericObjectT[] = [];
  devices: GenericObjectT[] = [];
  data = {
    loading: 0,
    selDevice: {},
    items: [],
    inherit: false,
  };

  async mounted(): Promise<void> {
    await this.fetchData();
    // this.data.selDevice = first(this.deviceTypes) as Record<string, string>;
  }

  get inheritSetting(): boolean {
    const kind = get(this.data, 'selDevice.value', '');
    if (this.settings.length === 0) {
      return true;
    } else {
      const found = find(this.settings, { kind });
      return found === undefined;
    }
  }

  getDefaults(kind: string): GenericObjectT {
    switch (kind) {
      case 'BloodPressureThreshold':
        return deviceDfltSettings.bloodPressure;

      case 'BloodGlucoseThreshold':
        return deviceDfltSettings.bloodGlucose;

      case 'WeightThreshold':
        return deviceDfltSettings.weight;

      case 'OximeterThreshold':
        return deviceDfltSettings.oximeter;

      case 'ThermometerThreshold':
        return deviceDfltSettings.thermometer;
    }
    return {};
  }

  getItems(): GenericObjectT {
    const kind = get(this.data, 'selDevice.value', '');
    if (this.settings.length > 0) {
      const items = find(this.settings as GenericObjectT, {
        kind,
      });

      if (items !== undefined) {
        return items;
      }
    }

    const tenantSetting = find(this.data.items as GenericObjectT, { kind });
    if (tenantSetting === undefined) {
      return this.getDefaults(kind);
    }
    return tenantSetting;
  }

  get deviceTypes(): GenericObjectT[] {
    return map(this.devices, (device: GenericObjectT): GenericObjectT => {
      return {
        value: webUtil.str.deviceToThreshold(device.type),
        text: webUtil.str.startCase(device.type),
      };
    });
  }

  isSelected(typeIn: string): boolean {
    return get(this.data, 'selDevice.value') === typeIn;
  }

  getParsedRule(rule: ruleTypeT) {
    return parseRule(rule);
  }

  async saveData(values: GenericObjectT) {
    const payload = {
      kind: values.kind,
      ...preprocSettingData(values),
    };

    try {
      this.data.loading += 1;
      await this.$apollo.mutate({
        mutation: patientsGql.settings.create(),
        variables: {
          patientId: this.patientId,
          data: payload,
        },
      });

      this.fetchData();

      webUtil.notify.success(this.$toast, 'Setting was saved successfully');
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }

  async removeSetting(kind: string) {
    try {
      this.data.loading += 1;
      await this.$apollo.mutate({
        mutation: patientsGql.settings.remove(),
        variables: {
          patientId: this.patientId,
          kind,
        },
      });

      this.fetchData();

      webUtil.notify.success(this.$toast, 'Setting was reset successfully');
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }

  async fetchData() {
    this.fetchPatientData();
    this.fetchMeasurementRules();
  }

  async fetchPatientData() {
    try {
      this.data.loading += 1;
      const {
        data: {
          Patients: {
            Show: { settings, Devices },
          },
        },
      } = await this.$apollo.query({
        query: patientsGql.settings.get(),
        variables: {
          patientId: this.patientId,
        },
      });

      this.settings = settings;
      this.devices = Devices;

      if (isEmpty(this.data.selDevice)) {
        this.data.selDevice = first(this.deviceTypes) as Record<string, string>;
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.data.loading -= 1;
    }
  }

  async fetchMeasurementRules() {
    try {
      this.data.loading += 1;
      const {
        data: {
          Patients: { MeasurementRules },
        },
      } = await this.$apollo.query({
        query: patientsGql.settings.get(),
        variables: {
          patientId: this.patientId,
        },
      });

      this.data.items = MeasurementRules;
    } catch (err) {
      console.log(err);
    } finally {
      this.data.loading -= 1;
    }
  }
}
