
import { Component, Vue, Prop } from 'vue-property-decorator';
import { opSymbol, valueUnitString } from '@/common/utils/rules';
import { has, startCase, isArray, isNumber, isString } from 'lodash';

@Component
export default class PlainRuleComponent extends Vue {
  @Prop({ default: () => ({}) }) ruleItem!: any;

  get fieldName(): string {
    return startCase(this.ruleItem.field);
  }

  get fieldOperator(): string | undefined {
    return opSymbol(this.ruleItem.op);
  }

  get valueUnit(): string {
    return (
      valueUnitString(this.ruleItem.value_unit) ||
      valueUnitString(this.ruleItem.valueUnit)
    );
  }

  isSimpleValue(value: number | string): boolean {
    return isNumber(value) || isString(value);
  }

  isArrayValues(value: number[] | string[]): boolean {
    return isArray(value);
  }

  hasOverPeriod(): boolean {
    return (
      has(this.ruleItem, 'period_value') && has(this.ruleItem, 'period_value')
    );
  }
}
