
import { messageGql } from '@/common/graphql/message';
import { setPagination } from '@/common/utils/utils';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { get, map, first, last, has, keys, List, forEach } from 'lodash';
import { webUtil } from '@/common/utils/web-utils';
import { GenericObjectT } from '@/common/types/interfaces';
import ToolTip from '@/common/components/ToolTip.vue';
import PrettyTs from '@/common/components/PrettyTs.vue';
import MessageThreadDialog from '@/components/messages/MessageThreadDialog.vue';

@Component({
  components: {
    ToolTip,
    PrettyTs,
    MessageThreadDialog,
  },
})
export default class MessageViewer extends Vue {
  @Prop({ default: 'Conversations' }) msgType!: string;
  @Prop({ default: true }) careTakerView!: boolean;
  @Prop({ default: '' }) patientId!: string;

  @Watch('msgType')
  onMsgTypeChg(): void {
    this.fetchData();
    this.data.pagination = {
      offset: 0,
      limit: 15,
      total: 0,
      totalPages: 0,
    };
    this.data.filter = {};
    this.data.sort = {};
  }

  @Watch('data.automated.pageNum')
  onPageNumChg(): void {
    this.data.pagination.offset =
      (this.data.automated.pageNum - 1) * this.data.pagination.limit;

    this.fetchData();
  }

  toEpoch = webUtil.time.epoch;
  truncate = webUtil.str.truncate;
  data = {
    loading: 0,
    selected: -1,
    dialog: {},
    automated: {
      headers: [
        {
          text: this.$webUtil.str.startCase(
            this.$t('common.headers.createdAt'),
          ),
          value: 'createdAt',
        },
        {
          text: this.$webUtil.str.startCase(this.$t('common.headers.patient')),
          value: 'toName',
        },
        {
          text: this.$webUtil.str.startCase(this.$t('common.headers.message')),
          value: 'body',
        },
        {
          text: this.$webUtil.str.startCase(this.$t('common.headers.actions')),
          value: 'actions',
        },
      ],
      pageNum: 1,
    },
    messages: [],
    careTakerInfo: {
      id: '',
      name: '',
    },
    pagination: {
      offset: 0,
      limit: 15,
      total: 0,
      totalPages: 0,
    },
    filter: {},
    sort: {},
  };

  get allMessages(): GenericObjectT[] {
    return this.data.messages;
  }

  mounted(): void {
    this.fetchData();
  }

  latestThreadTs(item: { threads: List<object> }): number {
    return webUtil.time.epoch(get(first(item.threads), 'createdAt', ''));
  }

  lastReceiptSeconds(receipt: GenericObjectT[]): number {
    return get(last(receipt), 'dateSent');
  }

  get automatedMessages(): GenericObjectT[] {
    const result: GenericObjectT[] = [];
    forEach(this.data.messages, (message: GenericObjectT): void => {
      result.push(...message.threads);
    });
    return map(result, (entry) => {
      return {
        ...entry,
        toName: `${entry.to.firstName} ${entry.to.lastName}`,
        lastReceipt: this.lastReceiptSeconds(entry.receipt),
      };
    });
  }

  getConvoPeople(
    item: { threads: List<object> },
    asArray: boolean,
  ): GenericObjectT {
    const people: GenericObjectT = {};
    forEach(item.threads, (thread: GenericObjectT): void => {
      const { from, to } = thread;
      const fromName = `${from.firstName} ${from.lastName}`;
      const toName = `${to.firstName} ${to.lastName}`;

      if (!has(people, fromName)) {
        if (from.id === this.data.careTakerInfo.id) {
          people['me'] = { id: from.id, name: fromName, kind: from.kind };
        } else {
          people[from.firstName] = {
            id: from.id,
            name: fromName,
            kind: from.kind,
          };
        }
      }
      if (!has(people, toName)) {
        if (to.id === this.data.careTakerInfo.id) {
          people['me'] = { id: to.id, name: toName, kind: to.kind };
        } else {
          people[to.firstName] = { id: to.id, name: toName, kind: to.kind };
        }
      }
    });
    return asArray === true ? keys(people) : people;
  }

  async fetchData() {
    try {
      this.data.loading += 1;
      const {
        data: {
          CareTakers: {
            Show: {
              id,
              name,
              Messages: { docs, pageInfo },
            },
          },
        },
      } = await this.$apollo.query({
        query: messageGql.query.byCareTakers(),
        variables: {
          msgType: this.msgType,
          pagination: {
            offset: this.data.pagination.offset,
            limit: this.data.pagination.limit,
          },
          params: {
            filter: this.data.filter,
            sort: this.data.sort,
          },
        },
        fetchPolicy: 'network-only',
      });

      this.data.careTakerInfo = { id, name };
      this.data.messages = docs;
      setPagination(this.data.pagination, pageInfo);
    } catch (err) {
      console.log(err);
      // webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }

  async resend(msgId: string) {
    try {
      this.data.loading += 1;
      await this.$apollo.mutate({
        mutation: messageGql.mutation.resendMessage(),
        variables: {
          msgId,
        },
        fetchPolicy: 'no-cache',
      });
    } catch (err) {
      console.log(err);
      // webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }
}
