
import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseDeviceIcon from './BaseDevice.vue';

@Component({
  components: {
    BaseDeviceIcon,
  },
})
export default class BloodPressureIcon extends Vue {
  @Prop({ default: false }) hasStatus!: boolean;
  @Prop({ default: '' }) variant!: string;
  @Prop({ default: '' }) status!: string;
}
