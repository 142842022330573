import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const gatewaysGql = {
  listAll(): DocumentNode {
    return gql`
      query gatewayQuery(
        $pagination: QueryInputPagination
        $params: QueryInputParam
      ) {
        Gateways {
          Get(pagination: $pagination, params: $params) {
            docs {
              id
              status
              firmware
              serialNumber
              macAddress
              assignmentDate
              Patient {
                id
                name
              }
            }
            pageInfo {
              offset
              limit
              total
            }
          }
        }
      }
    `;
  },
  create(): DocumentNode {
    return gql`
      mutation createGateway($data: NewGatewayInput!) {
        Gateway {
          New(data: $data) {
            id
          }
        }
      }
    `;
  },
};
