
import { Component, Vue } from 'vue-property-decorator';
import { dictionaryGql } from '@/common/graphql/dictionary';
import PageHeader from '@/common/components/PageHeader.vue';
import MessageViewer from '@/components/messages/MessageViewer.vue';
import NewMessageDialog from '@/components/messages/NewMessageForm.vue';
import { webUtil } from '@/common/utils/web-utils';

@Component({
  components: {
    PageHeader,
    MessageViewer,
    NewMessageDialog,
  },
})
export default class Messages extends Vue {
  data = {
    loading: 0,
    selected: 0,
    msgTypes: [],
  };

  get selectedType(): string {
    return this.data.msgTypes[this.data.selected];
  }

  mounted(): void {
    this.fetchData();
  }

  async fetchData() {
    this.fetchMessageTypes();
  }

  async fetchMessageTypes() {
    try {
      this.data.loading += 1;
      const {
        data: {
          Dictionary: {
            message: { types },
          },
        },
      } = await this.$apollo.query({
        query: dictionaryGql.message.types(),
      });

      this.data.msgTypes = types;
    } catch (err) {
      console.log(err);
      // webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }
}
