
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

@Component
export default class FormDialog extends Vue {
  @Prop({ default: '' }) patientId!: string;
  @Prop() closeDialogFn!: unknown;

  data = {
    loading: 0,
  };

  @Emit('close-dialog')
  closeDialog(): void {
    return;
  }
}
