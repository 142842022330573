
import { Component, Vue, Prop } from 'vue-property-decorator';
import BloodPressureIcon from '@/common/components/devices/BloodPressureIcon.vue';
import BloodGlucoseIcon from '@/common/components/devices/BloodGlucoseIcon.vue';
import WeightIcon from '@/common/components/devices/WeightIcon.vue';
import OximeterIcon from '@/common/components/devices/OximeterIcon.vue';
import ThermometerIcon from '@/common/components/devices/ThermometerIcon.vue';
import MeasurementTooltipBody from '@/common/components/MeasurementTooltipBody.vue';
import { GenericObjectT } from '@/common/types/interfaces';
import { isEmpty } from 'lodash';

@Component({
  components: {
    WeightIcon,
    OximeterIcon,
    ThermometerIcon,
    BloodGlucoseIcon,
    BloodPressureIcon,
    MeasurementTooltipBody,
  },
})
export default class DeviceIcon extends Vue {
  @Prop({ default: '' }) deviceType!: string;
  @Prop({ default: false }) hasStatus!: boolean;
  @Prop({ default: '' }) status!: string;
  @Prop({ default: () => ({}) }) lastMeasurement!: GenericObjectT;
  @Prop({ default: () => ({}) }) points!: GenericObjectT;

  is(devType: string): boolean {
    return this.deviceType === devType;
  }
  isEmpty = isEmpty;
}
