import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Dashboard from '@/views/Dashboard.vue';
import Patients from '@/views/patients/Patients.vue';
import PatientDetail from '@/views/patients/PatientDetail.vue';
import Notifications from '@/views/Notifications.vue';
import KnowledgeBase from '@/views/knowledge-bases/KnowledgeBases.vue';
import KnowledgeBaseArticles from '@/views/knowledge-bases/KnowledgeBaseArticles.vue';
import PatientEngagements from '@/views/PatientEngagements.vue';
import Messages from '@/views/Messages.vue';
import Settings from '@/views/Settings.vue';
import Devices from '@/views/Devices.vue';
import Gateways from '@/views/Gateways.vue';
import EquipmentRequests from '@/views/operations/EquipmentRequests.vue';
import BulkEquipmentImport from '@/views/operations/BulkEquipmentImport.vue';
import Login from '@/views/Login.vue';
import store from '@/store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { path: '/', name: 'main', redirect: '/patients' },
  { path: '/dashboard', name: 'dashboard', component: Dashboard },
  { path: '/patients', name: 'patients', component: Patients },
  {
    path: '/patients/:patientId',
    name: 'patientDetail',
    component: PatientDetail,
  },
  { path: '/measurements', name: 'measurements', component: Notifications },
  { path: '/knowledge-base', name: 'knowledgeBase', component: KnowledgeBase },
  {
    path: '/knowledge-base/:kbId/articles',
    name: 'kbArticles',
    component: KnowledgeBaseArticles,
  },
  {
    path: '/engagements',
    name: 'engagements',
    component: PatientEngagements,
  },
  { path: '/messages', name: 'messages', component: Messages },
  { path: '/settings', name: 'settings', component: Settings },
  { path: '/devices', name: 'devices', component: Devices },
  { path: '/gateways', name: 'gateways', component: Gateways },
  {
    path: '/equipment-requests',
    name: 'equipmentRequests',
    component: EquipmentRequests,
  },
  { path: '/bulk-import', name: 'bulkImport', component: BulkEquipmentImport },
  { path: '/login', name: 'login', component: Login },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export const initializeRouter = (): VueRouter => {
  router.beforeEach((to, from, next): void => {
    const authenticated = store.getters.isAuthenticated;

    if (authenticated) {
      if (to.name?.toLowerCase() === 'login') {
        return next({ name: 'main' });
      } else {
        return next();
      }
    } else {
      if (to.name?.toLocaleLowerCase() !== 'login') {
        return next({ name: 'login', query: to.query ? to.query : undefined });
      }
      return next();
    }
  });

  return router;
};

export default router;
