
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { GenericObjectT } from '@/common/types/interfaces';
import RangeSettingForm from '@/components/settings/forms/RangeSettingForm.vue';
import SimpleHistoricalSetting from '@/components/settings/forms/SimpleHistoricalSetting.vue';
import { webUtil } from '@/common/utils/web-utils';

@Component({
  filters: {
    startCase: webUtil.str.startCase,
  },
  components: {
    RangeSettingForm,
    SimpleHistoricalSetting,
  },
})
export default class OximeterSettingForm extends Vue {
  @Prop({ default: () => ({}) }) setting!: GenericObjectT;
  @Prop({ default: false }) flatCard!: boolean;

  kind = 'OximeterThreshold';
  data: GenericObjectT = {
    sp02: {
      min: 0,
      max: 0,
    },
    heartRate: {
      min: 0,
      max: 0,
    },
    historical: {
      status: false,
      period: 0,
      occurence: 0,
    },
  };

  @Emit('save-data')
  saveData(): GenericObjectT {
    return { kind: this.kind, data: this.data };
  }

  @Emit('reset-setting')
  removeSetting(): string {
    return this.kind;
  }
}
