/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import { set, forEach, camelCase } from 'lodash';
import { authActions, userActions } from '../actions';
import { AuthState, loadingStateE } from '../types';
import { updateJwtAbility } from '@/plugins/ability';

const state: AuthState = {
  accessToken: '',
  status: loadingStateE.Initial,
  loading: false,
};

const getters = {
  authLoading: (state: AuthState): boolean => state.loading,
  accessToken: (state: AuthState): string => state.accessToken,
  authStatus: (state: AuthState): string => state.status,
  isAuthenticated: (state: AuthState): boolean => state.accessToken.length > 0,
};

const actions = {
  [authActions.setter.loginRequest]: async ({
    commit,
  }: {
    commit: any;
  }): Promise<void> => {
    commit(authActions.setter.status, loadingStateE.Loading);
    commit(authActions.setter.authLoading, true);
    await Vue.prototype.$auth.loginWithRedirect({
      prompt: 'login',
    });
  },
  [authActions.setter.loginSuccess]: async ({
    commit,
  }: {
    commit: any;
  }): Promise<void> => {
    commit(authActions.setter.status, loadingStateE.Success);
    commit(authActions.setter.authLoading, true);
    try {
      const temp = await Vue.prototype.$auth.auth0Client.getUser();
      const user = {};
      forEach(Object.keys(temp), (key: string): void => {
        set(user, camelCase(key), temp[key]);
      });
      const accessToken = await Vue.prototype.$auth.getTokenSilently();
      commit(userActions.setter.profile, user);
      commit(authActions.setter.authToken, accessToken);
      updateJwtAbility(accessToken);
    } catch (err) {
      console.log(err);
    } finally {
      commit(authActions.setter.authLoading, false);
    }
  },
  [authActions.setter.logoutRequest]: async ({
    commit,
  }: {
    commit: any;
  }): Promise<void> => {
    commit(authActions.setter.status, loadingStateE.Initial);
    commit(authActions.setter.authToken, '');
  },
};

const mutations = {
  [authActions.setter.request]: (state: AuthState): void => {
    state.status = loadingStateE.Loading;
  },
  [authActions.setter.status]: (
    state: AuthState,
    status: loadingStateE,
  ): void => {
    state.status = status;
  },
  [authActions.setter.authToken]: (
    state: AuthState,
    accessToken: string,
  ): void => {
    state.accessToken = accessToken;
  },
  [authActions.setter.authLoading]: (
    state: AuthState,
    loading: boolean,
  ): void => {
    state.loading = loading;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
