
import { isEmpty } from 'lodash';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class HealthIcon extends Vue {
  @Prop({ default: '' }) variant!: string;
  @Prop({ default: '' }) src!: string;

  get iconSrc(): string {
    return `/icons/${this.src}`;
  }

  get iconCls(): string {
    if (isEmpty(this.variant)) {
      return 'icon';
    }
    return `icon-${this.variant}`;
  }
}
