export const nl = {
  deviceType: {
    bloodPressure: {
      text: 'bloeddruk',
      monitor: 'bloeddrukmonitor',
    },
    bloodGlucose: {
      text: 'bloed glucose',
      monitor: 'bloedglucosemeter',
    },
    thermometer: {
      text: 'thermometer',
    },
    oximeter: { text: 'zuurstofmeter' },
    weight: {
      text: 'gewicht',
      scale: 'weegschaal',
    },
  },
  common: {
    words: {
      days: 'dagen',
      last: 'laatst',
      measured: 'gemeten',
      years: 'jaren',
      yearsOld: 'jaar oud',
      measurement: 'meting',
      missed: 'mislopen',
      noDeviceFound: 'geen apparaat gevonden',
      noMessageFound: 'Geen bericht gevonden',
      noDataAvailable: 'geen gegevens beschikbaar',
      filter: 'filteren',
      notify: 'verwittigen',
      filterApplied: 'filter toegepast',
      lastMeasured: 'laatst gemeten',
      setting: 'Instelling',
      delivered: 'geleverd',
      types: 'types',
      note: 'nota',
      conditions: 'voorwaarden',
      when: 'wanneer',
      isBetween: 'is tussen',
      and: 'en',
      article: 'artikel',
      over: 'over',
      times: 'tijden',
      period: 'Periode',
      direction: 'richting',
      showMore: 'laat meer zien',
      engagementLog: 'Betrokkenheidslogboek',
      occurrences: 'Gebeurtenissen',
      checkIfSettingOccur: 'Controleer of er instellingen optreden',
      checkIfSettingNotOccur:
        'Instellingen niet controleren op historische metingen',
      basicInfo: 'basis informatie',
      contactInfo: 'contactgegevens',
      remoteMonProgress: 'Voortgang monitoring op afstand',
      gateway: 'toegangspoort',
      completedSinceLastBilling: 'Voltooid sinds de laatste factureringscyclus',
      currentMeasurement: 'huidige metingen',
      historicalMeasurement: 'historische metingen',
      increasedBy: 'Verhoogd Door',
      last2Weeks: 'Laatste 2 weken',
      lastMonth: 'Vorige maand',
      last3Months: 'Laatste 3 maand',
      cfgedNotYetSeen: 'Geconfigureerd, maar nog niet gezien',
      lastSeen: 'Laatst gezien',
      assign: 'toewijzen',
      replace: 'vervangen',
      remove: 'verwijderen',
      byName: 'bij naam',
      byAgeGroup: 'per leeftijdsgroep',
      byDeviceTypes: 'per apparaattype',
      any: 'een',
    },
    title: {
      patientAge: 'patiënt leeftijd',
      deviceDistribution: 'apparaat distributie',
      patientAgeDistribution: 'leeftijdsverdeling van de patiënt',
      deviceTypeDistribution: 'distributie van het apparaattype',
      newPatientEngLog: 'Nieuw patiëntbetrokkenheidslogboek',
      newPatientNote: 'nieuwe patiëntnota',
      notificationSetting: 'notificatie instelling',
    },
    btnLabel: {
      new: 'nieuwe',
      refresh: 'verfrissen',
      clearFilter: 'helder filter',
      send: 'verzenden',
      resend: 'opnieuw verzenden',
      reset: 'resetten',
      save: 'sparen',
      cancel: 'annuleren',
      requestDevice: 'een apparaat aanvragen',
      requested: 'aangevraagd',
      requestGateway: 'een toegangspoort aanvragen',
      discard: 'afdanken',
      dismiss: 'ontslaan',
      apply: 'toepassen',
      readMore: 'Lees verder',
    },
    formPlaceholder: {
      selectPerson: 'Selecteer een persoon',
      typeASubject: 'Typ hier een onderwerp ...',
      typeAMessage: 'Typ hier een bericht ...',
      typeANote: 'typ hier een notitie ...',
      chooseEngType: 'Kies het type betrokkenheid',
      typePartialName: 'Typ gedeeltelijke naam  ...',
    },
    deviceWords: {
      sp02: 'sp02',
      systolic: 'systolisch',
      diastolic: 'diastolisch',
      heartRate: 'hartslag',
      glucose: 'glucose',
      fasting: 'fasting',
      temperature: 'temperatuur-',
      weight: 'gewicht',
      lookback: 'terug kijken',
      value: 'value',
    },
    tpl: {
      xAge: '{age} jaren',
      deviceDetail: '{device} detail',
      measurementStats: 'meetstatistieken over {count} patiënten',
      listOfX: 'lijst met {msg}',
    },
    roles: {
      careTaker: 'verzorger',
    },
    headers: {
      name: 'Naam',
      age: 'Leeftijd',
      devices: 'Apparaten',
      lastMeasurements: 'Laatst Meting',
      dateTime: 'Datum/Tijd',
      date: 'Datum',
      patient: 'Patiënt',
      patients: 'Patiënt',
      deviceType: 'soort apparaat',
      tags: 'labels',
      notes: 'notas',
      dashboard: 'dashboard',
      measurements: 'maten',
      knowledgeBase: 'kennis basis',
      messages: 'berichten',
      message: 'bericht',
      alertTriggers: 'Waarschuwingstriggers',
      conversations: 'conversaties',
      automated: 'geautomatiseerd',
      createdAt: 'Aangemaakt Bij',
      actions: 'gedrag',
      receipts: 'bonnen',
      manage: 'beheren',
      settings: 'instellingen',
      content: 'inhoud',
      duration: 'duur',
      log: 'logboek',
      email: 'E-mailen',
      address: 'Adres',
      phone: 'Telefoon',
      since: 'Sinds',
      goal: 'doel',
      overview: 'Overzicht',
      types: 'Types',
      active: 'actief',
      patientEngagements: 'Patiëntbetrokkenheid',
      lastTwoComparison: 'Vergelijking laatste twee metingen',
      requestedAt: 'Verzocht bij',
      notifyCareTeam: 'Zorgteam op de hoogte',
    },
  },
};
