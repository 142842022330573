import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const knowledgeBasesGql = {
  list(): DocumentNode {
    return gql`
      query kbListyQuery(
        $pagination: QueryInputPagination
        $params: QueryInputParam
      ) {
        KnowledgeBase {
          KbList(pagination: $pagination, params: $params) {
            docs {
              id
              title
              description
              groupImageUrl
              articleCount
            }
            pageInfo {
              offset
              limit
              total
            }
          }
        }
      }
    `;
  },
  listArticles(): DocumentNode {
    return gql`
      query kbArticleListQuery(
        $kbId: ID!
        $pagination: QueryInputPagination
        $params: QueryInputParam
      ) {
        KnowledgeBase {
          Articles(kbId: $kbId, pagination: $pagination, params: $params) {
            docs {
              id
              kbId
              articleId
              title
              content
              headerImageUrl
              embeddedVideoUrl
              embeddedDocumentUrl
            }
            pageInfo {
              offset
              limit
              total
            }
          }
        }
      }
    `;
  },
};
