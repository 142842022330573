
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { webUtil } from '../utils/web-utils';

@Component({
  filters: {
    startCase: webUtil.str.startCase,
  },
})
export default class PageHeader extends Vue {
  @Prop({ default: 'Default title ' }) title!: string;
  @Prop({ default: false }) clearFilter!: boolean;

  @Emit('clear-filter')
  clearFilterFn(): void {
    return;
  }
}
