
import { get, find } from 'lodash';
import { patientsGql } from '@/common/graphql/patients';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import PrettyTs from '@/common/components/PrettyTs.vue';

@Component({
  components: {
    PrettyTs,
  },
})
export default class GatewayRequestBtn extends Vue {
  @Prop({ default: '' }) patientId!: string;
  @Watch('patientId')
  onPatientIdChg(): void {
    this.fetchData();
  }

  data = {
    loading: 0,
    setLoading: 0,
    gateway: {},
    reqEquipments: [{}],
  };

  get isLoading(): boolean {
    return this.data.setLoading > 0;
  }

  get gatewayId(): string {
    return get(this.data, 'gateway.id', '');
  }

  get requestedGw(): object | undefined {
    const gateway = find(this.data.reqEquipments, { gateway: true });

    return gateway;
  }

  mounted(): void {
    this.fetchData();
  }

  async fetchData() {
    try {
      this.data.loading += 1;
      const {
        data: {
          Patients: {
            Show: { Gateway, requestedDevices },
          },
        },
      } = await this.$apollo.query({
        query: patientsGql.patients.requestedEquipments(),
        variables: {
          patientId: this.patientId,
        },
        fetchPolicy: 'network-only',
      });
      this.data.gateway = Gateway;
      this.data.reqEquipments = requestedDevices;
    } catch (err) {
      console.log(err);
    } finally {
      this.data.loading -= 1;
    }
  }

  async requestGateway() {
    try {
      this.$data.setLoading += 1;
      await this.$apollo.mutate({
        mutation: patientsGql.equipmentRequest.setGateway(),
        variables: {
          patientId: this.patientId,
          action: 'Assign',
        },
      });

      console.log('Fetching data after setting request for gateway');
      this.fetchData();
    } catch (err) {
      console.log(err);
    } finally {
      this.$data.setLoading -= 1;
    }
  }
}
