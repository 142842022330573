import { ApolloLink, ApolloClient, InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import store from '@/store';
import { authActions } from '@/store/actions';
import router from '@/router';
import { get, noop } from 'lodash';

const link = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(
      ({ message, locations, path, extensions: { code, response } }) => {
        // eslint-disable-next-line
        const { statusCode } = response as any;
        switch (statusCode) {
          case 401:
          case 403:
            store
              .dispatch(authActions.setter.logoutRequest)
              .then(() => {
                console.log(get(router, 'app._route.name'));
                if (get(router, 'app._route.name') !== 'login') {
                  router.push('/login').catch(noop);
                }
              })
              .catch(() => {
                router.push('/login').catch(noop);
              });
            break;
          default:
            console.log(
              `[GraphQL error ${code}]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            );
            break;
        }
      },
    );
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = store.getters.accessToken;

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : null,
    },
  });

  return forward(operation);
});

// HTTP connection to the API
const httpLink = createUploadLink({
  uri: process.env.VUE_APP_GRAPHQL_URI,
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: link.concat(authMiddleware).concat(httpLink as unknown as ApolloLink),
  cache,
});
