
import { Component, Vue } from 'vue-property-decorator';
import { patientsGql } from '@/common/graphql/patients';
import {
  ruleHealthDevices,
  parseRule,
  notificationDataT,
  ruleTypeT,
} from '@/common/utils/rules';
import ToolTip from '@/common/components/ToolTip.vue';
import FormDialog from '@/common/components/FormDialog.vue';
import AdvancedRuleForm from '@/common/components/rules/AdvancedRuleForm.vue';
import AdvancedRuleDescriber from '@/common/components/rules/RuleDescriber.vue';
import RuleNotificationConfigure from '@/common/components/rules/RuleNotificationConfig.vue';

@Component({
  components: {
    ToolTip,
    FormDialog,
    AdvancedRuleForm,
    AdvancedRuleDescriber,
    RuleNotificationConfigure,
  },
})
export default class PatientAdvRulesSection extends Vue {
  debug = false;
  data = {
    loading: 0,
    showDialog: false,
    patientId: this.$route.params.patientId,
    rules: [],
    headers: [
      { text: this.getLocaleHdr('advRules'), value: 'advRule' },
      { text: this.getLocaleHdr('deviceType'), value: 'deviceType' },
      // { text: this.getLocaleHdr('enabled'), value: 'enabled', sortable: false },
      {
        text: this.getLocaleHdr('inherited'),
        value: 'inherited',
        sortable: false,
      },
      {
        text: this.getLocaleHdr('notification'),
        value: 'notification',
        sortable: false,
      },
      {
        text: this.getLocaleHdr('actions'),
        value: 'actions',
        sortable: false,
      },
    ],
  };

  humanizeSec = this.$webUtil.time.humanize.seconds;

  get isLoading(): boolean {
    return this.data.loading > 0;
  }

  humanizeRecurring(value: string | number) {
    let result = this.humanizeSec(value).replace('a ', '').replace('an ', '');
    return this.startCase(result);
  }

  mounted(): void {
    this.fetchData();
  }

  getLocaleHdr(label: string): string {
    const camelLabel = this.$webUtil.str.camelCase(label);
    if (camelLabel !== 'dateTime') {
      return this.$webUtil.str.startCase(
        this.$t(`common.headers.${this.$webUtil.str.camelCase(label)}`),
      );
    }
    return this.$t(`common.headers.${camelLabel}`) as string;
  }

  startCase(value: string): string {
    return this.$webUtil.str.startCase(value);
  }

  getRandId(): number {
    return Math.random() * 1_000_000;
  }

  getParsedRule(rule: ruleTypeT) {
    return parseRule(rule);
  }

  getHealthDevices(rule: ruleTypeT) {
    return ruleHealthDevices(rule);
  }

  async closeDialog(reload: boolean): Promise<void> {
    this.data.showDialog = false;

    if (reload) {
      await this.fetchData();
    }
  }

  closeDeleteDialog(item: { deleteDialog: boolean }) {
    item.deleteDialog = false;
  }

  async fetchData(): Promise<void> {
    try {
      console.log('Fetching data in Adv. Rule Section ...');
      this.data.loading += 1;
      const {
        data: {
          Patients: { MeasurementRules },
        },
      } = await this.$apollo.query({
        query: patientsGql.settings.get(),
        variables: {
          patientId: this.data.patientId,
        },
        fetchPolicy: 'no-cache',
      });
      this.data.rules = MeasurementRules;
    } catch (err: unknown) {
      console.log(err);
      this.$webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }

  async deleteRule(item: { id: string }): Promise<void> {
    try {
      this.data.loading += 1;
      await this.$apollo.mutate({
        mutation: patientsGql.measurementRule.delete(),
        variables: {
          patientId: this.data.patientId,
          ruleId: item.id,
        },
      });
      this.$webUtil.notify.success(
        this.$toast,
        'Rule was deleted successfully',
      );
      await this.fetchData();
    } catch (err: unknown) {
      console.log(err);
      this.$webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }

  async saveNotificationData(value: {
    ruleId: string;
    value: notificationDataT;
  }): Promise<void> {
    try {
      this.data.loading += 1;
      console.log(`Value: ${JSON.stringify(value)}`);
      await this.$apollo.mutate({
        mutation: patientsGql.measurementRule.setNotification(),
        variables: {
          patientId: this.data.patientId,
          ruleId: value.ruleId,
          data: value.value,
        },
      });

      this.$webUtil.notify.success(
        this.$toast,
        'Notification throttle was set successfully.',
      );

      this.fetchData();
    } catch (err: unknown) {
      console.log(err);
      this.$webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }
}
