import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const dashboardsGql = {
  query: {
    measurementStats(): DocumentNode {
      return gql`
        query Dashboard {
          Dashboards {
            MeasurementStats
          }
        }
      `;
    },
    distributionStats(): DocumentNode {
      return gql`
        query Dashboard {
          Dashboards {
            DistributionStats
          }
        }
      `;
    },
  },
};
