
import { webUtil } from '@/common/utils/web-utils';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { patientsGql } from '@/common/graphql/patients';
import { map, get, isEmpty, forEach } from 'lodash';
import { GenericObjectT } from '@/common/types/interfaces';

@Component
export default class FilterByPatientName extends Vue {
  @Prop({ default: 30 }) displayMaxEntry!: number;
  @Prop({ default: '_id' }) filterKey!: string;

  @Watch('data.valueInput')
  onDataValueInput(): void {
    this.fetchData();
  }

  data = {
    loading: 0,
    valueInput: '',
    values: [],
    totalEntry: 0,
    currentFilter: [],
  };

  get isLoading(): boolean {
    return this.data.loading > 0;
  }

  get dataValues(): GenericObjectT[] {
    return this.data.values;
  }

  @Emit('updated-filter')
  updateSelected(): GenericObjectT {
    const currentFilter = this.data.values.filter(
      (value: GenericObjectT): boolean => value.selected,
    );
    this.data.currentFilter = currentFilter;
    let updatedFilter: GenericObjectT = {};

    if (currentFilter.length === 1) {
      updatedFilter = {
        [this.filterKey]: get(currentFilter, '0.id'),
      };
    } else if (currentFilter.length > 1) {
      updatedFilter = {
        $or: [],
      };

      forEach(currentFilter, (filter: GenericObjectT): void => {
        updatedFilter.$or.push({ [this.filterKey]: get(filter, 'id') });
      });
    }

    console.log(`Updated Filter: ${JSON.stringify(updatedFilter, null, 2)}`);

    return { section: 'byName', value: updatedFilter };
  }

  mounted(): void {
    this.fetchData();
  }

  buildParams() {
    if (isEmpty(this.data.valueInput)) {
      return {};
    }
    return {
      $or: [
        { firstName: { $regex: this.data.valueInput } },
        { lastName: { $regex: this.data.valueInput } },
      ],
    };
  }

  async fetchData(): Promise<void> {
    try {
      this.data.loading += 1;
      const {
        data: {
          Patients: {
            Get: { docs, pageInfo },
          },
        },
      } = await this.$apollo.query({
        query: patientsGql.patients.byIdAndName(),
        variables: {
          pagination: {
            offset: 0,
            limit: this.displayMaxEntry,
          },
          params: { filter: this.buildParams(), sort: {} },
        },
      });

      this.data.values = map(docs, (value: GenericObjectT): GenericObjectT => {
        return {
          selected: false,
          ...value,
        };
      }) as never[];
      this.data.totalEntry = get(pageInfo, 'total', 0);
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }
}
