
import { Component, Vue } from 'vue-property-decorator';
import { tenantsGql } from '@/common/graphql/tenants';
import AdvancedRuleDescriber from '@/common/components/rules/RuleDescriber.vue';
import RuleNotificationConfigure from '@/common/components/rules/RuleNotificationConfig.vue';
import {
  notificationDataT,
  ruleHealthDevices,
  parseRule,
  ruleTypeT,
} from '@/common/utils/rules';

@Component({ components: { AdvancedRuleDescriber, RuleNotificationConfigure } })
export default class AdvancedRulesSetting extends Vue {
  data = {
    loading: 0,
    rules: [],
    headers: [
      { text: 'Description', value: 'rule' },
      { text: 'Device Type', value: 'deviceType' },
      { text: 'Notification Throttle', value: 'notification' },
    ],
  };

  parseRule = parseRule;
  startCase = this.$webUtil.str.startCase;
  decFmt = this.$webUtil.number.dec;
  humanizeSec = this.$webUtil.time.humanize.seconds;

  humanizeRecurring(value: string | number) {
    let result = this.humanizeSec(value).replace('a ', '').replace('an ', '');
    return this.startCase(result);
  }

  getSeverityColor(severity: string): string {
    switch (severity.toLowerCase()) {
      case 'low':
        return 'orange darken-2';
      case 'medium':
        return 'deep-orange darken-4';
      case 'high':
        return 'red darken-4';
      default:
        return 'primary';
    }
  }

  getRandId(): number {
    return Math.random() * 1_000_000;
  }

  getHealthDevices(rule: ruleTypeT) {
    return ruleHealthDevices(rule, true);
  }

  async fetchData() {
    try {
      this.data.loading += 1;
      const {
        data: {
          Tenant: {
            Settings: { advancedRules },
          },
        },
      } = await this.$apollo.query({
        query: tenantsGql.query.settings(),
        fetchPolicy: 'no-cache',
      });

      this.data.rules = advancedRules;
    } catch (err) {
      console.log(err);
    } finally {
      this.data.loading -= 1;
    }
  }

  async saveNotificationData(value: {
    ruleId: string;
    value: notificationDataT;
  }): Promise<void> {
    console.log(`Update notification data: ${JSON.stringify(value)}`);
    try {
      this.data.loading += 1;

      await this.$apollo.mutate({
        mutation: tenantsGql.mutation.measurementRuleNotification(),
        variables: {
          ruleId: value.ruleId,
          data: value.value,
        },
      });

      this.$webUtil.notify.success(
        this.$toast,
        'Notification throttle was set successfully.',
      );
      this.fetchData();
    } catch (err: unknown) {
      console.log(err);
      this.$webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }

  mounted() {
    this.fetchData();
  }
}
