import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const filtersGql = {
  device: {
    initFilter(): DocumentNode {
      return gql`
        query filterDeviceQuery(
          $pagination: QueryInputPagination
          $params: QueryInputParam
        ) {
          Dictionary {
            equipments {
              status
              types
            }
          }
          Patients {
            Get(pagination: $pagination, params: $params) {
              docs {
                id
                name
              }
              pageInfo {
                offset
                limit
                total
              }
            }
          }
        }
      `;
    },
  },
};
