
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { patientsGql } from '@/common/graphql/patients';
import { setPagination } from '@/common/utils/utils';
import moment from 'moment';
import PrettyTs from '@/common/components/PrettyTs.vue';
import DeviceIcon from '@/common/components/devices/DeviceIcon.vue';
import BloodPressureMeasurementDisplay from '@/common/components/devices/measurements/BloodPressureMeasurement.vue';
import OximeterMeasurementDisplay from '@/common/components/devices/measurements/OximeterMeasurement.vue';
import BloodGlucoseMeasurementDisplay from '@/common/components/devices/measurements/BloodGlucoseMeasurement.vue';
import ThermometerMeasurementDisplay from '@/common/components/devices/measurements/ThermometerMeasurement.vue';
import WeightScaleMeasurementDisplay from '@/common/components/devices/measurements/WeightScaleMeasurement.vue';
import { GenericObjectT } from '@/common/types/interfaces';

@Component({
  components: {
    PrettyTs,
    DeviceIcon,
    BloodPressureMeasurementDisplay,
    OximeterMeasurementDisplay,
    BloodGlucoseMeasurementDisplay,
    ThermometerMeasurementDisplay,
    WeightScaleMeasurementDisplay,
  },
})
export default class PatientMeasurement extends Vue {
  @Prop({ default: '' }) patientId!: string;

  debug = false;
  data = {
    loading: 0,
    rows: [],
    headers: [
      { text: this.getLocaleHdr('Date/Time'), value: 'ts' },
      { text: this.getLocaleHdr('Device Type'), value: 'deviceType' },
      { text: this.getLocaleHdr('measurements'), value: 'deviceData' },
    ],
    pagination: {
      offset: 0,
      limit: 20,
      total: 0,
      totalPages: 0,
    },
    pageNum: 1,
  };
  lastRefreshTs = '';
  interval = -1;

  @Watch('data.pageNum')
  onPageNumChg(): void {
    this.data.pagination.offset =
      (this.data.pageNum - 1) * this.data.pagination.limit;

    this.fetchData(true);
  }

  convertToSecond(dateTimeStr: string): number {
    return moment.utc(dateTimeStr).unix();
  }

  getLocaleHdr(label: string): string {
    const camelLabel = this.$webUtil.str.camelCase(label);
    if (camelLabel !== 'dateTime') {
      return this.$webUtil.str.startCase(
        this.$t(`common.headers.${this.$webUtil.str.camelCase(label)}`),
      );
    }
    return this.$t(`common.headers.${camelLabel}`) as string;
  }

  annotateItem(item: GenericObjectT) {
    return {
      entry: item,
      units: item.meta.units,
    };
  }

  async fetchData(force = false) {
    try {
      this.data.loading += 1;
      const {
        data: {
          Patients: {
            RawMeasurements: { docs, pageInfo },
          },
        },
      } = await this.$apollo.query({
        query: patientsGql.patients.rawMeasurementList(),
        variables: {
          patientId: this.patientId,
          pagination: {
            offset: this.data.pagination.offset,
            limit: this.data.pagination.limit,
          },
        },
      });

      this.data.rows = docs;
      setPagination(this.data.pagination, pageInfo);

      this.setAutoRefresh(force);
    } catch (err: unknown) {
      console.log(`ERR: ${err}`);
      this.$webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }

  setAutoRefresh(force: boolean) {
    this.lastRefreshTs = this.$webUtil.time.fullTs(moment().unix() * 1_000);
    if (force === true) {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = -1;
      }
    } else if (this.interval >= 0) {
      return;
    }

    this.interval = setInterval(() => this.fetchData(false), 60_000);
  }

  mounted(): void {
    this.fetchData();
  }
  beforeDestroy(): void {
    clearInterval(this.interval);
    this.interval = -1;
  }
}
