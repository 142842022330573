export const eventNames = {
  patients: {
    filter: {
      updated: 'Patients:Filter:Updated',
      clear: {
        all: 'Patients:Filter:Clear:All',
        byName: 'Patients:Filter:Clear:By_Name',
        byAge: 'Patients:Filter:Clear:By_Age',
        byDeviceType: 'Patients:Filter:Clear:By_Device_Type',
      },
    },
  },
};
