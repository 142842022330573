
import { Component, Vue } from 'vue-property-decorator';
import PageHeader from '@/common/components/PageHeader.vue';
import { supportGql } from '@/common/graphql/support';
import { webUtil } from '@/common/utils/web-utils';

@Component({
  components: {
    PageHeader,
  },
})
export default class BulkEquipmentImport extends Vue {
  error: unknown = undefined;
  data = {
    selectedTab: '',
    tabs: [
      { id: 'gateway', label: 'Gateway' },
      { id: 'device', label: 'Device' },
    ],
    supportedTypes: ['csv', 'json'],
    file: '',
    fileType: 'json',
  };

  result = {};

  async importFile() {
    try {
      const result = await this.$apollo.mutate({
        mutation: supportGql.mutation.importGateway(),
        variables: {
          file: this.data,
        },
      });
      this.result = result;
    } catch (err: unknown) {
      console.log(err);
      this.error = err;
      webUtil.notify.error(this.$toast, err);
    }
  }
}
