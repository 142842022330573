
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import { GenericObjectT } from '@/common/types/interfaces';
import { preprocDictionary, setPagination } from '@/common/utils/utils';
import { filtersGql } from '@/common/graphql/filters';
import { webUtil } from '@/common/utils/web-utils';

@Component
export default class GatewayFilterBody extends Vue {
  @Prop({ default: '' }) group!: string;
  @PropSync('filters', { default: () => ({}) }) currFilters!: GenericObjectT;

  data = {
    loading: 0,
    patients: [],
    gateway: {
      status: [{}],
    },
    pagination: {
      offset: 0,
      limit: 15,
      total: 0,
      totalPages: 0,
    },
    params: {},
  };

  is(groupIn: string): boolean {
    return this.group === groupIn;
  }

  mounted(): void {
    this.fetchData();
  }

  async fetchData() {
    try {
      this.data.loading += 1;
      const {
        data: {
          Dictionary: {
            equipments: { status },
          },
          Patients: {
            Get: { docs, pageInfo },
          },
        },
      } = await this.$apollo.query({
        query: filtersGql.device.initFilter(),
        variables: {
          pagination: {
            offset: this.data.pagination.offset,
            limit: this.data.pagination.limit,
          },
          params: this.data.params,
        },
      });

      this.data.patients = docs;
      this.data.gateway.status = preprocDictionary(status);
      setPagination(this.data.pagination, pageInfo);
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }
}
