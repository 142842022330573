
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { forEach, camelCase, isEmpty } from 'lodash';
import { webUtil } from '@/common/utils/web-utils';
import { GenericObjectT } from '@/common/types/interfaces';

@Component({
  filters: {
    startCase: (label: string): string =>
      ['sp02'].includes(label) ? label : webUtil.str.startCase(label),
  },
})
export default class DeviceLabel extends Vue {
  @Prop({ default: '' }) deviceType!: string;
  @Prop({ default: '' }) label!: string;
  @Prop({ default: () => ({}) }) item!: GenericObjectT;

  data = {
    loading: 0,
    tags: [],
  };

  @Watch('item', { deep: true })
  onDeviceTypeChg(): void {
    this.data.tags = [];
    this.buildTags();
  }

  mounted(): void {
    this.buildTags();
  }

  buildTags(): void {
    try {
      this.data.loading += 1;
      const tagMapping: Record<string, string[]> = {
        bloodPressure: ['systolic', 'diastolic', 'heartRate'],
        bloodGlucose: ['glucose'],
        weight: ['weight'],
        oximeter: ['sp02', 'heartRate'],
        thermometer: ['temperature'],
      };
      const valueMap: Record<string, string> = {
        '1': 'high',
        '0': '',
        '-1': 'low',
      };
      const colorMap: Record<string, string> = {
        '1': 'red lighten-1',
        '0': '',
        '-1': 'blue lighten-1',
      };
      const iconMap: Record<string, string> = {
        '1': 'mdi-trending-up',
        '0': '',
        '-1': 'mdi-trending-down',
      };

      const showNormalFlag = ['0', '0_0', '0_0_0'].includes(this.label);
      const tags = this.label.split('_');
      const labelMap = tagMapping[camelCase(this.deviceType)];
      forEach(tags, (key: string, kix: number): void => {
        const value = valueMap[key];
        if (!isEmpty(value) || showNormalFlag === true) {
          this.data.tags.push({
            label: labelMap[kix],
            value,
            color: colorMap[key],
            icon: iconMap[key],
          } as never);
        }
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.data.loading -= 1;
    }
  }
}
