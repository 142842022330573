
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { get, isEmpty, forEach, find } from 'lodash';
import moment from 'moment';

@Component
export default class PatientDetailProgress extends Vue {
  @Prop({ default: '' }) measurements!: any;
  @Prop({ default: '' }) period!: { text: string; value: string };

  data = {
    startDate: moment(),
  };

  @Watch('period')
  onPeriodChg(val: { text: string; value: string }): void {
    switch (val.value) {
      case 'Past_2_Weeks':
        this.data.startDate = moment().subtract(2, 'weeks').startOf('day');
        break;
      case 'Past_Month':
        this.data.startDate = moment().subtract(1, 'months').startOf('day');
        break;
      case 'Past_3_Months':
        this.data.startDate = moment().subtract(3, 'months').startOf('day');
        break;
    }
  }

  get missedReadings(): number {
    return get(this.getProgressValues(), 'missed', 0);
  }

  get measuredReadings(): number {
    return get(this.getProgressValues(), 'completed', 0);
  }

  getProgressValues(): Record<string, number> {
    const result = {
      missed: 0,
      completed: 0,
    };
    let startDate = moment().subtract(2, 'weeks').startOf('day');
    switch (this.period.value) {
      case 'Past_2_Weeks':
        startDate = moment().subtract(2, 'weeks').startOf('day');
        break;
      case 'Past_Month':
        startDate = moment().subtract(1, 'months').startOf('day');
        break;
      case 'Past_3_Months':
        startDate = moment().subtract(3, 'months').startOf('day');
        break;
    }

    if (isEmpty(this.measurements)) {
      return result;
    }

    const possible: moment.Moment[] = [];
    const pattern = 'YYYY-MM-DD';
    const endDate = moment().startOf('day');
    console.log(`Start date: ${startDate} -> ${endDate}`);
    while (startDate.format(pattern) !== endDate.format(pattern)) {
      possible.push(startDate.clone());
      startDate = startDate.add(1, 'days');
    }
    possible.shift();
    possible.push(endDate);
    console.log(possible);

    forEach(possible, (dateString: moment.Moment): void => {
      const found = find(this.measurements, {
        timestamp: dateString.format('YYYY-MM-DD'),
      });
      if (found) {
        result.completed += 1;
      } else {
        result.missed += 1;
      }
    });
    // let currDate = this.data.startDate;
    // const now = moment().startOf('day');
    // const dates = [];
    // let counter = 0;
    // while (currDate <= now) {
    //   dates.push(currDate);
    //   currDate.add(1, 'days');
    //   if (counter++ > 30) {
    //     break;
    //   }
    // }
    // console.log(`Possible Dates`, dates);

    // forEach(this.measurements, (entry): void => {
    //   if (every(values(entry), isNumber)) {
    //     result.completed += 1;
    //   } else {
    //     result.missed += 1;
    //   }
    // });
    return result;
  }
}
