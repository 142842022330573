import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const messageGql = {
  query: {
    byCareTakers(): DocumentNode {
      return gql`
        query careTakerMessageQuery(
          $msgType: MessageTypeE!
          $pagination: QueryInputPagination
          $params: QueryInputParam
        ) {
          CareTakers {
            Show {
              id
              name
              Messages(
                type: $msgType
                params: $params
                pagination: $pagination
              ) {
                docs {
                  id
                  subject
                  threads {
                    msgId
                    messageType
                    messageThreadId
                    body
                    createdAt
                    careTakerTeam
                    from {
                      id
                      firstName
                      lastName
                      kind
                    }
                    to {
                      id
                      firstName
                      lastName
                      kind
                    }
                    receipt {
                      kind
                      sentId
                      dateSent
                      errorCode
                      errorString
                    }
                  }
                }
                pageInfo {
                  offset
                  limit
                  total
                  totalPages
                }
              }
            }
          }
        }
      `;
    },
    byPatient(): DocumentNode {
      return gql`
        query patientMessageQuery(
          $patientId: ID!
          $msgType: MessageTypeE!
          $pagination: QueryInputPagination
          $params: QueryInputParam
        ) {
          Patients {
            Show(patientId: $patientId) {
              id
              name
              Messages(
                type: $msgType
                params: $params
                pagination: $pagination
              ) {
                docs {
                  id
                  subject
                  threads {
                    msgId
                    messageType
                    messageThreadId
                    body
                    createdAt
                    careTakerTeam
                    from {
                      id
                      firstName
                      lastName
                      kind
                    }
                    to {
                      id
                      firstName
                      lastName
                      kind
                    }
                    receipt {
                      kind
                      sentId
                      dateSent
                      errorCode
                      errorString
                    }
                  }
                }
                pageInfo {
                  offset
                  limit
                  total
                  totalPages
                }
              }
            }
          }
        }
      `;
    },
    byThreadId(): DocumentNode {
      return gql`
        query patientMessageThreadsQuery(
          $threadId: ID!
          $pagination: QueryInputPagination
          $params: QueryInputParam
        ) {
          CareTakers {
            MessageThreads(
              threadId: $threadId
              params: $params
              pagination: $pagination
            ) {
              docs {
                msgId
                messageType
                messageThreadId
                body
                createdAt
                careTakerTeam
                from {
                  id
                  firstName
                  lastName
                  kind
                }
                to {
                  id
                  firstName
                  lastName
                  kind
                }
                receipt {
                  kind
                  sentId
                  dateSent
                  errorCode
                  errorString
                }
              }
              pageInfo {
                offset
                limit
                total
                totalPages
              }
            }
          }
        }
      `;
    },
  },
  mutation: {
    newConversation(): DocumentNode {
      return gql`
        mutation NewConversationMessage(
          $patientId: ID!
          $data: MessageConversationInput!
        ) {
          Patient {
            Message(patientId: $patientId) {
              NewConversation(data: $data)
            }
          }
        }
      `;
    },
    replyConversation(): DocumentNode {
      return gql`
        mutation ReplyConversationMessage(
          $patientId: ID!
          $msgThreadId: ID!
          $data: MessageConversationInput!
        ) {
          Patient {
            Message(patientId: $patientId) {
              ReplyConversation(msgThreadId: $msgThreadId, data: $data)
            }
          }
        }
      `;
    },
    resendMessage(): DocumentNode {
      return gql`
        mutation ResendConversationMessage($msgId: ID!) {
          CareTaker {
            ResendMessage(msgId: $msgId)
          }
        }
      `;
    },
  },
};
