
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component
export default class MessageAlertMenu extends Vue {
  @Prop({ default: '' }) msgId!: string;
  @Prop({ default: false }) canResend!: boolean;

  @Emit('resend-message')
  onResendMessage(): string {
    return this.msgId;
  }

  data = {
    loading: 0,
  };
}
