
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { GenericObjectT } from '@/common/types/interfaces';
import { cloneDeep, forEach, groupBy } from 'lodash';
import { messageGql } from '@/common/graphql/message';
import FormDialog from '@/common/components/FormDialog.vue';
import MessageThreadList from '@/components/messages/MessageThreadList.vue';
import { setPagination } from '@/common/utils/utils';
import { webUtil } from '@/common/utils/web-utils';

@Component({
  components: {
    FormDialog,
    MessageThreadList,
  },
})
export default class MessageThreadDialog extends Vue {
  @Prop({ default: false }) dialogVisible!: boolean;
  @Prop({ default: '' }) threadId!: string;
  @Prop({ default: '' }) subject!: string;
  @Prop({ default: () => ({}) }) participants!: GenericObjectT;

  @Emit('close-dialog')
  closeDialog() {
    return;
  }

  @Watch('dialogVisible')
  onDialogVisibleChg(value: boolean) {
    if (value === true) {
      this.data.intervalId = window.setInterval(() => {
        this.fetchData();
      }, 3000);
    } else {
      clearInterval(this.data.intervalId);
    }
  }

  data = {
    loading: 0,
    newMessage: '',
    threads: [],
    pagination: {
      offset: 0,
      limit: 25,
      total: 0,
      totalPages: 0,
    },
    intervalId: -1,
  };

  mounted(): void {
    this.fetchData();
    this.onDialogVisibleChg(true);
  }

  get msgThreads(): GenericObjectT {
    const newThreads = cloneDeep(this.data.threads);
    newThreads.sort((t1: GenericObjectT, t2: GenericObjectT): number => {
      return webUtil.time.epoch(t1.createdAt) < webUtil.time.epoch(t2.createdAt)
        ? -1
        : 1;
    });

    return groupBy(newThreads, (entry: GenericObjectT) => {
      return webUtil.time.custom(
        (webUtil.time.epoch(entry.createdAt) * 1000).toString(),
        'YYYY-MM-DD',
      );
    });
  }

  get patients(): string[] {
    const result: string[] = [];
    forEach(this.participants, (person: GenericObjectT): void => {
      if (person.kind === 'Patient') {
        result.push(person.name);
      }
    });
    return result;
  }

  get patientId(): string {
    let id = '';
    forEach(this.participants, (person: GenericObjectT): void => {
      if (person.kind === 'Patient') {
        id = person.id;
      }
    });
    return id;
  }

  async fetchData(): Promise<void> {
    try {
      this.data.loading += 1;
      const {
        data: {
          CareTakers: {
            MessageThreads: { docs, pageInfo },
          },
        },
      } = await this.$apollo.query({
        query: messageGql.query.byThreadId(),
        variables: {
          threadId: this.threadId,
          pagination: {
            offset: this.data.pagination.offset,
            limit: this.data.pagination.limit,
          },
          params: {
            filter: {},
            sort: {},
          },
        },
        fetchPolicy: 'network-only',
      });
      this.data.threads = docs;
      setPagination(this.data.pagination, pageInfo);
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }

  async resend(msgId: string): Promise<void> {
    try {
      this.data.loading += 1;
      await this.$apollo.mutate({
        mutation: messageGql.mutation.resendMessage(),
        variables: {
          msgId,
        },
        fetchPolicy: 'no-cache',
      });

      webUtil.notify.success(this.$toast, 'Message was sent successfully');
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }

  async reply(): Promise<void> {
    try {
      this.data.loading += 1;
      await this.$apollo.mutate({
        mutation: messageGql.mutation.replyConversation(),
        variables: {
          patientId: this.patientId,
          msgThreadId: this.threadId,
          data: {
            subject: this.subject,
            body: this.data.newMessage,
          },
        },
      });

      this.data.newMessage = '';
      this.fetchData();
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }
}
