import Vue from 'vue';
import App from './App.vue';
import store from './store';
import vuetify from './plugins/vuetify';
import VueEcharts from './plugins/echarts';
import router, { initializeRouter } from './router';
import { Auth0Plugin } from './plugins/auth0';
import { apolloProvider } from './plugins/apollo/apollo';
import { RawLocation } from 'vue-router';
import { abilitiesPlugin } from '@casl/vue';
import { abilityFromJwt } from './plugins/ability';
import { WebUtilPlugin } from '@/plugins/webutils';
import { i18n } from '@/plugins/i18n';
import LazyYoutube from 'vue-lazytube';
import ToastPlugin from 'vue-toast-notification';
import LocaleText from '@/common/components/I18nText.vue';

// CSS Import
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.config.productionTip = false;
Vue.use(VueEcharts);
Vue.use(LazyYoutube);
Vue.use(ToastPlugin);
Vue.use(WebUtilPlugin);
Vue.use(abilitiesPlugin, abilityFromJwt(), {
  useGlobalProperties: true,
});
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  onRedirectCallback: (appState: { targetUrl: RawLocation }) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    );
  },
});

Vue.component('locale-text', LocaleText);

new Vue({
  router: initializeRouter(),
  store,
  i18n,
  vuetify,
  apolloProvider,
  render: (h) => h(App),
}).$mount('#app');
