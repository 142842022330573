
/* eslint-disable */
import { Component, Vue, Watch } from 'vue-property-decorator';
import { patientsGql } from '@/common/graphql/patients';
import { webUtil } from '@/common/utils/web-utils';
import { get, isNil, forEach, camelCase } from 'lodash';
import { setPagination } from '@/common/utils/utils';
import { GenericObjectT } from '@/common/types/interfaces';
import { eventNames } from '@/common/utils/vue-events';
import PrettyTs from '@/common/components/PrettyTs.vue';
import DeviceIcon from '@/common/components/devices/DeviceIcon.vue';

@Component({
  filters: {
    startCase: webUtil.str.startCase,
  },
  components: {
    PrettyTs,
    DeviceIcon,
  },
})
export default class PatientTable extends Vue {
  @Watch('data.pageNum')
  onPageNumChg(): void {
    this.data.pagination.offset =
      (this.data.pageNum - 1) * this.data.pagination.limit;

    this.fetchData();
  }

  camelCase = camelCase;
  currFilters: GenericObjectT = {};
  data = {
    debug: false,
    rows: [],
    headers: [
      { text: this.getLocaleHdr('name'), value: 'name' },
      { text: this.getLocaleHdr('age'), value: 'dob' },
      { text: this.getLocaleHdr('devices'), value: 'Devices' },
      { text: this.getLocaleHdr('missedMeasurements'), value: 'lastActive' },
    ],
    pagination: {
      offset: 0,
      limit: 20,
      total: 0,
      totalPages: 0,
    },
    filter: {},
    pageNum: 1,
    params: {},
    loading: 0,
  };

  getLocaleHdr(label: string): string {
    const camelLabel = this.$webUtil.str.camelCase(label);
    if (camelLabel !== 'dateTime') {
      return this.$webUtil.str.startCase(
        this.$t(`common.headers.${this.$webUtil.str.camelCase(label)}`),
      );
    }
    return this.$t(`common.headers.${camelLabel}`) as string;
  }

  mounted() {
    this.fetchData();

    this.$root.$on(
      eventNames.patients.filter.updated,
      (value: GenericObjectT) => {
        this.data.filter = value;
        this.fetchData();
      },
    );
  }

  beforeDestroy(): void {
    this.$root.$off(eventNames.patients.filter.updated);
  }

  lastActiveTs(items: any): { ts: number; type: string } {
    let ts = -Infinity;
    let type = '';
    forEach(get(items, 'Devices', []), (device: any): void => {
      const lastTs = get(device, 'Measurements.last.ts', -1);
      if (lastTs > -1 && lastTs > ts) {
        ts = lastTs;
        type = get(device, 'type');
      }
    });
    return { ts, type };
  }

  deviceLastTsStatus(device: any): string {
    const lastTs = get(device, 'Measurements.last.timestamp', null);

    if (!isNil(lastTs) && !webUtil.time.dateOlderThanToday(lastTs)) {
      return 'success';
    }
    return 'error';
  }

  missedMeasurementDevices(devices: any[]): string[] {
    const output: string[] = [];
    forEach(devices, (device): void => {
      if (this.deviceLastTsStatus(device) === 'error') {
        output.push(device.type);
      }
    });
    return output;
  }

  async fetchData() {
    try {
      this.data.loading += 1;
      const result = await this.$apollo.query({
        query: patientsGql.patients.all(),
        variables: {
          period: 'Past_Week',
          params: { filter: this.data.filter, sort: {} },
          pagination: {
            offset: this.data.pagination.offset,
            limit: this.data.pagination.limit,
          },
        },
      });

      const {
        data: {
          Patients: {
            Get: { docs, pageInfo },
          },
        },
      } = result;
      this.data.rows = docs;
      setPagination(this.data.pagination, pageInfo);
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }
}
