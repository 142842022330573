const authPrefix = 'AUTH';
const userPrefix = 'USER';

export const authActions = {
  setter: {
    user: `${authPrefix}_SET_DATA`,
    request: `${authPrefix}_REQUEST`,
    status: `${authPrefix}_STATUS`,
    authLoading: `${authPrefix}_LOADING`,
    authToken: `${authPrefix}_AUTH_TOKEN`,
    loginRequest: `${authPrefix}_LOGIN_REQUEST`,
    loginSuccess: `${authPrefix}_LOGIN_SUCCESSFUL`,
    logoutRequest: `${authPrefix}_LOGOUT_REQUEST`,
  },
  getter: {
    user: `${authPrefix}_GET_DATA`,
  },
};

export const userActions = {
  setter: {
    profile: `${userPrefix}_SET_PROFILE`,
    locale: `${userPrefix}_SET_LOCALE`,
  },
  getter: {
    locale: `${userPrefix}_GET_LOCALE`,
  },
};
