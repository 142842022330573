var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{staticClass:"mx-4 mt-4",attrs:{"small":"","color":"primary"}},[_c('locale-text',{attrs:{"textPath":"common.btnLabel.requestDevice"}})],1)],1)]}}]),model:{value:(_vm.data.dialog),callback:function ($$v) {_vm.$set(_vm.data, "dialog", $$v)},expression:"data.dialog"}},[_c('form-dialog',{attrs:{"closeDialogFn":_vm.closeDialog},scopedSlots:_vm._u([{key:"dialogTitle",fn:function(){return [_c('locale-text',{attrs:{"textPath":"common.btnLabel.requestDevice"}})]},proxy:true},{key:"dialogContent",fn:function(){return [_c('div',{staticClass:"mt-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"5"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.data.headers,"items":_vm.data.items,"item-key":"device","show-select":"","sort-by":"device","sort-desc":false},scopedSlots:_vm._u([{key:`item.data-table-select`,fn:function({
                  item,
                  isSelected,
                  select,
                }){return [_c('v-simple-checkbox',{attrs:{"value":isSelected,"disabled":!item.selectable},on:{"input":function($event){return select($event)}}})]}},{key:`header.data-table-select`,fn:function(){return undefined},proxy:true},{key:`item.device`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("startCase")(item.device))+" ")]}},{key:`item.action`,fn:function({ item }){return [_c('v-select',{attrs:{"dense":"","outlined":"","hide-details":"","items":_vm.getAvailActions(item)},on:{"change":function($event){return _vm.onActionChg(item)}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('locale-text',{attrs:{"textPath":`common.words.${_vm.$webUtil.str.camelCase(
                        item,
                      )}`}})]}},{key:"selection",fn:function({ item }){return [_c('locale-text',{attrs:{"textPath":`common.words.${_vm.$webUtil.str.camelCase(
                        item,
                      )}`}})]}}],null,true),model:{value:(item.action),callback:function ($$v) {_vm.$set(item, "action", $$v)},expression:"item.action"}})]}},{key:`item.createdAt`,fn:function({ item }){return [(_vm.createdAtMs(item.createdAt) > 0)?_c('pretty-ts',{attrs:{"second":_vm.createdAtMs(item.createdAt)}}):_c('span',[_vm._v(" n/a ")])]}}],null,true),model:{value:(_vm.data.selected),callback:function ($$v) {_vm.$set(_vm.data, "selected", $$v)},expression:"data.selected"}})],1)],1)],1)]},proxy:true},{key:"dialogActions",fn:function(){return [_c('v-spacer'),_c('v-btn',{staticClass:"error--text",attrs:{"disabled":_vm.isLoading,"small":"","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('locale-text',{attrs:{"textPath":"common.btnLabel.discard"}})],1),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.isLoading,"small":""},on:{"click":function($event){return _vm.saveRequestedDevice()}}},[_c('locale-text',{attrs:{"textPath":"common.btnLabel.save"}})],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }