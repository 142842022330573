
import { Component, Vue } from 'vue-property-decorator';
import { patientsGql } from '@/common/graphql/patients';
import { get, map, forEach, find } from 'lodash';
import { webUtil } from '@/common/utils/web-utils';
import PageHeader from '@/common/components/PageHeader.vue';
import DetailSection from '@/components/patients/details/DetailSection.vue';
import NoteSection from '@/components/patients/details/NoteSection.vue';
import DeviceDetailSection from '@/components/patients/details/DeviceDetailSection.vue';
import DeviceRequest from '@/common/components/devices/requests/DeviceRequest.vue';
import SettingSection from '@/components/patients/details/SettingSection.vue';
import AdvancedRulesSetting from '@/components/patients/details/AdvancedRuleSection.vue';
import RawMeasurementSection from '@/components/patients/details/RawMeasurementSection.vue';

type MiniSideBarT = {
  title: string;
  icon?: string;
  children?: MiniSideBarT[];
};

@Component({
  components: {
    PageHeader,
    NoteSection,
    DetailSection,
    DeviceRequest,
    SettingSection,
    DeviceDetailSection,
    AdvancedRulesSetting,
    RawMeasurementSection,
  },
})
export default class PatientDetail extends Vue {
  debug = false;
  data = {
    loading: 0,
    patientId: this.$route.params.patientId,
    selected: 0,
    menus: [],
    patient: {},
  };

  get isLoading(): boolean {
    return this.data.loading > 0;
  }

  get patientName(): string {
    return get(this.data, 'patient.name', '');
  }

  get fixedMenuCtn(): number {
    // eslint-disable-next-line
    return this.data.menus.filter((m: any) => !m.children).length;
  }

  get selTitle(): string {
    if (this.data.selected < this.fixedMenuCtn) {
      return get(this.data.menus, [this.data.selected, 'title']);
    }

    return get(this.data.menus, [
      this.fixedMenuCtn,
      'children',
      this.data.selected - this.fixedMenuCtn,
      'title',
    ]);
  }

  get deviceId(): string {
    const devices = get(this.data, 'patient.Devices', []);
    if (devices.length === 0) {
      console.info('No device found');
      return '';
    }
    const device = find(get(this.data, 'patient.Devices', []), {
      type: this.selTitle.replace(/ /g, ''),
    });
    return get(device, 'id') as unknown as string;
  }

  get isDeviceType(): boolean {
    return this.data.selected >= this.fixedMenuCtn;
  }

  get patientDevices(): MiniSideBarT[] {
    // eslint-disable-next-line
    return map(get(this.data, 'patient.Devices', []), (device: any) => {
      return {
        title: webUtil.str.startCase(get(device, 'type')),
        icon: '',
      };
    });
  }

  mounted(): void {
    this.fetchData();
  }

  async fetchData() {
    try {
      this.data.loading += 1;
      const result = await this.$apollo.query({
        query: patientsGql.patients.show(),
        variables: {
          patientId: this.data.patientId,
          period: 'Past_2_Weeks',
        },
      });

      const {
        data: {
          Patients: { Show },
        },
      } = result;
      this.data.patient = Show;
      this.buildSideMenu();
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }

  buildSideMenu() {
    this.data.menus = [];
    const keys = ['Overview', 'Adv Rules', 'Measurements', 'Devices'];
    forEach(keys, (key: string): void => {
      const menuItem: MiniSideBarT = {
        title: key,
        icon: '',
      };

      if (key === 'Devices') {
        menuItem['children'] = map(
          get(this.data, 'patient.Devices', []),
          // eslint-disable-next-line
          (device: any) => {
            return {
              title: webUtil.str.startCase(get(device, 'type')),

              icon: '',
            };
          },
        );
      }

      this.data.menus.push(menuItem as never);
    });
  }
}
