import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const dictionaryGql = {
  engagementLog: {
    types(): DocumentNode {
      return gql`
        query dictionaryQuery {
          Dictionary {
            EngagemenLogTypes
          }
        }
      `;
    },
  },
  doctor: {
    specialties(): DocumentNode {
      return gql`
        query dictionaryQuery {
          Dictionary {
            doctorSpecialties
          }
        }
      `;
    },
  },
  careTaker: {
    types(): DocumentNode {
      return gql`
        query dictionaryQuery {
          Dictionary {
            careTakerTypes
          }
        }
      `;
    },
  },
  equipment: {
    types(): DocumentNode {
      return gql`
        query dictionaryQuery {
          Dictionary {
            equipments {
              types
            }
          }
        }
      `;
    },
    status(): DocumentNode {
      return gql`
        query dictionaryQuery {
          Dictionary {
            equipments {
              status
            }
          }
        }
      `;
    },
  },
  person: {
    gender(): DocumentNode {
      return gql`
        query dictionaryQuery {
          Dictionary {
            person {
              gender
            }
          }
        }
      `;
    },
    race(): DocumentNode {
      return gql`
        query dictionaryQuery {
          Dictionary {
            person {
              race
            }
          }
        }
      `;
    },
  },
  message: {
    types(): DocumentNode {
      return gql`
        query dictMessageTypeQuery {
          Dictionary {
            message {
              types
            }
          }
        }
      `;
    },
  },
};
