
import { Component, Vue } from 'vue-property-decorator';
import { tenantsGql } from '@/common/graphql/tenants';

@Component
export default class TrustNetworkSetting extends Vue {
  data = {
    loading: 0,
    config: {
      kind: 'trust-networks',
      enabled: true,
      ssidName: '',
      ssidPsk: '',
      vpnUri: '',
    },
    items: {
      enabled: true,
      ssidName: '',
      ssidPsk: '',
      vpnUri: '',
    },
  };

  async saveData(): Promise<void> {
    console.log(this.data.config);
    try {
      this.data.loading += 1;
      await this.$apollo.mutate({
        mutation: tenantsGql.mutation.settings(),
        variables: {
          data: this.data.config,
        },
      });
      this.$webUtil.notify.success(
        this.$toast,
        'Trust Network setting was saved successfully',
      );
    } catch (err) {
      console.log(err);
    } finally {
      this.data.loading -= 1;
    }
  }

  removeSetting(): void {
    this.data.config.enabled = this.data.items.enabled;
    this.data.config.ssidName = this.data.items.ssidName;
    this.data.config.ssidPsk = this.data.items.ssidPsk;
    this.data.config.vpnUri = this.data.items.vpnUri;
  }

  async fetchData() {
    try {
      this.data.loading += 1;
      const {
        data: {
          Tenant: {
            Settings: { trustNetwork },
          },
        },
      } = await this.$apollo.query({
        query: tenantsGql.query.settings(),
      });

      this.data.items = trustNetwork;
      this.data.config.enabled = trustNetwork.enabled;
      this.data.config.ssidName = trustNetwork.ssidName;
      this.data.config.ssidPsk = trustNetwork.ssidPsk;
      this.data.config.vpnUri = trustNetwork.vpnUri;
    } catch (err) {
      console.log(err);
    } finally {
      this.data.loading -= 1;
    }
  }

  mounted() {
    this.fetchData();
  }
}
