
import { Component, Vue } from 'vue-property-decorator';
import { camelCase } from 'lodash';
import PageHeader from '@/common/components/PageHeader.vue';
import NotificationSettingForm from '@/components/notifications/NotificationSettingForm.vue';
import TrustNetworkSetting from '@/components/settings/forms/TrustNetworkSettings.vue';
import AdvancedRulesSetting from '@/components/settings/AdvancedRuleList.vue';
import EhrSetting from '@/components/settings/forms/EHRSetting.vue';

@Component({
  components: {
    PageHeader,
    EhrSetting,
    TrustNetworkSetting,
    AdvancedRulesSetting,
    NotificationSettingForm,
  },
})
export default class Settings extends Vue {
  data = {
    loading: 0,
    selected: 0,
    menu: ['Advanced Rules', 'Trust Network'], //'e-Health Server'],
  };

  isSelected(typeIn: string): boolean {
    return camelCase(this.data.menu[this.data.selected]) === typeIn;
  }
}
