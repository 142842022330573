
import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';
import { get, find, forEach } from 'lodash';
import { preprocDictionary } from '@/common/utils/utils';
import { webUtil } from '@/common/utils/web-utils';
import { patientsGql } from '@/common/graphql/patients';
import { GenericObjectT } from '@/common/types/interfaces';
import moment from 'moment';
import FormDialog from '@/common/components/FormDialog.vue';
import PrettyTs from '@/common/components/PrettyTs.vue';

@Component({
  filters: {
    startCase: webUtil.str.startCase,
  },
  components: {
    PrettyTs,
    FormDialog,
  },
})
export default class DeviceRequest extends Vue {
  @Prop({ default: '' }) patientId!: string;
  @Emit('close-dialog')
  closeDialog(): void {
    this.data.dialog = false;
  }

  @Watch('data.dialog')
  onDataDialogChg() {
    if (this.data.dialog === true) {
      this.fetchData();
    }
  }

  get isLoading(): boolean {
    return get(this.data, 'loading', 1) > 0;
  }

  data = {
    loading: 0,
    dialog: false,
    devices: [{}],
    requested: [{}],
    deviceTypes: [{}],
    selected: [],
    headers: [
      {
        text: this.getLocaleHdr('Types'),
        value: 'device',
        width: '150',
        sortable: true,
      },
      { text: this.getLocaleHdr('Actions'), value: 'action', sortable: false },
      {
        text: this.getLocaleHdr('Requested At'),
        value: 'createdAt',
        width: '225',
      },
    ],
    items: [{}],
    actions: ['Assign', 'Replace', 'Remove'],
  };

  getAvailActions(item: GenericObjectT): string[] {
    if (item.selectable === true) {
      // Remove "Assign"
      return ['Assign'];
    }
    return this.data.actions;
  }

  onActionChg(item: GenericObjectT) {
    if (!find(this.data.selected, item)) {
      this.data.selected.push(item as never);
    }
  }

  createdAtMs(createdAt: string): number {
    return moment(createdAt).unix() * 1000;
  }

  getLocaleHdr(label: string, prefix = 'common.headers'): string {
    const camelLabel = this.$webUtil.str.camelCase(label);
    if (camelLabel !== 'dateTime') {
      return this.$webUtil.str.startCase(
        this.$t(`${prefix}.${this.$webUtil.str.camelCase(label)}`),
      );
    }
    return this.$t(`${prefix}.${camelLabel}`) as string;
  }

  getDataItems() {
    this.data.items = [];
    forEach(this.data.requested, (item): void => {
      if (get(item, 'device') !== 'None') {
        this.data.items.push({
          ...item,
          selectable: false,
        });

        this.data.selected.push({
          ...item,
          selectable: false,
        } as never);
      }
    });

    forEach(this.data.deviceTypes, (device): void => {
      const targetDevice = get(device, 'id');
      if (targetDevice !== 'None') {
        const item = {
          device: targetDevice,
          action: 'Assign',
          createdAt: -1,
          selectable: true,
        };

        if (find(this.data.devices, { type: targetDevice })) {
          item.selectable = false;
          this.data.items.push(item);
          this.data.selected.push(item as never);
        }

        if (!find(this.data.items, { device: targetDevice }))
          this.data.items.push(item);
      }
    });

    return this.data.items;
  }

  async fetchData() {
    try {
      this.data.loading += 1;
      const {
        data: {
          Patients: {
            Show: { Devices, requestedDevices },
          },
          Dictionary: {
            equipments: { types },
          },
        },
      } = await this.$apollo.query({
        query: patientsGql.patients.requestedEquipments(),
        variables: {
          patientId: this.patientId,
        },
        fetchPolicy: 'network-only',
      });
      this.data.deviceTypes = preprocDictionary(types, false);
      this.data.requested = requestedDevices;
      this.data.devices = Devices;
      this.getDataItems();
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }

  async saveRequestedDevice(): Promise<void> {
    try {
      this.data.loading += 1;

      for (let i = 0; i < this.data.selected.length; i++) {
        const item = this.data.selected[i];
        if (!find(this.data.devices, { type: get(item, 'device', '') })) {
          await this.$apollo.mutate({
            mutation: patientsGql.equipmentRequest.setDevice(),
            variables: {
              patientId: this.patientId,
              action: get(item, 'action'),
              deviceType: get(item, 'device'),
            },
          });
        }
      }

      this.closeDialog();

      webUtil.notify.success(this.$toast, 'Device was requested successfully');
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }
}
