
import { Component, Vue, PropSync } from 'vue-property-decorator';

@Component
export default class SimpleHistoricalSetting extends Vue {
  @PropSync('status', { default: false }) statusValue!: boolean;
  @PropSync('occurence', { default: 0 }) occurenceValue!: number;
  @PropSync('period', { default: 0 }) periodValue!: number;

  getChkboxLabel(statusValue: boolean): string {
    if (statusValue === true) {
      return this.$webUtil.str.startCase(
        this.$t('common.words.checkIfSettingOccur'),
      );
    }
    return this.$webUtil.str.startCase(
      this.$t('common.words.checkIfSettingNotOccur'),
    );
  }
}
