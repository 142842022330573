
import { Component, Vue, Prop } from 'vue-property-decorator';
import { get } from 'lodash';

@Component
export default class BloodGlucoseGoals extends Vue {
  @Prop({ default: () => ({}) }) goal!: object;

  get glucose(): number {
    return get(this.goal, 'glucose', 0);
  }
}
