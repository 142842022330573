
import { GenericObjectT } from '@/common/types/interfaces';
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { forEach } from 'lodash';
import { webUtil } from '@/common/utils/web-utils';
import moment from 'moment';
import PrettyTs from '@/common/components/PrettyTs.vue';
import MessageAlertMenu from '@/components/messages/MessageAlertMenu.vue';

@Component({
  components: {
    PrettyTs,
    MessageAlertMenu,
  },
})
export default class MessageThreadList extends Vue {
  @Prop({ default: () => ({}) }) threads!: GenericObjectT[];

  @Emit('resend-message')
  resend(): void {
    return;
  }

  data = {
    loading: 0,
  };

  dateStringDisplay(value: string): string {
    const today = moment().format('MMM D, YYYY');
    const target = moment(value, 'YYYY-MM-DD').utc().format('MMM D, YYYY');
    return today === target ? 'Today' : target;
  }

  isCareTakerTeam(entry: GenericObjectT): boolean {
    return entry.careTakerTeam > 0;
  }

  olderThanToday(ts: number): boolean {
    return webUtil.time.olderThanToday((ts * 1000).toString());
  }

  lastDeliveredReceipt(receipts: GenericObjectT[]): number {
    let ts = 0;
    forEach(receipts, (receipt: GenericObjectT): void => {
      if (ts === 0 || ts < receipt.dateSent) {
        ts = receipt.dateSent;
      }
    });

    return +ts;
  }
}
