
import { Component, Vue, Emit, Watch } from 'vue-property-decorator';
import FormDialog from '@/common/components/FormDialog.vue';
import { patientsGql } from '@/common/graphql/patients';
import { get, isEmpty } from 'lodash';
import { messageGql } from '@/common/graphql/message';
import { webUtil } from '@/common/utils/web-utils';

@Component({
  components: {
    FormDialog,
  },
})
export default class NewMessageDialog extends Vue {
  @Emit('close-dialog')
  closeDialog() {
    this.data.dialog = false;
    return;
  }

  @Watch('data.dialog')
  onDialogOpened() {
    this.fetchData();
  }

  data = {
    loading: 0,
    dialog: false,
    patient: {},
    patients: [],
    subject: '',
    message: '',
    pagination: {
      offset: 0,
      limit: 15,
      total: 0,
      totalPages: 0,
    },
    filter: {},
    sort: {},
  };

  onPatientChanged(evt: string): void {
    if (!isEmpty(evt)) {
      this.data.filter = {
        $or: [
          { firstName: { $regex: evt, $options: 'i' } },
          { lastName: { $regex: evt, $options: 'i' } },
        ],
      };
    } else {
      this.data.filter = {};
    }
    this.fetchData();
  }

  async fetchData() {
    try {
      this.data.loading += 1;
      const {
        data: {
          Patients: {
            Get: { docs },
          },
        },
      } = await this.$apollo.query({
        query: patientsGql.patients.byIdAndName(),
        variables: {
          pagination: {
            offset: this.data.pagination.offset,
            limit: this.data.pagination.limit,
          },
          params: {
            filter: this.data.filter,
            sort: this.data.sort,
          },
        },
      });
      this.data.patients = docs;
    } catch (err) {
      console.log(err);
      // webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }

  async saveNewMessage() {
    try {
      this.data.loading += 1;
      await this.$apollo.mutate({
        mutation: messageGql.mutation.newConversation(),
        variables: {
          patientId: get(this.data.patient, 'id'),
          data: {
            subject: this.data.subject,
            body: this.data.message,
          },
        },
      });

      this.closeDialog();
      webUtil.notify.success(this.$toast, 'Message was saved successfully');
    } catch (err) {
      console.log(err);
      webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }
}
