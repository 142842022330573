
import { Component, Vue } from 'vue-property-decorator';
import { knowledgeBasesGql } from '@/common/graphql/knowledge-bases';
import { setPagination } from '@/common/utils/utils';
import { webUtil } from '@/common/utils/web-utils';
import PageHeader from '@/common/components/PageHeader.vue';
import ShareMenuAction from '@/components/share/ShareMenu.vue';

@Component({
  filters: {
    pluralize: webUtil.str.pluralize,
  },
  components: {
    PageHeader,
    ShareMenuAction,
  },
})
export default class KnowledgeBase extends Vue {
  data = {
    loading: 0,
    KnowledgeBase: [],
    pagination: {
      offset: 0,
      limit: 15,
      total: 0,
      totalPages: 0,
    },
    params: {},
  };

  mounted(): void {
    this.fetchData();
  }

  async fetchData() {
    try {
      this.data.loading += 1;
      const {
        data: {
          KnowledgeBase: {
            KbList: { docs, pageInfo },
          },
        },
      } = await this.$apollo.query({
        query: knowledgeBasesGql.list(),
        variables: {
          pagination: {
            offset: this.data.pagination.offset,
            limit: this.data.pagination.limit,
          },
          params: this.data.params,
        },
      });

      this.data.KnowledgeBase = docs;
      setPagination(this.data.pagination, pageInfo);
    } catch (err) {
      console.log(err);
      // webUtil.notify.error(this.$toast, err);
    } finally {
      this.data.loading -= 1;
    }
  }

  async goTo(kbId: string): Promise<void> {
    console.log('Kb ID', kbId);
    try {
      this.$router.push(`/knowledge-base/${kbId}/articles`);
    } catch (err) {
      console.log(err);
    }
  }
}
