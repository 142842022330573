
import { Component, Vue, PropSync, Emit } from 'vue-property-decorator';
import { GenericObjectT } from '@/common/types/interfaces';
import { isEmpty } from 'lodash';

@Component
export default class FilterBtn extends Vue {
  @PropSync('drawer', { default: false }) filterDrawer!: boolean;
  @PropSync('filters', { default: () => ({}) }) currentfilters!: GenericObjectT;

  @Emit('open-filter-drawer')
  openFilterDrawer(): boolean {
    return true;
  }

  get filter(): GenericObjectT {
    return this.currentfilters;
  }

  get hasFilter(): boolean {
    return !isEmpty(this.currentfilters);
  }
}
