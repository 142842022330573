
import { GenericObjectT } from '@/common/types/interfaces';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class SubMenuBar extends Vue {
  @Prop({ default: '' }) subheader!: string;
  @Prop({ default: () => ({}) }) perm!: GenericObjectT;
  @Prop({ default: () => [] }) items!: GenericObjectT[];
}
