
import { Component, Vue, Prop } from 'vue-property-decorator';
import { GenericObjectT } from '@/common/types/interfaces';
import { get, has, forEach } from 'lodash';
import { webUtil } from '@/common/utils/web-utils';
import PrettyTs from '@/common/components/PrettyTs.vue';
import DeviceLabel from '@/common/components/devices/DeviceLabel.vue';
import DeviceValueDisplay from '@/common/components/devices/DeviceValueDisplay.vue';

@Component({
  filters: {
    startCase: (label: string): string =>
      ['sp02'].includes(label) ? label : webUtil.str.startCase(label),
  },
  components: {
    PrettyTs,
    DeviceLabel,
    DeviceValueDisplay,
  },
})
export default class NotificationDetail extends Vue {
  @Prop({ default: () => ({}) }) item!: GenericObjectT;

  debug = false;

  hasMinMax(key: string, item: { [x: string]: undefined }): boolean {
    return has(item, 'min') && has(item, 'max');
  }

  get deviceSettings(): GenericObjectT {
    const settings: GenericObjectT = {};
    if (this.item.deviceType === 'Blood_Pressure') {
      forEach(['systolic', 'diastolic', 'heartRate'], (key: string): void => {
        settings[key] = get(this.item, ['details', 'setting', key]);
      });
    } else {
      forEach(this.item.details.setting, (value, key): void => {
        if (
          !['kind', 'direction', 'type', 'occurence', 'period'].includes(key)
        ) {
          settings[key] = value;
        }
      });
    }

    return settings;
  }
}
