
import { Component, Vue, Prop } from 'vue-property-decorator';
import { AsYouType } from 'libphonenumber-js';
import { isEmpty } from 'lodash';

@Component
export default class PhoneNumber extends Vue {
  @Prop({ default: '' }) phoneNum!: string;

  data = {
    phone: '',
    formatted: '',
  };

  get fmtPhoneNumber(): string {
    if (this.phoneNum == '--' || isEmpty(this.phoneNum)) {
      return '';
    }
    return new AsYouType('US').input(this.phoneNum);
  }
}
