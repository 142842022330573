
import { Component, Vue, Prop } from 'vue-property-decorator';
import { forEach, get, isNil, isEmpty } from 'lodash';
import { webUtil } from '@/common/utils/web-utils';
import { GenericObjectT } from '@/common/types/interfaces';

import PhoneNumber from '@/common/components/PhoneNumber.vue';
import GatewayRequestBtn from '@/common/components/devices/requests/GatewayRequest.vue';
import lastMeasurementDisplay from '@/common/components/devices/last-measurements/LastMeasurementsDisplay.vue';

@Component({
  filters: {
    fromAgo: webUtil.time.fromAgo,
    fullTs: webUtil.time.fullTs,
    truncate: webUtil.str.truncate,
  },
  components: {
    PhoneNumber,
    GatewayRequestBtn,
    lastMeasurementDisplay,
  },
})
export default class PatientDetailSection extends Vue {
  @Prop({ default: () => ({}) }) patient!: GenericObjectT;
  basicInfo = [
    { text: this.getLocaleHdr('Name'), value: 'name' },
    { text: 'MRN', value: 'mrn' },
    { text: this.getLocaleHdr('Since'), value: 'createdAt' },
  ];

  contactInfo = [
    { text: this.getLocaleHdr('Email'), value: 'emailAddr' },
    { text: this.getLocaleHdr('Phone'), value: 'phoneNum' },
    { text: this.getLocaleHdr('Address'), value: 'postal' },
  ];

  getLocaleHdr(label: string): string {
    const camelLabel = this.$webUtil.str.camelCase(label);
    if (camelLabel !== 'dateTime') {
      return this.$webUtil.str.startCase(
        this.$t(`common.headers.${this.$webUtil.str.camelCase(label)}`),
      );
    }
    return this.$t(`common.headers.${camelLabel}`) as string;
  }

  value(item: {
    text: string;
    value: GenericObjectT;
    fmt?: GenericObjectT;
  }): string {
    const output = get(this.patient, item.value, null);
    return !isNil(output) ? output : 'n/a';
  }

  get postal(): string {
    const { postal } = this.patient;
    if (!isEmpty(postal)) {
      return `${postal.address.streetAddr}, <br />${postal.address.city} ${postal.address.state} ${postal.address.zipcode}`;
    }
    return 'None found';
  }

  get devices() {
    return get(this.patient, 'Devices', []);
  }

  get completedProgress(): number {
    let max = 0;
    forEach(this.patient.Devices, (device): void => {
      const {
        Measurements: { progress },
      } = device;

      max = Math.max(max, progress.length);
    });

    const result = Math.round((max * 100) / 16);
    return result >= 100 ? 100 : result;
    // return Math.round((max * 100) / 30);
  }
}
