import { render, staticRenderFns } from "./KbArticleViewer.vue?vue&type=template&id=177d6109&"
import script from "./KbArticleViewer.vue?vue&type=script&lang=ts&"
export * from "./KbArticleViewer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports