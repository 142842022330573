import { GenericObjectT } from '@/common/types/interfaces';
import { camelCase, isEmpty, get, map, find } from 'lodash';

export const labelDirections: GenericObjectT = [
  { text: 'Hi', value: '1', icon: 'mdi-arrow-top-right-thin-circle-outline' },
  {
    text: 'Lo',
    value: '-1',
    icon: 'mdi-arrow-bottom-right-thin-circle-outline',
  },
  { text: 'In Range', value: '0', icon: 'mdi-arrow-expand-horizontal' },
  { text: 'Any', value: 'x', icon: 'mdi-tray-arrow-down' },
];

export function deviceLabels(devType: string): GenericObjectT {
  let result = {};
  const deviceType = camelCase(devType);
  switch (deviceType) {
    case 'bloodPressure':
      result = { deviceType, labels: ['systolic', 'diastolic', 'heart rate'] };
      break;
    case 'bloodGlucose':
      result = { deviceType, labels: ['glucose'] };
      break;
    case 'oximeter':
      result = { deviceType, labels: ['sp02', 'heart rate'] };
      break;
    case 'weight':
      result = { deviceType, labels: ['weight'] };
      break;
    case 'thermometer':
      result = { deviceType, labels: ['temperature'] };
      break;
    default:
      break;
  }
  console.log(`Result for ${deviceType}: ${JSON.stringify(result)}`);
  return result;
}

export function processLabelMatcher(
  label: string,
  devType: string,
): GenericObjectT[] {
  const devLabel = deviceLabels(devType);
  return map(label.split('_'), (value: string, idx: number): GenericObjectT => {
    const labelDir = find(labelDirections, { value });

    if (isEmpty(labelDir)) {
      throw new Error(`${value} is invalid, please fix it before continuing`);
    }

    return {
      label: get(devLabel, ['labels', idx], ''),
      ...labelDir,
    };
  });
}
