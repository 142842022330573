
import { FilterItemList, GenericObjectT } from '@/common/types/interfaces';
import { webUtil } from '@/common/utils/web-utils';
import { isEmpty } from 'lodash';
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import FilterByPatientName from '@/components/patients/filters/sections/FilterByPatientName.vue';
import FilterByPatientAgeGroup from '@/components/patients/filters/sections/FilterByPatientAgeGroup.vue';
import FilterByDeviceType from '@/components/patients/filters/sections/FilterByDeviceTypes.vue';

@Component({
  filters: {
    pluralize: webUtil.str.pluralize,
  },
  components: {
    FilterByDeviceType,
    FilterByPatientName,
    FilterByPatientAgeGroup,
  },
})
export default class PatientFilterBody extends Vue {
  @Prop({ default: true }) hasInput!: boolean;
  @Prop({ default: 30 }) displayMaxEntry!: number;
  @Prop({ default: '' }) group!: string;

  loading = 0;
  values: FilterItemList[] = [];
  totalEntry = 0;
  valueInput = '';
  currentFilter: GenericObjectT = {};

  get groupTitle(): string {
    return isEmpty(this.group) ? '' : this.group;
  }

  @Emit('updated-filter-body')
  updateFilter(evt: GenericObjectT) {
    this.currentFilter[evt.section] = evt.value;

    return this.currentFilter;
  }
}
