import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import user from './modules/user';
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: { auth, user },
  plugins: [
    createPersistedState({
      paths: ['auth.accessToken', 'auth.idToken', 'user.profile'],
      storage: {
        getItem: (key: string): string => {
          return Cookies.get(key) || '';
        },
        setItem: (key: string, value: string): void => {
          Cookies.set(key, value, { expires: 3 });
        },
        removeItem: (key: string): unknown => Cookies.remove(key),
      },
    }),
  ],
});
